/* eslint-disable */
import * as actionType from './actionType.js';
import axios from '@/utils/request';
import { message } from 'antd';
import promise from 'es6-promise';
import md5 from 'md5';
promise.polyfill();
const api = {
  FindPwd: '/saas-auth/open/sendResetPasswordUrl', //post
  resetPasswordByPhone: '/saas-auth/open/resetPasswordByPhone', //post
  getCode: '/saas-auth/open/sendResetPasswordVerifyCode',
};
//找回密码公用函数
function UserFindPwd(obj, callback) {
  let url;
  let findObj = {};
  if (obj.email) {
    findObj.type = 1;
    findObj.checkCode = obj.checkCode;
    findObj.email = obj.email;
    url = api.FindPwd;
    //放入domain
    findObj.domain = window.location.host;
  }
  if (obj.phone) {
    findObj.code = obj.phonecode;
    findObj.type = 0;
    findObj.phone = obj.phone;
    findObj.password = md5(obj.password);
    url = api.resetPasswordByPhone;
  }
  findObj._t = new Date().getTime();
  axios
    .post(url, findObj)
    .then((res) => {
      if (res.data.code == 1) {
        callback(false, res.data);
      } else {
        callback(res.data);
      }
    })
    .catch((err) => {
      callback(err);
    });
}
//邮箱找回
export function sendEmailFind(obj) {
  return (dispatch) => {
    dispatch({ type: actionType.SEND_EMAIL });
    UserFindPwd(obj, function (err, data) {
      if (err) {
        //console.log(err);
        if (!err.code) {
          try {
            message.error(err.msg);
          } catch (err) {
            message.error('响应超时，请联系管理员');
          }
        } else {
          let messages = err.msg.replace(/<[^>]*>|<\/[^>]*>/gm, '');

          message.error(messages);
          let time = new Date().getTime();
          dispatch({ type: actionType.CHANGE_NEW_DATA, data: time });
          dispatch({ type: actionType.SEND_EMAIL_ERR });
        }
      } else {
        message.success('已发送找回密码邮件到您的邮箱，请根据邮件内容进行操作');

        dispatch({ type: actionType.SEND_EMAIL_SUCC });
      }
      dispatch({ type: actionType.SEND_EMAIL_END });
    });
  };
}
//电话找回
//短信验证码
export function sendPhoneCode(obj) {
  return (dispatch) => {
    dispatch({ type: actionType.SEND_PHONE_CODE });
    dispatch({ type: actionType.SEND_PHONE_MSG, loadMsg: '发送中' });
    axios
      .post(api.getCode, { phone: obj.phone })
      .then((res) => {
        if (res.data.code == 1) {
          message.success('验证码发送成功,请注意查收');
          let times = 60;
          let timer = setInterval(() => {
            times--;
            if (times <= 0) {
              dispatch({
                type: actionType.SEND_PHONE_MSG,
                loadMsg: '点击获取验证码',
              });
              dispatch({ type: actionType.SEND_PHONE_CODE_END });
              clearInterval(timer);
              return;
            }
            dispatch({
              type: actionType.SEND_PHONE_MSG,
              loadMsg: '已发送(' + times + ')',
            });
          }, 1000);
        } else {
          let messages = res.data.msg.replace(/<[^>]*>|<\/[^>]*>/gm, '');
          message.error(messages);
          dispatch({
            type: actionType.SEND_PHONE_MSG,
            loadMsg: '点击获取验证码',
          });
          dispatch({ type: actionType.SEND_PHONE_CODE_END });
        }
      })
      .catch((err) => {
        dispatch({
          type: actionType.SEND_PHONE_MSG,
          loadMsg: '点击获取验证码',
        });
        dispatch({ type: actionType.SEND_PHONE_CODE_END });
      });
  };
}

export function checkResetPhone(obj) {
  return (dispatch) => {
    dispatch({ type: actionType.SEND_PHONE_RESET });
    UserFindPwd(obj, function (err, data) {
      if (err) {
        if (err.msg) {
          message.error(err.msg);
        } else {
          message.error('未知错误，请稍后重试');
        }
        dispatch({ type: actionType.SEND_PHONE_RESET_END });
      } else {
        if (data.code == 1) {
          message.success('密码重置成功，即将跳转到登录页面');
          setTimeout(() => {
            window.location.href = data.data.loginUrl;
          }, 1000);
        }
        dispatch({ type: actionType.SEND_PHONE_RESET_END });
      }
    });
  };
}

//更改状态码
export function changeNewTimeDate() {
  return (dispatch) => {
    dispatch({ type: actionType.CHANGE_NEW_DATA, data: new Date().getTime() });
  };
}
