/* eslint-disable */
import * as actionType from './actionType.js';

const changeTableData = (dataSource, pageCode, sdk_manufacturer, sdk_name, sdk_package_name, total) => ({
  type: actionType.CHANGE_TABLE_DATA,
  data: dataSource,
  pageCode,
  sdk_manufacturer,
  sdk_name,
  sdk_package_name,
  total,
});
const editVisible = (is) => ({
  type: actionType.EDIT_VISIBLE,
  is,
});

export { changeTableData, editVisible };
