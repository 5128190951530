/* eslint-disable */
import axios from '@/utils/request';
import { message } from 'antd';
import { getThreatScenariosAction, getApkInfoAction } from './action';
import api from './api';
import { get } from 'lodash';
// 获取table 表格数据
const getTableData = (obj) => async () => {
  try {
    const { data } = await axios.get(api.getTableList, { params: obj });
    let code = get(data, 'code', 'table数据获取失败');
    let msg = get(data, 'msg', '');
    let tableData = [];
    let stateNumber = {};
    if (code === 1) {
      tableData = get(data, 'data.all', []);
      stateNumber = get(data, 'data', {});
      return {
        tableData,
        stateNumber,
      };
    } else {
      message.error(msg);
    }
    return {
      tableData: [],
      stateNumber: {},
    };
  } catch (error) {
    console.error(error);
    const msg = get(error, 'response.data.msg', '');
    if (msg) {
      message.error(msg);
    }
    return {
      tableData: [],
      stateNumber: {},
    };
  }
};
// 获取apk 的详细信息
const getApkInfo = (task_id) => async (dispatch) => {
  try {
    const {
      data: { data },
    } = await axios.get(api.getApkInfo, { params: { task_id } });
    dispatch(getApkInfoAction(data));
  } catch (error) {
    console.error(error);
  }
};
// 得到单个安全加固策略的威胁场景
const getThreatScenarios = (obj) => async (dispatch) => {
  try {
    if (obj.policy_id) {
      const { data } = await axios.get(api.getThreatScenarios, { params: obj });
      let code = get(data, 'code', '');
      if (code === 1) {
        dispatch(getThreatScenariosAction(data.data));
        // message.success(msg);
      }
    } else {
      dispatch(getThreatScenariosAction([]));
    }
  } catch (error) {
    // const {response = {}} = error;
    // const msg = get(response, "data.msg", "删除失败");
    // message.error(msg);
    console.error(error);
  }
};
// 取消加固接口
const cancelInsert = (obj) => async (dispatch) => {
  try {
    const { data } = await axios.post(`/appguard/backend/project/group/delete`, obj);
    let code = get(data, 'code', '');
    let msg = get(data, 'msg', '取消成功');
    if (code === 1) {
      message.success(msg);
    }
  } catch (error) {
    const { response = {} } = error;
    const msg = get(response, 'data.msg', '删除失败');
    message.error(msg);
    console.error(error);
  }
};
// 获取加固的实时状态
const getEncryptState = (task_id) => async (dispatch) => {
  try {
    const { data } = await axios.get(api.getEncryptState, { params: { task_id } });

    let obj = { state: data.protectTaskStr, progress: data.progress, signState: data.sign_state_str };
    return obj;
  } catch (error) {
    console.error(error);
    return false;
  }
};
// 获取加固的实时状态
const getEncryptStateSchema = (params) => async (dispatch) => {
  try {
    const { data } = await axios.get(api.getEncryptStateSchema, { params: { ...params } });
    const dataAll = get(data, 'data.all', '[]');
    const count = get(data, 'data.count', '0');
    let obj = { state: '', count: count, schemaData: dataAll };
    return obj;
  } catch (error) {
    console.error(error);
    return false;
  }
};
// 开始加固
const startEncrypt = (obj) => async (dispatch) => {
  try {
    const { data } = await axios.post(api.startEncrypt, obj);
    return data;
  } catch (error) {
    const { response = {} } = error;
    const msg = get(response, 'data.msg', '开始加固失败');
    message.error(msg);
    console.error(error);
    return false;
  }
};
// 获取签名文件信息
const fetchSignFileList = () => async (dispatch) => {
  const { data } = await axios.get(api.signFileList, { params: { page: -1 } });
  const { code, msg } = data;
  if (code === 1) {
    return { list: data?.data.all, total: data?.data.total };
  } else {
    const error = { response: { data: { msg } } };
    return Promise.reject(error);
  }
};
// 根据包名获取多渠道设置
const fetchChanneSchemaList = (obj) => async (dispatch) => {
  try {
    const {data} = await axios.get(api.schemaChannelList, {params: {page: -1, ...obj}});
    const { code } = data;
    if (code === 1) {
      return {list: data?.data.all, total: data?.data.count};
    }
  }catch (error) {
    // const { response = {} } = error;
    // const msg = get(response, 'data.msg', '获取渠道信息失败');
    // message.error(msg);
    console.error(error);
    return false;
  }
}
// 根据权限设置签名和多渠道设置
const fetchAndroidEncryptPermission = (obj) => async (dispatch) => {
  try {
    const { data } = await axios.post(api.getAndroidPermissionKey, { ...obj});
    const { code } = data;
    if (code === 1) {
      const groupKeyPermit = get(data, 'data.groupKeyPermit', {});
      return { groupKeyPermit: groupKeyPermit };
    }
  } catch (error) {
    // const { response = {} } = error;
    // const msg = get(response, 'data.msg', '获取权限设置失败');
    // message.error(msg);
    console.error(error);
    return false;
  }
}

// 重新开始加固
const restartEncryptChannelsTask = (obj) => async (dispatch) => {
  try {
    const { data } = await axios.post(api.restartEncryptChannelsTask, { ...obj});
    const { code } = data;
    if (code === 1) {
      return data;
    }
  } catch (error) {
    // const { response = {} } = error;
    // const msg = get(response, 'data.msg', '重试失败');
    // message.error(msg);
    console.error(error);
    return false;
  }
}



export default {
  getTableData,
  cancelInsert,
  getThreatScenarios,
  getEncryptState,
  getEncryptStateSchema,
  startEncrypt,
  getApkInfo,
  fetchSignFileList,
  fetchChanneSchemaList,
  fetchAndroidEncryptPermission,
  restartEncryptChannelsTask,
};
