/* eslint-disable */
import {
  UPDATAWORD,
  GETTABLEDATA,
  DEL,
  ADD,
  UPDATA,
  REALUPDA,
  FINDAGENTTWO,
  REALUPDATWO,
  FREEZE,
  GET_AGENT_LIST,
  GET_AGENT_NAME_LIST,
  GET_ENTERPRISE_TYPE_LIST,
  GET_PROJECT_MANAGER_LIST,
  GET_SALES_MANAGER_LIST,
} from './actionType';

const getTableDataAction = (data) => ({
  type: GETTABLEDATA,
  data,
});
const delAction = () => ({
  type: DEL,
});
const addAction = (data) => ({
  type: ADD,
  data,
});
const updateAction = (data) => ({
  type: UPDATA,
  data,
});
const realupdataAction = (data) => ({
  type: REALUPDA,
  data,
});
const findAgentTwoAction = (data) => ({
  type: FINDAGENTTWO,
  data,
});
const realupdatatwoAction = () => ({
  type: REALUPDATWO,
});
const freezeAction = () => ({
  type: FREEZE,
});
const updataWordAction = () => ({
  type: UPDATAWORD,
});
const getAgentListAction = (data) => ({
  type: GET_AGENT_LIST,
  data,
});
const getAgentNameListAction = (data) => ({
  type: GET_AGENT_NAME_LIST,
  data,
});
const getEnterpriseTypeListAction = (data) => ({
  type: GET_ENTERPRISE_TYPE_LIST,
  data,
});
const getProjectManagerListAction = (data) => ({
  type: GET_PROJECT_MANAGER_LIST,
  data,
});
const getSalesManagerListAction = (data) => ({
  type: GET_SALES_MANAGER_LIST,
  data,
});

export {
  getTableDataAction,
  delAction,
  addAction,
  updateAction,
  realupdataAction,
  findAgentTwoAction,
  realupdatatwoAction,
  freezeAction,
  updataWordAction,
  getAgentListAction,
  getAgentNameListAction,
  getEnterpriseTypeListAction,
  getProjectManagerListAction,
  getSalesManagerListAction,
};
