/* eslint-disable */
import React, { useState } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { Form, Input, Button, Checkbox, message, Modal, Row, Col } from 'antd';
import md5 from 'md5';
import { emailRigister } from '../../../request/request';
import { LoginUserImg, PwdIconImg, LoginYanzmaImg } from '../../common/config_img';
import { passwordRegExp } from '../../common/config_regexp';
import VerifyCode from '@/common/verifyCode';

const FormItem = Form.Item;
const RegFroms = (props) => {
  const [currentTime, setcurrentTime] = useState('');
  const [agreement, setAgreement] = useState(false); // 是否勾选用户协议
  const [emailActive, setEmailActive] = useState(true); // 邮箱是否激活
  // 确定注册方法
  const handleSubmit = (e) => {
    e.preventDefault();
    props.form.validateFields(async (err, values) => {
      if (!agreement) {
        message.info('请仔细阅读并同意用户协议！');
        return;
      }
      if (!err) {
        values.password = md5(values.password);
        values.domain = window.location.host;
        const data = await emailRigister(values);
        // 说明 邮箱已注册没有激活
        if (data === 'noActicve') {
          setEmailActive(false);
          Modal.info({
            title: '邮箱已经注册，但未激活',
            content: (
              <div>
                <p>点击下方激活邮箱按钮，去激活邮箱</p>
              </div>
            ),
            onOk() {},
          });
          return;
        }
        if (data) {
          Modal.info({
            title: '邮箱注册成功',
            content: (
              <div>
                <p>请前往注册邮箱激活账号</p>
              </div>
            ),
            onOk() {},
          });
        }
        return;
      }
      message.error(err);
    });
  };
  const { getFieldDecorator } = props.form;
  // 获取当前时间戳 获取不同的图片
  const changeCodeTime = () => {
    setcurrentTime(Date.parse(new Date()));
  };

  return (
    <Form onSubmit={handleSubmit} className="regitem">
      <FormItem>
        {getFieldDecorator('email', {
          rules: [
            {
              required: true,
              message: '邮箱不能为空',
            },
            {
              pattern: /^[a-z0-9]+([._\\-]*[a-z0-9])*@([a-z0-9]+[-a-z0-9]*[a-z0-9]+.){1,63}[a-z0-9]+$/,
              message: '邮箱格式错误',
            },
          ],
        })(
          <Input
            prefix={
              <span className="login-icon">
                <img src={LoginUserImg} />
              </span>
            }
            autoComplete="new-email"
            className="username-info"
            placeholder="请输入Email账号"
          />,
        )}
      </FormItem>
      {/* 按钮界面 */}
      <FormItem className="err-err-panel">
        <Row>
          <Col span={14}>
            {getFieldDecorator('checkCode', {
              rules: [
                { required: true, message: '验证码不能为空' },
                { min: 4, message: '验证码不能少于四位' },
              ],
            })(
              <Input
                type="text"
                placeholder="请输入验证码"
                prefix={
                  <span className="login-icon">
                    <img src={LoginYanzmaImg} />
                  </span>
                }
                onChange={(e) => {
                  setCheckCode(e.target.value);
                }}
              />,
            )}
          </Col>
          <Col span={10}>
            <div className="checkCode">
              <VerifyCode onClick={changeCodeTime} timestap={currentTime} />
            </div>
          </Col>
        </Row>
      </FormItem>
      {/* 按钮界面 */}
      <FormItem>
        {getFieldDecorator('password', {
          rules: [
            { required: true, message: '密码不能为空' },
            { min: 6, message: '密码不能少于6位' },
            { pattern: passwordRegExp, message: '密码必须由字母和数字组成' },
          ],
        })(
          <Input.Password
            placeholder="请输入密码"
            prefix={
              <span className="login-icon">
                <img src={PwdIconImg} />
              </span>
            }
          />,
        )}
      </FormItem>
      <div className="res-argee-ment mail-res-argee-ment">
        <Checkbox
          onChange={(e) => {
            setAgreement(e.target.checked);
          }}
          checked={agreement}
          className="actice-email antd-checkboxinput"
        >
          我已阅读并同意
          <a href="https://www.kiwisec.com/service/agreements" target="_blank" className="button-color-color">
            《用户协议》
          </a>
        </Checkbox>
      </div>
      <FormItem>
        {emailActive ? (
          <Button type="primary" htmlType="submit" className="login-form-button mail-regbutton antdButtonSelf">
            注册账号
          </Button>
        ) : (
          <Button type="primary" className="login-form-button mail-regbutton antdButtonSelf">
            <Link to="/user/active">激活邮箱</Link>
          </Button>
        )}
      </FormItem>
    </Form>
  );
};
const WrappedNormalRegForm = Form.create()(RegFroms);

export default withRouter(WrappedNormalRegForm);
