/* eslint-disable */
import React from 'react';
import { Form, Input, Button, Checkbox, Row, Col, message } from 'antd';
import md5 from 'md5';
import { Link } from 'react-router-dom';
import { passwordRegExp } from '../../../../config/regexp.js';
import MyIcon from '../../login/component/loginIcon';
import VerifyCode from '@/common/verifyCode';
import '../style/regitem.less';

const FormItem = Form.Item;

class RegFroms extends React.PureComponent {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.changeCodeTime = this.changeCodeTime.bind(this);
    this.state = {
      codeTime: 1,
      checkCode: '',
      AgreeMent: false,
      showpsw: 'text',
    };
    this.AgreeMent = this.AgreeMent.bind(this);
  }
  componentDidMount() {}
  changeCodeTime() {
    this.props.updateImgCode();
  }
  handleSubmit(e) {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!this.state.AgreeMent) {
        message.info('请仔细阅读并同意用户协议！');
        return;
      }
      if (!err) {
        values.password = md5(values.password);
        //console.log(values);
        this.props.mailRegister(values);
      }
    });
  }
  AgreeMent() {
    this.setState({
      AgreeMent: !this.state.AgreeMent,
    });
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    const That_ = this;
    return (
      <Form onSubmit={this.handleSubmit} className="regitem">
        <FormItem>
          {getFieldDecorator('mailName', {
            rules: [
              {
                required: true,
                message: '邮箱不能为空',
              },
              {
                pattern: /^[a-zA-Z0-9]+([-_.][a-zA-Z0-9]+)*@[a-zA-Z0-9]+([-_.][a-zA-Z0-9]+)*\.[a-z]{2,}$/,
                message: '邮箱格式错误',
              },
            ],
          })(
            <Input
              prefix={<MyIcon src="/images/login-user.png" />}
              autoComplete="new-email"
              className="username-info"
              placeholder="请输入Email账号"
            />,
          )}
        </FormItem>
        {/*按钮界面*/}
        <FormItem>
          {getFieldDecorator('password', {
            rules: [
              { required: true, message: '密码不能为空' },
              { min: 6, message: '密码不能少于6位' },
              { pattern: passwordRegExp, message: '密码必须由字母和数字组成' },
            ],
          })(
            <Input.Password placeholder="请输入密码" prefix={<MyIcon src="/images/pwd-icon.png" />} />,
            // <Input
            //   autoComplete="new-mail-password"
            //   type={this.state.showpsw}
            //   placeholder="请输入密码"
            // />,
          )}
        </FormItem>

        {this.props.checkCode ? (
          <FormItem className="err-err-panel">
            <Row>
              <Col span={14}>
                {getFieldDecorator('checkcode', {
                  rules: [
                    { required: true, message: '验证码不能为空' },
                    { min: 4, message: '验证码不能少于四位' },
                  ],
                })(<Input autoComplete="new-mail-code" type="text" placeholder="验证码" />)}
              </Col>
              <Col span={10}>
                <div className="checkCode">
                  <VerifyCode onClick={this.changeCodeTime} timestap={this.props.imgCodeTime} />
                </div>
              </Col>
            </Row>
          </FormItem>
        ) : null}

        <div className="res-argee-ment mail-res-argee-ment">
          <Checkbox
            onChange={this.AgreeMent}
            checked={this.state.AgreeMent}
            className="actice-email"
            className="antd-checkboxinput"
          >
            我已阅读并同意{' '}
            <a href="https://www.kiwisec.com/service/agreements" target="_blank" className="button-color-color">
              《用户协议》
            </a>
          </Checkbox>
          &emsp; &emsp; &emsp; &emsp; &emsp; &emsp;
          {this.props.show_email_btn ? <Link to="/user/active">激活邮箱</Link> : null}
        </div>

        <FormItem>
          <Button
            type="primary"
            htmlType="submit"
            className="login-form-button mail-regbutton antdButtonSelf"
            loading={this.props.mail_loading}
          >
            注册账号
          </Button>
        </FormItem>
      </Form>
    );
  }
}
const WrappedNormalRegForm = Form.create()(RegFroms);

export default WrappedNormalRegForm;
