/* eslint-disable */
import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { Collapse, Row, Col, Spin, Typography, Tabs } from 'antd';
import { getRulePocContent } from '../request/request'
import { get } from 'lodash';
import PocHtmlCode from './components/code';
import '../style/index.less';

const PocHtml = (props) => {
  const { id = null, rule_name = null } = useParams();

  if (!id) {
    props.history.push('/login');
  }

  const contentRef = useRef();
  const [loading, setLoading] = useState(false);
  const [content, setContent] = useState(false);
  const [pocContent, setPocContent] = useState([]);
  const [deviceList, setDeviceList] = useState([]);

  const getPocContent = async () => {
    setLoading(true);
    const res = await getRulePocContent({ rule_app_id: id });
    if (!res) {
      props.history.replace('/login', {});
      return;
    }
    const poc_content = get(res, 'poc_content', '');
    setContent(poc_content);

    // 漏洞利用/PoC
    var aPre = contentRef.current.querySelector('#myTabContent').querySelectorAll('pre');
    let allPrePoc = [];
    aPre.forEach((item) => {
      const oSpan = item.querySelector("code");
      allPrePoc.push(oSpan.innerHTML);
    })
    setPocContent(allPrePoc);

    // 获取受影响的设备
    var ali = contentRef.current.querySelector('.threeLine').querySelectorAll('li');
    let allDevices = [];
    ali.forEach((item) => {
      const oSpan = item.querySelector("span");
      allDevices.push(oSpan.innerHTML);
    })
    setDeviceList(allDevices);

    setLoading(false);
    setContent("");
  }

  useEffect(() => {
    (async() => {
      await getPocContent();
    })();
  }, [id])

  return (
    <div className="poc-html">
      <Spin spinning={loading}>

        <Row gutter={20}>
          <Col span={20} offset={2}>
            {
              rule_name && <Typography.Title level={1} style={{ fontSize: 18, textAlign: 'center', marginBottom: 20 }}>检测细则：{ rule_name }</Typography.Title>
            }
          </Col>
          <Col span={20} offset={2}>
            <Collapse defaultActiveKey={['poc', 'device']}>
              <Collapse.Panel header="漏洞利用/PoC" key="poc">
                <Tabs>
                  {
                    pocContent.length > 0 && (
                      pocContent.map((item, index) => {
                        return (
                          <Tabs.TabPane tab={`PoC${parseInt(index + 1)}`} key={`poc${index}`}>
                            <div className="poc-html-content">
                              <PocHtmlCode poc={item} />
                            </div>
                          </Tabs.TabPane>
                        )
                      })
                    )
                  }
                </Tabs>
              </Collapse.Panel>
              <Collapse.Panel header="受影响的平台与产品" key="device">
                {
                  deviceList.length > 0 && deviceList.map((item, index) => {
                    return (
                      <Typography.Title level={4} key={index} style={{ fontSize: 14 }}>{ item }</Typography.Title>
                    )
                  })
                }
              </Collapse.Panel>
            </Collapse>
          </Col>
        </Row>
        {
          content && (
            <div style={{ display: 'none' }} ref={contentRef} dangerouslySetInnerHTML={{ __html: content }}>
            </div>
          )
        }
      </Spin>
    </div>
  )
}

export default PocHtml;
