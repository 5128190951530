/* eslint-disable */
const namespace = 'MEMBERADMIN';
export const CANCLE_MODEL = `${namespace}CANCLE_MODEL`;
export const OPEN_MODEL = `${namespace}OPEN_MODEL`;
export const CHANGE_CODE = `${namespace}CHANGE_CODE`;
export const CHANGE_SELECT_DATA = `${namespace}CHANGE_SELECT_DATA`; //修改Select数据
export const CHANGE_TABLE_DATA = `${namespace}CHANGE_TABLE_DATA`; //修改Table数据
export const CHANGE_TABLE_LOAD = `${namespace}CHANGE_TABLE_LOAD`; //修改Table加载状态
export const CHANGE_TABLE_COUNT = `${namespace}CHANGE_TABLE_COUNT`; //修改Table数据总数
export const GET_OPTION_DATA = `${namespace}GET_OPTION_DATA`; //修改option数据
export const GET_SELECT_DATA = `${namespace}GET_SELECT_DATA`; //修改Select下拉框数据
