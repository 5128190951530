/* eslint-disable */
import React from 'react';
import md5 from 'md5';
import { Form, Input, Button, Row, Col } from 'antd';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import MyIcon from './loginIcon.js';
import VerifyCode from '@/common/verifyCode';
import '../style/phoneFrom.less';

const FormItem = Form.Item;

class LoginFroms extends React.PureComponent {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.changeCodeTime = this.changeCodeTime.bind(this);
    this.state = { codeTime: 1, passwordDoMd5: true };
  }
  changeCodeTime() {
    this.props.updateImgCode();
  }
  handleSubmit(e) {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        // values.password = md5(values.password);
        const fn = this.context.loginCommonObj.fn;
        values.passwordDoMd5 = this.state.passwordDoMd5;
        fn(values);
      }
    });
  }
  render() {
    const { state } = this.context.loginCommonObj;
    const { getFieldDecorator } = this.props.form;
    const checkCode = state.showCode;
    const { changeUserOperation, passwordDoMd5 } = this.props;
    const imgCheckCode = state.imgCheckCode;
    const privateServer = window.webConfig ? window.webConfig.privateServer : false;
    const isRegister = window.webConfig.enabledRegister !== undefined ? window.webConfig.enabledRegister : true;
    const isForget = window.webConfig.enabledForget !== undefined ? window.webConfig.enabledForget : true;

    this.setState({ passwordDoMd5: passwordDoMd5 });

    return (
      <Form onSubmit={this.handleSubmit} className="loginAndRegisterForm">
        <FormItem className="login-user">
          {getFieldDecorator('userName', {
            rules: [
              { required: true, message: '用户名不能为空' },
              { min: 6, message: '请输入手机号或邮箱' },
            ],
          })(
            <Input
              autoComplete="new-username"
              prefix={<MyIcon src="/images/login-user.png" />}
              className="username-info"
              placeholder="请输入 Email / 手机号 登录"
            />,
          )}
        </FormItem>
        {/*按钮界面*/}
        <FormItem className="login-paw">
          {getFieldDecorator('password', {
            rules: [
              { required: true, message: '密码不能为空' },
              { min: 6, message: '密码格式不正确！' },
            ],
          })(
            <Input
              onpaste="return false"
              oncontextmenu="return false"
              oncopy="return false"
              oncut="return false"
              autoComplete="new-password"
              prefix={<MyIcon src="/images/pwd-icon.png" />}
              type="password"
              placeholder="登录密码"
              id="username-password"
            />,
          )}

          {!/验证码错误/.test(state.errValue) && state.errValue ? (
            <div className="ant-form-explain errlist" dangerouslySetInnerHTML={{ __html: state.errValue }} />
          ) : null}
        </FormItem>

        {checkCode ? (
          <FormItem className="err-err-panel">
            <Row>
              <Col span={13}>
                {getFieldDecorator('checkcode', {
                  rules: [
                    { required: true, message: '验证码不能为空' },
                    { min: 4, message: '验证码不能少于四位' },
                  ],
                })(<Input type="text" autoComplete="new-username" placeholder="验证码" />)}
              </Col>
              <Col span={11}>
                <div className="checkCode">
                  <VerifyCode onClick={this.changeCodeTime} timestap={imgCheckCode} />
                </div>
              </Col>
            </Row>
            {/验证码错误/.test(state.errValue) && state.errValue ? (
              <div className="ant-form-explain errlist" dangerouslySetInnerHTML={{ __html: state.errValue }} />
            ) : null}
          </FormItem>
        ) : null}

        <FormItem>
          <Button
            loading={state.loading ? true : false}
            type="primary"
            htmlType="submit"
            className="login-form-button antdButtonSelf"
          >
            登录
          </Button>
        </FormItem>
        <FormItem>
          <Row>
            {/* 第三登录屏蔽 qq和微信*/}
            <Col span={11} className="loginIcon">
              {/* <span className="elseLogin">第三方登录:</span> */}
              {/* <span
                  className="icon-pravite icon-qq qqWechatIcon"
                  onClick={this.props.qqLogin}
                /> */}
              {/* <span className="icon-pravite icon-wechat qqWechatIcon" onClick={this.props.wxLogin} /> */}
            </Col>

            <Col span={13} className="resister">
              {isRegister ? (
                <a
                  className="forgetPassword"
                  onClick={() => {
                    changeUserOperation({ code: 2 });
                  }}
                >
                  注册账号
                </a>
              ) : null}

              {isForget ? (
                <Link className="forgetPassword" to="/user/findpwd">
                  忘记密码
                </Link>
              ) : null}
            </Col>
          </Row>
        </FormItem>
      </Form>
    );
  }
}
const WrappedNormalLoginForm = Form.create()(LoginFroms);
LoginFroms.contextTypes = {
  loginCommonObj: PropTypes.object,
};
export default WrappedNormalLoginForm;
