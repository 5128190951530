/* eslint-disable */
import React from 'react';
import host from '../../config/host';
function VerifyCode(props) {
  const { timestap, onClick } = props;
  let baseURL;
  // 环境标识
  let env = 'dev';
  if (process.env.REACT_APP_ENV) {
    env = process.env.REACT_APP_ENV;
  }
  host.some((item) => {
    if (item.prefix.startsWith('/saas-auth')) {
      baseURL = item[env];
      return true;
    }
  });
  return <img onClick={onClick} src={`${baseURL}/saas-auth/open/getCheckCode?_t=${timestap}`} />;
}

export default VerifyCode;
