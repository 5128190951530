/* eslint-disable */
/*
 * @Author: rookie.CYJ
 * @Date: 2020-11-10 09:30:03
 * @Last Modified by: rookie.CYJ
 * @Last Modified time: 2021-04-29 13:43:12
 */
import axios from '@/utils/request';
import api from './api';
import { message } from 'antd';
import { getTableDatas, setSelectedRow, setSelectData, editVisible, addVisible } from './action';
import { get } from 'lodash';
//获取表格数据
const getTableDate = async (dispatch, pageCode, review_type, review_content_id, state) => {
  console.log(state);
  let obj = { page: pageCode };
  if (review_type.length) {
    obj.review_type = review_type;
  }
  if (review_content_id) {
    obj.review_content_id = review_content_id;
  }
  if (state === 0 || state === 1) {
    obj.state = state;
  }
  try {
    const dataAxios = await axios.get(api.getTableData, { params: obj }); // /appprivacy/privacy/text/list

    if (dataAxios) {
      const { data } = dataAxios;
      const { data: dataSource } = data;
      const tableTotal = get(data, 'totle_num', 0); //获取table数据总数
      dispatch(getTableDatas(dataSource, tableTotal, pageCode, review_type, review_content_id, state));
    }
  } catch (error) {
    const { message: msg = '获取数据失败' } = error;
    message.error(msg);
  }
};
// 获取数据
const getNewTableDate = (pageCode = 1, review_type = '', review_content_id = null, state = 'all') => async (
  dispatch,
) => {
  //筛选参数:
  // 1.pageCode页数
  // 2.审查类型
  // 3.审查内容
  // 4.状态
  getTableDate(dispatch, pageCode, review_type, review_content_id, state);
};

// 更新删除数组
const setSelectedRowKeys = (arr) => async (dispatch) => {
  dispatch(setSelectedRow(arr));
};

// 同时获取审查类型与审查内容数据
const getSelectOneData = () => axios.get(api.SelectOneData);
const getSelectTwoData = () => axios.get(api.SelectTwoData);
const getSelectData = () => async (dispatch) => {
  console.log('拿数据');
  try {
    const one = getSelectOneData();
    const two = getSelectTwoData();
    const [selectDataOne, selectDataTwo] = await Promise.all([one, two]);
    if ((selectDataOne, selectDataTwo)) {
      const { data: dataOne } = selectDataOne;
      const { data: dataTwo } = selectDataTwo;
      const { data: selectOne } = dataOne;
      const { data: selectTwo } = dataTwo;
      console.log(selectOne, selectTwo);
      if (selectOne === (undefined || null)) selectOne = [];
      if (selectTwo === (undefined || null)) selectTwo = [];
      dispatch(setSelectData(selectOne, selectTwo));
    }
  } catch (error) {
    const { msg: msg = '获取数据失败' } = error;
    message.error(msg);
  }
};

//删除表格数据
const delTableData = (arr, pageCode, review_type, review_content_id, state) => async (dispatch) => {
  // console.log(arr)
  let obj = {};
  obj.keyword_id = arr;
  console.log(obj);
  try {
    const { data } = await axios({
      url: api.delTableData,
      method: 'delete',
      data: obj,
    });
    console.log(data);
    message.success(data.msg);
    getTableDate(dispatch, pageCode, review_type, review_content_id, state);
  } catch (error) {
    const { message: msg = '删除数据失败' } = error;
    message.error(msg);
  }
};

const setEditVisible = (is) => async (dispatch) => {
  //控制是否打开编辑Model
  dispatch(editVisible(is));
};
const setAddVisible = (is) => async (dispatch) => {
  //控制是否打开添加Model
  console.log(is);
  dispatch(addVisible(is));
};

//修改检测数据
const editTableData = (value, pageCode, review_type, review_content_id, state) => async (dispatch) => {
  try {
    const { data } = await axios({
      url: api.editTableData,
      method: 'put',
      data: value,
    });
    console.log(data);
    message.success(data.msg);
    dispatch(editVisible(false));
    getTableDate(dispatch, pageCode, review_type, review_content_id, state);
  } catch (error) {
    const { message: msg = '修改数据失败' } = error;
    message.error(msg);
  }
};

//添加检测数据
const addTableData = (value, pageCode, review_type, review_content_id, state) => async (dispatch) => {
  console.log(value);
  try {
    const { data } = await axios({
      url: api.addTableData,
      method: 'post',
      data: value,
    });
    console.log(data);
    message.success(data.msg);
    dispatch(addVisible(false));
    getTableDate(dispatch, pageCode, review_type, review_content_id, state);
  } catch (error) {
    const { message: msg = '新增数据失败' } = error;
    message.error(msg);
  }
};

export default {
  getNewTableDate,
  setSelectedRowKeys,
  getSelectData,
  delTableData,
  setEditVisible,
  setAddVisible,
  editTableData,
  addTableData,
};
