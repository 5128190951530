/* eslint-disable */
/*
 * @Author: kiliaosi
 * @Date: 2020-04-17 10:24:13
 * @LastEditors: kiliaosi
 * @LastEditTime: 2020-06-11 12:04:36
 * @Description:
 */
import axios from '@/utils/request';

import {
  freezeAction,
  getTableDataAction,
  updataWordAction,
  addAction,
  updateAction,
  realupdataAction,
  findAgentTwoAction,
  realupdatatwoAction,
  getAgentListAction,
  getAgentNameListAction,
  getEnterpriseTypeListAction,
  getProjectManagerListAction,
  getSalesManagerListAction,
} from './action';
import { message, Modal } from 'antd';
import { get } from 'lodash';
import React from 'react';

// 获取所有的代理商
const getTableData = (params) => async (dispatch) => {
  try {
    const data = await axios.post(`/saas-auth/admin/enterprise/list`, params || {});
    if (data) {
      const { data: dataSource } = data;
      if (dataSource.code === 1) {
      } else {
        message.error(dataSource.message);
        return;
      }
      dispatch(getTableDataAction(dataSource));
    }
  } catch (error) {
    const data = get(error, 'response.data', {});
    const { msg = '获取数据失败' } = data;
    message.error(msg);
  }
};

// 添加代理商
const addAgent = (param) => async (dispatch) => {
  try {
    const { data } = await axios.post('/saas-auth/admin/enterprise/add', param);
    return data;
  } catch (error) {
    try {
      let msg = error.response.data.msg ? error.response.data.msg : error.response.data[0];
      if (!msg) {
        msg = '添加失败';
      }
      message.error(msg);
    } catch (error) {
      message.error('添加失败');
    }
    console.log('添加失败错误信息', error.response);
  }
};

// 获取单个代理商详细信息
const updateAgent = (enterpriseNo) => async (dispatch) => {
  try {
    const {
      data: { data },
    } = await axios.get(`/saas-auth/enterprise/detail`, {
      params: { enterpriseNo: enterpriseNo },
    });
    dispatch(updateAction(data));
  } catch (error) {
    const { message: msg = '数据获取失败' } = error;
    message.error(msg);
  }
};
// 修改代理商
const realUpdateAgent = (param) => async (dispatch) => {
  try {
    const { data } = await axios.post(`/saas-auth/admin/enterprise/update`, param);
    if (data.code == 1) {
      dispatch(realupdataAction(data));
      message.success('修改成功');
    } else {
      message.error(data.message);
    }
  } catch (error) {
    try {
      let msg = error.response.data[0] ? error.response.data[0] : '添加失败';
      message.error(msg);
    } catch (error) {
      message.error('添加失败了！');
    }
  }
};
// 获取单个二级代理商详细信息 (点击配置时)
const findAgentTwo = (enterpriseNo) => async (dispatch) => {
  try {
    // const { data } = await axios.post(`/saas-auth/admin/enterprise/config/detail`, { enterpriseNo: enterpriseNo });
    const { data } = await axios.post('/saas-auth/admin/enterprise/config/detail', {
      enterpriseNo,
    });
    if (data.code === 1) {
      dispatch(findAgentTwoAction(data.data));
      return data.data;
    } else {
      message.error(data.message);
    }
  } catch (error) {
    const { message: msg = '数据获取失败' } = error;
    message.error(msg);
  }
};
// 修改二级代理商配置
const realUpdateTwo = (enterpriseNo, param) => async (dispatch) => {
  try {
    const { data } = await axios.post(`/saas-auth/admin/enterprise/config/update`, param);
    if (data.code == 1) {
      dispatch(realupdatatwoAction(data));
      message.success('修改成功');
      return true;
    } else {
      message.error('修改失败');
      return false;
    }
  } catch (error) {
    message.error('修改失败');
  }
};

// 冻结代理商
const freeze = (enterpriseNo) => async (dispatch) => {
  try {
    let param = {
      enterpriseNo: enterpriseNo,
    };
    const { data } = await axios.post(`/saas-auth/admin/enterprise/freeze`, param);
    if (data.code == 1) {
      message.success('操作成功！');
      dispatch(freezeAction());
    } else {
      message.error(data.message);
    }
  } catch (error) {
    message.error('操作失败');
  }
};

// 解冻代理商
const unfreeze = (enterpriseNo) => async (dispatch) => {
  try {
    let param = {
      enterpriseNo: enterpriseNo,
    };
    const { data } = await axios.post(`/saas-auth/admin/enterprise/unfreeze`, param);
    if (data.code == 1) {
      message.success('操作成功！');
      dispatch(freezeAction());
    } else {
      message.error(data.message);
    }
  } catch (error) {
    message.error('操作失败');
  }
};

// 上传word模板
const uploadWord = (enterpriseNo, obj) => async (dispatch) => {
  try {
    let url = {
      ios: '/saas-auth/admin/enterprise/config/template/upload/customIosTemplate',
      android: '/saas-auth/admin/enterprise/config/template/upload/customAndroidTemplate',
      privacyManual: '/saas-auth/admin/enterprise/config/template/upload/customPrivacyManualTemplate',
      privacyAutomatic: '/saas-auth/admin/enterprise/config/template/upload/customPrivacyAutomaticTemplate',
    };

    const param = new FormData();
    param.append('enterpriseNo', enterpriseNo);
    param.append('file', obj.file);

    axios({
      url: url[obj.type],
      method: 'post',
      data: param,
    })
      .then((res) => {
        let data = res.data;
        if (data.code == 1) {
          message.success('上传成功');
          dispatch(updataWordAction());
        } else {
          message.error('上传失败：' + data.message);
        }
      })
      .catch((error) => {
        console.log(error);
        message.error('上传失败');
      });
  } catch (error) {
    console.error(error);
  }
};

const getAgentList = () => async (dispatch) => {
  try {
    const data = await axios.post(`/saas-auth/admin/enterprise/getAgentList`, {});
    if (data) {
      const { data: dataSource } = data;
      if (dataSource.code === 1) {
        dispatch(getAgentListAction(dataSource.data));
        return dataSource.data;
      } else {
        message.error(dataSource.message);
      }
    }
    return [];
  } catch (error) {
    const data = get(error, 'response.data', {});
    const { msg = '获取数据失败' } = data;
    message.error(msg);
  }
};

const getAgentNameList = () => async (dispatch) => {
  try {
    const data = await axios.get(`/saas-auth/admin/enterprise/all`, {});
    if (data) {
      const { data: dataSource } = data;
      if (dataSource.code === 1) {
        dispatch(getAgentNameListAction(dataSource.data));
      } else {
        message.error(dataSource.message);
      }
    }
  } catch (error) {
    const data = get(error, 'response.data', {});
    const { msg = '获取数据失败' } = data;
    message.error(msg);
  }
};

const getEnterpriseTypeList = () => async (dispatch) => {
  try {
    const data = await axios.post(`/saas-auth/admin/enterprise/getTypeList`, {});
    if (data) {
      const { data: dataSource } = data;
      if (dataSource.code === 1) {
        dispatch(getEnterpriseTypeListAction(dataSource.data));
        return dataSource.data;
      } else {
        message.error(dataSource.message);
      }
    }
    return [];
  } catch (error) {
    const data = get(error, 'response.data', {});
    const { msg = '获取数据失败' } = data;
    message.error(msg);
  }
};

// 获取项目经理
const getProjectManager = () => async (dispatch) => {
  try {
    const data = await axios.get(`/saas-auth/admin/enterprise/getPMList`);
    if (data) {
      const { data: dataSource } = data;
      if (dataSource.code === 1) {
        dispatch(getProjectManagerListAction(dataSource.data));
        return dataSource.data;
      } else {
        message.error(dataSource.message);
      }
    }
    return [];
  } catch (error) {
    const data = get(error, 'response.data', {});
    const { msg = '获取数据失败' } = data;
    message.error(msg);
  }
};

// 获取销售经理
const getSalesManager = () => async (dispatch) => {
  try {
    const data = await axios.get(`/saas-auth/admin/enterprise/getSMList`);
    if (data) {
      const { data: dataSource } = data;
      if (dataSource.code === 1) {
        dispatch(getSalesManagerListAction(dataSource.data));
        return dataSource.data;
      } else {
        message.error(dataSource.message);
      }
    }
    return [];
  } catch (error) {
    const data = get(error, 'response.data', {});
    const { msg = '获取数据失败' } = data;
    message.error(msg);
  }
};

export default {
  getTableData,
  uploadWord,
  freeze,
  unfreeze,
  addAgent,
  updateAgent,
  realUpdateAgent,
  findAgentTwo,
  realUpdateTwo,
  getAgentList,
  getAgentNameList,
  getEnterpriseTypeList,
  getProjectManager,
  getSalesManager,
};
