/* eslint-disable */
import { CHANGE_TABLE_DATA } from './actionType.js';
const defaultState = {
  tableData: ['测试'],
}; //默认数据

function reducer(state = defaultState, action) {
  //就是一个方法函数
  switch (action.type) {
    case CHANGE_TABLE_DATA:
      return {
        ...state,
        tableData: action.data,
      };
    default:
      return { ...state };
  }
}
export default reducer;
