/* eslint-disable */
import React from 'react';
import { Link } from 'react-router-dom';

class Verify extends React.Component {
  state = {
    ms: 4,
  };
  render() {
    setTimeout(() => {
      let ms = this.state.ms - 1;
      if (ms < 0) {
        this.props.history.push('/login');
        return;
      } else {
        this.setState({
          ms: ms,
        });
      }
    }, 1000);
    return (
      <div className="activ-back" style={{ textAlign: 'center' }}>
        <div className="activ">
          账号激活成功，请直接<Link to="/login">登录</Link>
          <span className="redirect">
            <i>{this.state.ms}</i>秒后自动跳转登录页
          </span>
        </div>
      </div>
    );
  }
}
export default Verify;
