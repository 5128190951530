/* eslint-disable */
/*
 * @Author: kiliaosi
 * @Date: 2020-02-29 17:25:08
 * @LastEditors: kiliaosi
 * @LastEditTime: 2020-02-29 18:45:16
 * @Description:
 */
import detectionIos from './reducer/index.js';

export default {
  detectionIos,
};
