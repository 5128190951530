/* eslint-disable */
/*
 * @Author: kiliaosi
 * @Date: 2020-02-29 18:39:20
 * @LastEditors: kiliaosi
 * @LastEditTime: 2020-06-15 15:35:46
 * @Description:
 */
import axios from '@/utils/request';
import { message, Modal } from 'antd';
import action from './action';
import { get } from 'lodash';

//获取所有检测任务
const loadAllRecord = (params) => async (dispatch) => {
  if (params === undefined) {
    //解决检测中表格显示不准问题
    let code = 1; //hash所记录的页数
    let fil = 'all'; //hash所记录的筛选
    let { hash = '' } = window.location;
    if (hash.length) {
      let obj = JSON.parse(hash.slice(1).replace(/%22/g, '"'));
      let { pageCode = 1, filter = 'all' } = obj;
      code = pageCode;
      fil = filter;
    }
    params = { pageCode: code, filter: fil };
  }

  try {
    const { data: result } = await axios.get('/ios-detect/ios/assessment/list', { params });
    const { data, code, length } = result;
    dispatch(action.loadDetectionLength(length));
    if (code !== 'success') {
      return message.error('iOS检测记录获取错误');
    }
    dispatch(action.loadAll(data));
  } catch (error) {
    if (error.response) {
      message.error('iOS检测记录获取错误');
    }
  }
};

//上传及设置检测任务
const uploadWork = (form, callback) => async (dispatch) => {
  dispatch(action.uploadState(true));
  try {
    const { data: result = {} } = await axios({
      url: '/ios-detect/ios/assessment/upload',
      method: 'post',
      data: form,
      onUploadProgress: function (progressEvent) {
        if (progressEvent.lengthComputable) {
          let total = progressEvent.total / 1024 / 1024; //总量
          let loaded = (progressEvent.loaded / 1024 / 1024).toFixed(2); //已上传
          let Surplus = Math.abs((total - loaded).toFixed(2)); //剩余
          let progress = (loaded / total).toFixed(2) * 100;

          dispatch(action.loadProgress(progress, { total: Surplus, loaded: loaded }));
        }
      },
    });

    message.success('文件上传成功');
    window.location.search = `id=${result.app_md5}`;
  } catch (error) {
    const { message: msg, response = {} } = error;
    Modal.error({
      title: '文件上传失败',
      content: response.data ? response.data.msg : '请联系工作人员',
    });
  }
  callback();
  dispatch(action.uploadState(false));
  dispatch(action.loadProgress(1 / 100, { total: 100, loaded: 0 }));
};

//获取界面数据

const loadPage = (md5) => async (dispatch) => {
  try {
    const { data: result } = await axios.post('/ios-detect/ios/assessment/page', {
      app_md5: md5,
    });
    const { data = [], code, length } = result;
    if (code === 'success') {
      data.totalLength = length;
      dispatch(action.loadReport(data));
    } else {
      message.error('获取报告出错');
    }
  } catch (error) {
    const data = get(error, 'response.data', {});
    const { msg = '获取数据失败' } = data;
    message.error(msg);
  }
};

//获取检测进度
const loadDetectionState = (md5) => async (dispatch) => {
  const { data: result } = await axios.get(`/ios-detect/ios/assessment/list/${md5}`);
  dispatch(action.loadState(result));
};
// 重新检测
const againTest = (app_md5) => async (dispatch) => {
  // alert(app_md5);
  await axios.post('/ios-detect/ios/assessment/reset', { app_md5 });
  window.location.reload(true);
};

const powerInformation = () => async (dispatch) => {
  //获取权限
  try {
    const { data } = await axios.post('/saas-order/order/permission/detail', { productKey: 'ios_detection_system' });
    console.log(data);
    let code = get(data, 'code', '');
    if (code === 1) {
      return data.data;
    }
  } catch (error) {
    const data = get(error, 'response.data', {});
    const { msg = '获取数据失败' } = data;
    message.error(msg);
  }
};

export default {
  loadAllRecord,
  uploadWork,
  loadPage,
  loadDetectionState,
  againTest,
  powerInformation,
};
