/* eslint-disable */
import { GETTHREATSCENARIOS, GETAPKINFO } from './actionType';

// 获取加固策略的加固项 action
const getThreatScenariosAction = (data) => ({
  type: GETTHREATSCENARIOS,
  data,
});
// 获取apk解析信息 action
const getApkInfoAction = (data) => ({
  type: GETAPKINFO,
  data,
});

export { getApkInfoAction, getThreatScenariosAction };
