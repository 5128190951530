/* eslint-disable */
import React, { useState } from 'react';
import md5 from 'md5';
import { Form, Input, Button, Row, Col } from 'antd';
import { Link } from 'react-router-dom';
import userNameImg from '../img/login-user.png';
import passwordImg from '../img/pwd-icon.png';
import { login } from '../../request/request';
import './login.less';
const FormItem = Form.Item;
const Login = (props) => {
  // 是否展示错误信息
  const [showErr, setShowErr] = useState(false);
  // 具体错误信息文本
  const [errStr, setErrStr] = useState('');
  const handleSubmit = (e) => {
    e.preventDefault();
    props.form.validateFields(async (err, values) => {
      if (!err) {
        console.log('登录信息', values);
        values.password = md5(values.password);
        const data = await login(values);
        console.log('18', data);
        if (data) {
          // 邮箱激活
          if (data === 2020012) {
            setShowErr(true);
            setErrStr('该邮箱未激活,请<a class="link"  href="/user/active">前往激活</a>');
            return;
          }
          if (data === 2020013) {
            setShowErr(true);
            setErrStr('该手机号未激活,请<a class="link"  href="/user/activePhone">前往激活</a>');
            return;
          }
          props.history.push('/');
          setTimeout(() => {
            location.reload();
          }, 500);
        }
      }
    });
  };
  const { getFieldDecorator } = props.form;
  let { loginTitle } = window.webConfig;
  return (
    <div className="common-panel-box">
      <div className="common-panel-content">
        <span className="panel-title">{loginTitle}</span>
        <div className='pandel-content-box'>
          <Form onSubmit={handleSubmit} className="loginAndRegisterForm">
            <FormItem className="login-user">
              {getFieldDecorator('account', {
                rules: [
                  { required: true, message: '用户名不能为空' },
                  { min: 6, message: '用户名不能低于6个字符' },
                ],
              })(
                <Input
                  prefix={
                    <span className="login-icon">
                      <img src={userNameImg} />
                    </span>
                  }
                  className="username-info"
                  placeholder="请输入 Email / 手机号"
                />,
              )}
            </FormItem>
            {/* 按钮界面 */}
            <FormItem className="login-paw">
              {getFieldDecorator('password', {
                rules: [
                  { required: true, message: '密码不能为空' },
                  { min: 6, message: '密码不能低于6个字符' },
                ],
              })(
                <Input
                  prefix={
                    <span className="login-icon">
                      <img src={passwordImg} />
                    </span>
                  }
                  type="password"
                  placeholder="登录密码"
                  id="username-password"
                />,
              )}
              {showErr && <div className="errlist" dangerouslySetInnerHTML={{ __html: errStr }} />}
            </FormItem>
            <FormItem>
              <Button
                // loading={state.loading ? true : false}
                type="primary"
                htmlType="submit"
                className="login-form-button"
              >
                登录
              </Button>
            </FormItem>
            <FormItem>
              <Row>
                <Col span={13} className="resisterandforgetPwd">
                  {/* 是否允许注册 */}
                  {window?.webConfig?.enabledRegister ? (
                    <Link
                      to="/login/register"
                      className="register-word"
                      onClick={() => {
                        // changeUserOperation({ code: 2 });
                      }}
                    >
                      注册账号
                    </Link>
                  ) : null}
                  {/* 是否允许忘记密码 */}
                  {window?.webConfig?.enabledForget ? (
                    <Link className="forgetPassword-word" to="/login/forgetPassword">
                      忘记密码
                    </Link>
                  ) : null}
                </Col>
              </Row>
            </FormItem>
          </Form>
        </div>
      </div>
    </div>
  );
};
const WrappedNormalLoginForm = Form.create()(Login);
export default WrappedNormalLoginForm;
