/* eslint-disable */
/*
 * @Author: kiliaosi
 * @Date: 2020-02-29 17:25:08
 * @LastEditors: kiliaosi
 * @LastEditTime: 2020-03-26 14:40:18
 * @Description:
 */

import actionType from './actionType';

const againDetectionAction = () => ({
  type: actionType.againDetection,
});
const loadAll = (payload) => ({
  type: actionType.loadAllRecord,
  payload,
});

const changeState = (payload) => ({
  type: actionType.changeState,
  payload,
});

const loadProgress = (payload, fileInfo) => ({
  type: actionType.progress,
  fileInfo,
  payload,
});

//上传状态
const uploadState = (payload) => ({
  type: actionType.uploading,
  payload,
});

//加载当前报告

const loadReport = (payload) => ({
  type: actionType.loadReport,
  payload,
});
//加载当前检测状态
const loadState = (payload) => ({
  type: actionType.detectionState,
  payload,
});

//加载任务长度
const loadDetectionLength = (payload) => ({
  type: actionType.loadDetectionLength,
  payload,
});

export default {
  loadAll,
  changeState,
  uploadState,
  loadProgress,
  loadReport,
  loadState,
  loadDetectionLength,
  againDetectionAction,
};
