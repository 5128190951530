/* eslint-disable */
const api = {
  // uoload: "/encrypt/android/upload?nextTodo=1", // 上传包的接口
  uoload: '/appguard/backend/project/file/upload', // 上传包的接口
  getGroupList: '/appguard/ios_minitor/project/group/list', // 获取项目组
  createGroup: '/appguard/ios_minitor/project/group/create', // 创建项目组
  updateGroup: '/appguard/ios_minitor/project/group/update', // 编辑项目组
  delGroup: '/appguard/ios_minitor/project/group/delete', // 删除项目组
  updateChildTable: '/appguard/backend/project/info/update', // 编辑子表格的渠道和归属应用
  updatehandChildTable: '/appguard/ios_minitor/project/artificial/info_update ', // 编辑子表格的手动接入的渠道和归属应用appname 和version
  getSafeIdea: '/appguard/backend/dynamic/policy/list', // 获取安全策略数组
  getThreatScenarios: '/appguard/backend/dynamic/policy/findOne', // 得到威胁场景
  getApkInfo: '/appguard/backend/project/protect/info', //获取apk的详细信息
  getInsertState: '/appguard/backend/project/task/state', //获取接入的实时状态
  startInsert: '/appguard/backend/project/task/create', // 开始接入
  cancelInsert: '/appguard/backend/project/cancel/state', // 取消接入
  downloadInsertPackage: '/appguard/backend/project/download/package', // 下载接入包
  delChildTableApp: '/appguard/ios_minitor/project/delete', // 删除子表格接入的包
  getVersionList: '/appguard/backend/appguardVersion/list', // kiwiguard版本
  getPower: '/saas-order/order/permission/detail',
  handInsert: '/appguard/ios_minitor/project/artificial/create', // 手动接入
};
export default api;
