/* eslint-disable */
export const SEND_EMAIL = 'SEND_EMAIL'; //发送邮件
export const SEND_EMAIL_END = 'SEND_EMAIL_END'; //发送结束
export const SEND_EMAIL_SUCC = 'SEND_EMAIL_SUCC'; //发送成功
export const SEND_EMAIL_ERR = 'SEND_EMAIL_ERR'; //发送失败
export const CHANGE_NEW_DATA = 'CHANGE_NEW_DATA'; //改变当前时间
//短信验证码找回
export const SEND_PHONE_CODE = 'SEND_PHONE_CODE'; //发送验证码
export const SEND_PHONE_CODE_END = 'SEND_PHONE_CODE_END'; //验证码等待时间结束
export const SEND_PHONE_MSG = 'SEND_PHONE_MSG'; //发送等待时间

export const SEND_PHONE_RESET = 'SEND_PHONE_RESET'; //发起重置
export const SEND_PHONE_RESET_END = 'SEND_PHONE_RESET_END'; //重置结束
