/* eslint-disable */
import * as actionType from './actionType.js';
const init = {
  dataSource: [],
  loading: false,
  total: 0,
};
export default function LoginRecord(state = init, action) {
  switch (action.type) {
    case actionType.LOGIN_RECORD_UPDATE_DATA:
      return {
        ...state,
        dataSource: action.dataSource,
        loading: false,
        total: action.total,
      };
    case actionType.LOGIN_RECORD_LOADING:
      return { ...state, loading: true };
    case actionType.GET_AGENT_NAME_LIST: {
      return { ...state, agentNameList: action.data };
    }
    default:
      return state;
  }
}
