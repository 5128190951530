/* eslint-disable */
import * as constants from './constants.js';
const init = {
  activeLoading: false,
  ImgCodeTime: new Date().getTime(),
};
export default function activeReducer(state = init, action) {
  switch (action.type) {
    case constants.ACTIVE_SEND_EMAIL:
      return { ...state, activeLoading: true };
    case constants.ACTIVE_SEND_SUCCESS:
      return { ...state, activeLoading: false, errorMsg: false };
    case constants.ACTIVE_SEND_ERR:
      return { ...state, activeLoading: false, errorMsg: action.msg };
    case constants.ACTIVE_CHANGE_CODE_IMG:
      return { ...state, ImgCodeTime: new Date().getTime() };
    default:
      return state;
  }
}
