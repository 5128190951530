/* eslint-disable */
import * as actionType from './actionType.js';
import moment from 'moment';
import React from 'react';
import { Badge } from 'antd';
const defaultState = {
  dataSource: [],
  columns: [
    {
      title: '序号',
      key: 'key',
      render: (text, record, index) => {
        const { current = 1 } = defaultState.pageIndex;
        return <span>{(current - 1) * 10 + index + 1}</span>;
      },
    },
    {
      title: '产品&服务',
      dataIndex: 'productName',
      key: 'productName',
    },
    {
      title: '授权时间',
      dataIndex: 'authorizeTime',
      key: 'authorizeTime',
    },
    {
      title: '授权数量',
      dataIndex: 'authorizeCount',
      key: 'authorizeCount',
    },
    {
      title: '订单状态',
      dataIndex: 'statusDesc',
      key: 'statusDesc',
      // align:'center',
    },
  ],
  pageIndex: 1,
  isChangeBasicInfo: false,
  basicInfo: null,
  loading: true, //表格加载状态
}; //默认数据

export default (state = defaultState, action) => {
  //就是一个方法函数
  switch (action.type) {
    case actionType.CHANGE_PAGE:
      return { ...state, isChangeBasicInfo: action.data };
    case actionType.GET_BASIC_DATA:
      return { ...state, basicInfo: action.data };
    case actionType.GET_TABLE_DATA:
      return { ...state, dataSource: action.data.data, loading: false };
    // case actionType.CHANGE_BTN:
    //     return { ...state, inputValue: action.value ,isShow:false};
    default:
      return { ...state };
  }
};
