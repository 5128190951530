/* eslint-disable */
import * as actionType from './actionType.js';
const defaultState = {
  test: '数据测试',
  isgoeDitor: false, //是否跳转授权页
  editorId: '', //授权角色ID
  editorRoleNo: '', // 授权角色 roleNo
  isOpenRoleModels: false, //是否打开添加角色Model

  roleTableData: [],
  loading: true, //table是否加载
  tableCount: 0, //表格数据总数

  allKeys: [], //所有权限信息
  checkedKeys: [], //缓存 默认权限信息 用于上传更新与还原

  isReceiveProps: true, //默认可以渲染

  isOpenAndroidEncryptCollapseLogModal: false, // 加固日志包管理 Modal 控制
}; //默认数据

export default (state = defaultState, action) => {
  //就是一个方法函数
  switch (action.type) {
    case actionType.GOTO_EDITOR:
      return {
        ...state,
        isgoeDitor: action.data,
        editorId: action.id,
        editorRoleNo: action.roleNo,
        checkedKeys: [],
      }; //defLimitArray目的为清空上传缓存记录
    case actionType.OPEN_ROLE_MODEL:
      return { ...state, isOpenRoleModels: action.data };
    case actionType.CHANGE_TABLE_COUNT:
      return { ...state, tableCount: action.data };
    case actionType.CHANGE_TABLE_DATA:
      return { ...state, roleTableData: action.data };
    case actionType.CHANGE_ALL_LIMIT_INFO:
      return {
        ...state,
        allKeys: action.data,
        checkedKeys: action.defArr,
      };
    case actionType.DEF_LIMIT_INFO:
      return {
        ...state,
        checkedKeys: action.data,
      };
    case actionType.CHANGE_CACHE_LIMIT:
      return { ...state, checkedKeys: action.data };
    case actionType.CHANGE_TABLE_LOADING:
      return { ...state, loading: action.data };
    case actionType.CHANGE_RECEIVE_PROPS:
      return { ...state, isReceiveProps: action.data };
    case actionType.OPEN_ANDROID_COLLAPSE_LOG_MODAL:
      return { ...state, isOpenAndroidEncryptCollapseLogModal: action.data }; // 加固日志包管理 Modal 控制
    case actionType.CHANGE_EDITOR_ROLE_NO:
      return { ...state, editorRoleNo: action.roleNo }; // 加固日志包管理 Modal 控制
    default:
      return { ...state };
  }
};
