/* eslint-disable */
/*
 * @Author: kiliaosi
 * @Date: 2020-06-15 14:47:56
 * @LastEditors: kiliaosi
 * @LastEditTime: 2020-06-18 16:58:44
 * @Description:
 */

import React from 'react';
import { Form, Input, Button, Row, Col } from 'antd';
import VerifyCode from '@/common/verifyCode';

const FormItem = Form.Item;
class SendEmail extends React.Component {
  constructor(props) {
    super(props);
    this.state = { codeTime: new Date().getTime() };
  }
  changeCodeTime = () => {
    this.setState({
      codeTime: new Date().getTime(),
    });
  };
  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        //console.log(values);
        this.props.sendEmail(values);
      }
    });
  };
  render() {
    let regEmail = sessionStorage.getItem('activeEmail');
    console.log('imgSrc', this.props.ImgCodeTime);
    console.log('激活邮件页面props', this.props);
    const { getFieldDecorator } = this.props.form;
    return (
      <div className="newsendEmail">
        <div className="title">发送邮箱验证链接</div>
        <Form onSubmit={this.handleSubmit}>
          <FormItem>
            {getFieldDecorator('email', {
              initialValue: regEmail,
              rules: [
                { required: true, message: '邮箱不能为空' },
                { min: 6, message: '请输入邮箱' },
                {
                  pattern: /^[a-zA-Z0-9]+([-_.][a-zA-Z0-9]+)*@[a-zA-Z0-9]+([-_.][a-zA-Z0-9]+)*\.[a-z]{2,}$/,
                  message: '邮箱格式错误',
                },
              ],
            })(<Input className="username-info" placeholder="邮箱账号" />)}
          </FormItem>

          {/*按钮界面*/}
          <FormItem className="err-err-panel">
            <Row>
              <Col span={14}>
                <input type="password" style={{ display: 'none' }} />
                {getFieldDecorator('checkcode', {
                  rules: [
                    { required: true, message: '验证码不能为空' },
                    { min: 4, message: '验证码不能少于四位' },
                  ],
                })(<Input type="text" autoComplete="false" placeholder="验证码" />)}
              </Col>
              <Col span={10}>
                <div className="checkCode">
                  {/* <> */}
                  <VerifyCode onClick={this.changeCodeTime} timestap={this.props.ImgCodeTime + this.state.codeTime} />
                </div>
              </Col>
            </Row>
            {this.props.errorMsg ? (
              <div className="ant-form-explain errlist" dangerouslySetInnerHTML={{ __html: this.props.errorMsg }}></div>
            ) : null}
          </FormItem>

          <FormItem>
            <Button loading={this.props.activeLoading} type="primary" htmlType="submit" className="login-form-button">
              提交
            </Button>
          </FormItem>
          <a
            className="login-form-button-go"
            onClick={() => {
              this.props.history.push('/login');
            }}
          >
            返回
          </a>
        </Form>
      </div>
    );
  }
}
const SendEmails = Form.create()(SendEmail);
export default SendEmails;
