/* eslint-disable */
import React from 'react';
import FindPhonePwd from './component/phone_find'; // 手机找回
import FindEmailPwd from './component/email_find'; //  邮箱找回
import { Tabs, Icon } from 'antd';
import './style/fogetPassword.less';

const TabPane = Tabs.TabPane;
const FindPwd = () => {
  let webConfig = window.webConfig;
  const allowPhone = webConfig?.allowLoginPhone;
  const allowEmail = webConfig?.allowLoginEmail;
  let { loginTitle } = window.webConfig;
  return (
    <div className="findPwd-panel-box">
      <div className="common-panel-content">
        <span className="panel-title">{loginTitle}</span>
        <div className="title33">重置密码</div>
        <Tabs animated={false}>
          {allowPhone ? (
            <TabPane
              tab={
                <span className="min-dark">
                  <Icon type="mobile" />
                  手机找回
                </span>
              }
              key="1"
            >
              <FindPhonePwd />
            </TabPane>
          ) : null}
          {allowEmail ? (
            <TabPane
              tab={
                <span className="min-dark">
                  <Icon type="mail" />
                  邮箱找回
                </span>
              }
              key="2"
            >
              <FindEmailPwd />
            </TabPane>
          ) : null}
        </Tabs>
      </div>
    </div>
  );
};
export default FindPwd;
