/* eslint-disable */
import * as actionType from './actionType.js';
import axios from '@/utils/request';
import { Redirect } from 'react-router-dom';
import { message, Modal } from 'antd';
import promise from 'es6-promise';
promise.polyfill();
const api = {
  registerByPhone: '/saas-auth/open/registerByPhone',
  registerByEmail: '/saas-auth/open/registerByEmail',
  getPhoneCode: '/saas-auth/open/sendVerifyCode', //短信验证码
  getEmailCode: '/user/getEmailCode', //
};
//全局error提示
function CommonError(title, content) {
  Modal.confirm({
    title: title,
    content: content,
    okType: 'primary',
    okText: '确定',
    cancelText: '取消',
    onOk: () => {
      if (/请前往邮箱激活/.test(content)) {
        window.location.href = '/user/active';
      }
    },
  });
}
//获取短信验证码
export function getPhoneCode(phone) {
  return (dispatch) => {
    dispatch({ type: actionType.REG_CODE_LOADING });
    dispatch({
      type: actionType.REG_CODE_MIN_LOADING,
      phoneMessage: '发送中...',
      time: null,
    });
    axios
      .post(api.getPhoneCode, { phone: phone })
      .then((res) => {
        if (res.data.code == 1) {
          message.success('短信验证码已经成功发送');
          var time = 60;
          const timer = setInterval(() => {
            time--;
            if (time < 1) {
              dispatch({
                type: actionType.REG_CODE_MIN_LOADEND,
                phoneMessage: `获取短信验证码`,
              });

              clearInterval(timer);
              return;
            }
            dispatch({
              type: actionType.REG_CODE_MIN_LOADING,
              phoneMessage: `已发送(${time})`,
              time: time,
            });
          }, 1000);
        } else {
          // Modal.error({
          //   // title: 'This is an error message',
          //   content: '该手机号码已绑定其他账号，请更换号码',
          // });
          message.error(res.data.msg, [3]);
          dispatch({
            type: actionType.REG_CODE_MIN_LOADEND,
            phoneMessage: `获取短信验证码`,
          });
        }
        dispatch({ type: actionType.REG_CODE_LOADEND });
      })
      .catch((err) => {
        message.error('短信验证码发送失败', [3]);
        dispatch({
          type: actionType.REG_CODE_MIN_LOADEND,
          phoneMessage: `获取短信验证码`,
        });
      });
  };
}
//获取邮箱验证码
export function getEmailCode(email, callback) {
  axios
    .get(api.getEmailCode, { email: email })
    .then((res) => {
      if (res.data.code == 1) {
        callback(null, res.data);
      } else {
        callback(res.data);
      }
    })
    .catch((err) => {
      callback(err);
    });
}

//注册公共函数

function CommonRegister(obj, callback) {
  let reqObj = {};
  let url;
  if (obj.phone) {
    reqObj.phone = obj.phone;
    reqObj.regType = 0;
    reqObj.phone_code = obj.phonecode;
    reqObj.checkcode = obj.checkcode;
    reqObj.code = obj.phonecode;
    url = api.registerByPhone;
  }
  if (obj.email) {
    reqObj.email = obj.email;
    reqObj.regType = 1;
    if (obj.checkcode) {
      reqObj.checkcode = obj.checkcode;
    }
    reqObj.code = obj.checkcode;
    url = api.registerByEmail;
    //放入domain
    reqObj.domain = window.location.host;
  }
  reqObj.password = obj.password;
  axios
    .post(url, reqObj)
    .then((res) => {
      if (res.data.code == 1) {
        callback(false, res.data);
      } else {
        //console.log(res);
        callback(res.data);
      }
    })
    .catch((err) => {
      callback(err);
    });
}
//手机注册
export function phoneRegister(obj) {
  return (dispatch) => {
    dispatch({ type: actionType.REG_LOADING });
    let regObj = {
      phone: obj.phoneNumber,
      phonecode: obj.phonecode,
      checkcode: obj.checkcode,
      password: obj.password,
    };
    const dispatcher = dispatch;
    const that_ = this;
    CommonRegister(regObj, function (err, data) {
      dispatcher.bind(that_);
      if (err) {
        message.error(err.msg);
        if (err.code == -2) {
          dispatch({ type: actionType.REG_SHOW_CODE });
          dispatch({ type: actionType.REG_UPDATE_CODE });
        }
        dispatcher({ type: actionType.REG_LOADINGEND });
      } else {
        message.success('注册成功，即将跳转');
        window.location.href = '/';
        dispatcher({ type: actionType.REG_LOADINGEND });
      }
    });
  };
}
//邮箱注册
export function mailRegister(obj) {
  return (dispatch) => {
    dispatch({ type: actionType.REG_EMAIL_LOADING });
    var checkcode = obj.checkcode;
    const newObj = {
      email: obj.mailName,
      password: obj.password,
      checkcode: checkcode,
    };
    CommonRegister(newObj, function (err, data) {
      const regEmail = newObj.email;
      if (err) {
        //console.log(err);
        switch (err.code) {
          case 2020011:
            message.error('该邮箱已被注册请直接登录，或更换注册邮箱');
            break;
          case 2020012:
            Modal.warning({
              okText: '确定',
              title: '发送邮箱验证链接',
              content: `${newObj.email}已注册但未激活，点击下面激活邮箱将去到邮件激活页面。`,
            });
            sessionStorage.setItem('activeEmail', regEmail);
            // CommonError("该邮箱已注册但未激活，是否重新发送邮件激活链接？");
            dispatch({ type: actionType.SHOW_ACTIVE_BTN }, regEmail);
            break;
          case -2:
            message.error('验证码错误');
            dispatch({ type: actionType.REG_SHOW_CODE });
            dispatch({ type: actionType.REG_UPDATE_CODE });
            break;
          case 0:
            message.error('邮件发送失败，请换其他方式[如:手机号]');
            break;
          default:
            message.error('未知错误,请稍后再试');
        }
      } else {
        // message.success("注册成功,请前往邮箱激活");
        Modal.success({
          content: '注册成功,请前往邮箱激活!',
        });
      }
      dispatch({ type: actionType.REG_EMAIL_LOADEND });
    });
  };
}

// 点击更改验证码
export function updateImgCode() {
  return (dispatch) => {
    dispatch({ type: actionType.REG_UPDATE_CODE });
  };
}
