/* eslint-disable */
import api from './api';
import axios from '@/utils/request';
import { message } from 'antd';
import { get } from 'lodash';

const getRulePocContent = async (obj) => {
  try {
    const { data } = await axios.get(api.getRulePocContent, { params: obj });
    let code = get(data, 'code', '');
    let msg = get(data, 'msg', '');
    if (code === 1) {
      return data.data;
    } else {
      message.error(msg);
      return false;
    }
  } catch (error) {
    console.error(error);
    let msg = get(error.response, 'data.msg', '');
    if (msg) {
      message.error(msg);
    }
    return false;
  }
}

export {
  getRulePocContent
}
