/* eslint-disable */
import React from 'react';
import './common/user.less';
import Register from './register/index'; //注册组件
import SyncLogin from './login/component/login.js'; //登录组件
// import QC from "../../utils/qc-1.0.1.js"; //qq登录所需库
import { message } from 'antd';
import axios from '@/utils/request';
import CenterBox from './center_box';
import Header from '@/layout/header';
import * as constants from './active/reducer/constants';

// const getJsonData = Config.getJsonData;

class User extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentAdd: 1,
    };
    this.changeUserOperation = this.changeUserOperation.bind(this);
    this.wxLogin = this.wxLogin.bind(this);
  }
  // 第三方微信登录
  wxLogin() {
    axios
      .post('/saas-auth/open/wechat/login/authorize')
      .then((res) => {
        if (res.data.code == 1) {
          window.location.href = res.data.data.authorizeUrl;
        } else {
          message.error(res.data.message);
        }
      })
      .catch((err) => {
        //console.log(err);
      });
  }
  // 第三方qq登录
  qqLogin() {
    //qq登录回调域  一个斜杠都不能错
    let isPrivateServer = window.webConfig ? window.webConfig.isPrivateServer : false;
    if (isPrivateServer) {
      return false;
    }
    window.location.href =
      'http://graph.qq.com/oauth2.0/show?which=Login&display=pc&client_id=101449976&response_type=token&scope=all&redirect_uri=http%3A%2F%2Fcloud.kiwisec.com%2F';
  }
  componentDidMount() {
    let isPrivateServer = window.webConfig ? window.webConfig.isPrivateServer : false;
    if (!isPrivateServer && window.QC.Login.check()) {
      window.QC.Login.getMe(function (openId, accessToken) {
        var qqSin = {
          openId: openId,
          accessToken: accessToken,
        };
        axios({
          method: 'post',
          url: '/user/qqLogin',
          data: qqSin,
        }).then((res) => {
          if (res.data.code == 1) {
            message.info('qq登录成功');
            window.location.href = '/';
          } else {
            message.error('登录失败');
          }
        });
      });
    }
  }
  changeUserOperation(obj) {
    this.setState({ currentAdd: obj.code });
  }
  render() {
    let copyright = window.webConfig.copyright;
    let isPrivateServer = window.webConfig.isPrivateServer;
    return (
      <div id="user">
        {/* 登录的头部 */}
        <Header isDefault={true} {...this.props} />
        {/* 登录中间的部分 */}
        <CenterBox>
          {
            //login
            this.state.currentAdd == 1 ? (
              <SyncLogin
                changeUserOperation={this.changeUserOperation}
                history={this.props.history}
                wxLogin={this.wxLogin}
                qqLogin={this.qqLogin}
                passwordDoMd5={false}
                isPrivateServer
              />
            ) : null
          }
          {
            //注册
            this.state.currentAdd == 2 ? (
              <Register changeUserOperation={this.changeUserOperation} wxLogin={this.wxLogin} qqLogin={this.qqLogin} />
            ) : null
          }
        </CenterBox>
        <div className="copy-right">
          {copyright ? copyright : `版权 © ${ new Date().getFullYear() } 成都盈海益讯科技有限公司 版权所有 蜀ICP备15015960号-1`}
        </div>
      </div>
    );
  }
}
export default User;
