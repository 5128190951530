/* eslint-disable */
import { GETTABLELIST, DEL, UPDATA, GETSYSTEMANAGMENT } from './actionType';

const gettablelistAction = (data) => ({
  type: GETTABLELIST,
  data,
});
const delAction = (data) => ({
  type: DEL,
  data,
});
const updataction = (data) => ({
  type: UPDATA,
  data,
});
const getSystemAction = (data) => ({
  type: GETSYSTEMANAGMENT,
  data,
});

export { gettablelistAction, delAction, updataction, getSystemAction };
