/* eslint-disable */
/*
 * @Author: kiliaosi
 * @Date: 2020-06-15 14:47:56
 * @LastEditors: kiliaosi
 * @LastEditTime: 2020-06-16 18:59:59
 * @Description:
 */
import React from 'react';
import FindPwd from './find/index.js'; //找回密码
import ResetPwd from './reset/reset.js'; //重置密码
import SendEmail from './active/index.js'; //发送邮件
import Verify from './verify/index.js'; //邮箱注册验证回调页面
import SendPhone from './activephone'; //发送手机验证
import WechatLogin from './wechatLogin/wechatLogin'; // 微信登陆过度页面
import './common/user.less';
import promise from 'es6-promise';
import CenterBox from './center_box';
import Header from '@/layout/header';

promise.polyfill();

class UserCore extends React.Component {
  render() {
    var srcs = window.location.pathname;
    var UserCom;
    switch (srcs) {
      case '/wx_web/checkLogin':
        UserCom = WechatLogin;
        break;
      case '/user/resetpwd':
        UserCom = ResetPwd;
        break;
      case '/user/findpwd':
        UserCom = FindPwd;
        break;
      case '/user/active':
        UserCom = SendEmail;
        break;
      case '/user/verify':
        UserCom = Verify;
        break;
      case '/user/activephone':
        UserCom = SendPhone;
        break;
      default:
        UserCom = ResetPwd;
    }

    return (
      <div id="user">
        <Header isDefault={true} {...this.props} />
        <CenterBox>
          <UserCom {...this.props} />
        </CenterBox>
        <div className="copy-right">版权 © 2014-{ new Date().getFullYear() } 成都盈海益讯科技有限公司 版权所有 蜀ICP备15015960号-1</div>
      </div>
    );
  }
}
export default UserCore;
