/* eslint-disable */
import {
  UPDATAWORD,
  GETTABLEDATA,
  DEL,
  ADD,
  UPDATA,
  REALUPDA,
  FINDAGENTTWO,
  REALUPDATWO,
  FREEZE,
  GET_AGENT_LIST,
  GET_AGENT_NAME_LIST,
  GET_ENTERPRISE_TYPE_LIST,
  GET_PROJECT_MANAGER_LIST,
  GET_SALES_MANAGER_LIST,
} from './actionType';

const defaultState = {
  list: [], // table数据
  singleAgent: [], // 单个代理商的数据
  twoAgentData: [], // 单个代理商的数据 点击配置时
  loading: true,
  userpassword: [],
  total: 10,
};

function Agent(state = defaultState, action) {
  switch (action.type) {
    case GETTABLEDATA: {
      return {
        ...state,
        tableData: action.data.data.data,
        loading: false,
        total: action.data.data.total,
      };
    }
    case DEL: {
      return { ...state };
    }
    case ADD: {
      return { ...state, userpassword: action.data };
    }
    case UPDATA: {
      return { ...state, singleAgent: action.data };
    }
    case REALUPDA: {
      return { ...state };
    }
    case FINDAGENTTWO: {
      return { ...state, twoAgentData: action.data };
    }
    case REALUPDATWO: {
      return { ...state };
    }
    case FREEZE: {
      return { ...state };
    }
    case UPDATAWORD: {
      return { ...state };
    }
    case GET_AGENT_LIST: {
      return { ...state, agentList: action.data };
    }
    case GET_AGENT_NAME_LIST: {
      return { ...state, agentNameList: action.data };
    }
    case GET_ENTERPRISE_TYPE_LIST: {
      return { ...state, enterpriseTypeList: action.data };
    }
    case GET_PROJECT_MANAGER_LIST: {
      return { ...state, projectManagerArr: action.data };
    }
    case GET_SALES_MANAGER_LIST: {
      return { ...state, salesManagerArr: action.data };
    }
    default:
      return state;
  }
}
export default Agent;
