/* eslint-disable */
import Login from './login/reducer/loginReducer.js';
import Register from './register/reducer/regReducer.js';
import FindPwd from './find/reducer/findPwdReducer.js';
import ResetUserPwd from './reset/reducer/resetPwdReducer.js';
import SendEmailActive from './active/reducer/activeReducer.js';
import SendPhoneActive from './activephone/reducer/activeReducer';
export default {
  Login,
  Register,
  FindPwd,
  ResetUserPwd,
  SendEmailActive,
  SendPhoneActive,
};
