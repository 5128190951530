/* eslint-disable */
import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { Form, Input, Button, Row, Col, Modal } from 'antd';
import { findPwdByEmail } from '../../../request/request';
import VerifyCode from '@/common/verifyCode';
import { LoginUserImg, LoginYanzmaImg } from '../../common/config_img';
const FormItem = Form.Item;

const EmailFind = (props) => {
  const [currentTime, setcurrentTime] = useState('');
  const handleSubmit = (e) => {
    e.preventDefault();
    props.form.validateFields(async (err, values) => {
      if (!err) {
        values.domain = window.location.host;
        values._t = Date.parse(new Date());
        const data = await findPwdByEmail(values);
        if (data) {
          Modal.info({
            title: '已发送修改密码确认邮件至你的邮箱，请前往邮箱进行验证！',
            // content: <div>请前往邮箱验证</div>,
            onOk() {},
          });
        }
      }
    });
  };
  // 获取当前时间戳 获取不同的图片
  const changeCodeTime = () => {
    setcurrentTime(Date.parse(new Date()));
  };
  const { getFieldDecorator } = props.form;
  return (
    <Form onSubmit={handleSubmit} className="find-password">
      <input type="password" style={{ display: 'none' }} />
      <FormItem>
        {getFieldDecorator('email', {
          rules: [
            { required: true, message: '邮箱不能为空' },
            { min: 6, message: '请输入邮箱' },
            {
              // pattern: /^([0-9A-Za-z\-_\.]+)@([0-9a-z]+\.[a-z]{2,3}(\.[a-z]{2})?)$/g,  old
              pattern: /^[a-zA-Z0-9]+([-_.][a-zA-Z0-9]+)*@[a-zA-Z0-9]+([-_.][a-zA-Z0-9]+)*\.[a-z]{2,}$/,
              message: '邮箱格式错误',
            },
          ],
        })(
          <Input
            className="username-info"
            placeholder="邮箱账号"
            prefix={
              <span className="login-icon">
                <img src={LoginUserImg} />
              </span>
            }
          />,
        )}
      </FormItem>
      {/* 按钮界面 */}
      <FormItem className="err-err-panel">
        <Row>
          <Col span={14}>
            {getFieldDecorator('checkCode', {
              rules: [
                { required: true, message: '验证码不能为空' },
                { min: 4, message: '验证码不能少于四位' },
              ],
            })(
              <Input
                type="text"
                placeholder="请输入验证码"
                prefix={
                  <span className="login-icon">
                    <img src={LoginYanzmaImg} />
                  </span>
                }
              />,
            )}
          </Col>
          <Col span={10}>
            <div className="checkCode">
              <VerifyCode onClick={changeCodeTime} timestap={currentTime} />
            </div>
          </Col>
        </Row>
      </FormItem>
      <FormItem>
        <Button type="primary" htmlType="submit" className="login-form-button antdButtonSelf">
          提交
        </Button>
      </FormItem>
      <a
        className="login-form-button-go"
        onClick={() => {
          props.history.push('/login');
        }}
      >
        取消
      </a>
    </Form>
  );
};
const EmailFinds = Form.create()(EmailFind);
export default withRouter(EmailFinds);
