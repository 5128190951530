/* eslint-disable */
import { VERSION_CHANGE, UNREADER_NUM_ONMESSAGE } from './actionType';

// 版本号 action
const versionChangeAction = (data) => ({
  type: VERSION_CHANGE,
  data,
});
// 未读消息条数 action
const unReaderMessageNum = (data) => ({
  type: UNREADER_NUM_ONMESSAGE,
  data,
});
export { versionChangeAction, unReaderMessageNum };
