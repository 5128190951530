/* eslint-disable */
const namespace = 'ApplicationList2';
const GETTABLELIST = `${namespace}gettablelist`; // 获取table数据
const DEL = `${namespace}del`; // 删除
const UPDATA = `${namespace}updata`; //更新
const UPLOAD = `${namespace}upload`; // 上传函数
const SHOWUPLOADBAR = `${namespace}ShowUploadBar`; // 进度条显示控制
const CHECKEDDATA = `${namespace}checkeddata`; // 审查数据
const DETECTIONAPK = `${namespace}detectionapk`; // 检测apk

export { GETTABLELIST, DEL, UPDATA, UPLOAD, SHOWUPLOADBAR, CHECKEDDATA, DETECTIONAPK };
