/* eslint-disable */
const api = {
  login: '/saas-auth/open/login', // 登录接口
  getPhoneCode: '/saas-auth/open/sendVerifyCode', // 获取手机短信验证码
  getFindPwdPhoneCode: '/saas-auth/open/sendResetPasswordVerifyCode', // 获取找回密码手机短信验证码
  phoneRigister: '/saas-auth/open/registerByPhone', // 手机号注册
  emailRigister: '/saas-auth/open/registerByEmail', // 邮箱注册
  findPwdByPhone: '/saas-auth/open/resetPasswordByPhone', // 手机号找回密码
  findPwdByEmail: '/saas-auth/open/sendResetPasswordUrl', // 邮箱找回密码
  resetPassword: '/saas-auth/open/resetPassword', // 重置密码
  activeEmail: '/saas-auth/open/sendActiveUrl', // 发起邮箱激活链接
  getActivePhoneCode: '/saas-auth/open/sendActivePhoneVerifyCode', // 手机激活短信验证码
  activePhone: '/saas-auth/open/activePhone', // 发起手机激活链接
};
export default api;
