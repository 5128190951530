/* eslint-disable */
import axios from '@/utils/request';
import { message } from 'antd';
import { get } from 'lodash';
import { gettablelistAction, delAction, aplicationTypeListAction, aplicationTypeDetailAction } from './action';

// 添加应用类型
const addAplicationType = (obj) => async (dispatch) => {
  try {
    const { data } = await axios.post('/appprivacy/privacy/rule/create', obj);
    console.log('添加的结果', data);
    let code = get(data, 'code', '');
    if (code === 1) {
      // dispatch(gettablelistAction(data));
      message.success('添加成功');
    }
  } catch (error) {
    if (error.response) {
      let msg = get(error.response, 'data.msg', '添加失败');
      message.error(msg);
    } else {
      message.error('数据获取失败!');
    }
  }
};
// 获取应用类型列表数据
const getAplicationTypeList = () => async (dispatch) => {
  try {
    const dataAxios = await axios.get('/appprivacy/privacy/rule/list');
    if (dataAxios) {
      const { data } = dataAxios;
      let code = get(data, 'code', '');
      console.log('应用类型列表222', data, code);
      if (code === 1) {
        dispatch(aplicationTypeListAction(data.data));
      }
      return data.data;
    }
  } catch (error) {
    if (error.response) {
      let msg = get(error.response, 'data.msg', '数据获取失败');
      message.error(msg);
    } else {
      message.error('数据获取失败!');
    }
  }
};
// 获取详情 数据
const getAplicationTypeDetail = (obj) => async (dispatch) => {
  try {
    const { data } = await axios.get('/appprivacy/privacy/rule/detail', { params: obj });
    console.log('详情数据', data);
    let code = get(data, 'code', '');
    if (code === 1) {
      dispatch(aplicationTypeDetailAction(data.data));
    }
  } catch (error) {
    if (error.response) {
      let msg = get(error.response, 'data.msg', '数据获取失败');
      message.error(msg);
    } else {
      message.error('数据获取失败!');
    }
  }
};
// 获取TABLE 数据
const getTableList = (obj) => async (dispatch) => {
  try {
    const dataAxios = await axios.get('/appprivacy/privacy/rule/option/list', { params: obj });
    if (dataAxios) {
      const { data } = dataAxios;
      let code = get(data, 'code', '');
      // console.log("table数据",data)
      if (code === 1) {
        dispatch(gettablelistAction(data));
      }
    }
  } catch (error) {
    if (error.response) {
      let msg = get(error.response, 'data.msg', '数据获取失败');
      message.error(msg);
    } else {
      message.error('数据获取失败!');
    }
  }
};
// 删除
const delcompany = (obj) => async (dispatch) => {
  try {
    const { data } = await axios.get('/appprivacy/backend/report/data', { params: obj });
    let code = get(data, 'code', '');
    if (code === 1) {
      dispatch(delAction(data));
      message.success('删除成功');
    }
  } catch (error) {
    if (error.response) {
      let msg = get(error.response, 'data.msg', '数据获取失败');
      message.error(msg);
    } else {
      message.error('数据获取失败!');
    }
  }
};
export default {
  getTableList,
  delcompany,
  addAplicationType,
  getAplicationTypeList,
  getAplicationTypeDetail,
};
