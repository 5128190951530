/* eslint-disable */
const namespace = 'ROLEADMIN';
export const GOTO_EDITOR = `${namespace}GOTO_EDITOR`; //进入授权页
export const OPEN_ROLE_MODEL = `${namespace}OPEN_ROLE_MODEL`; //进入权限编辑页
export const CHANGE_TABLE_COUNT = `${namespace}CHANGE_TABLE_COUNT`; //更改Table数据总数
export const CHANGE_TABLE_DATA = `${namespace}CHANGE_TABLE_DATA`; //更改Table数据
export const CHANGE_ALL_LIMIT_INFO = `${namespace}CHANGE_ALL_LIMIT_INFO`; //更改所有权限信息
export const DEF_LIMIT_INFO = `${namespace}DEF_LIMIT_INFO`; //默认权限信息
export const CHANGE_CACHE_LIMIT = `${namespace}CHANGE_CACHE_LIMIT`; //修改缓存默认权限信息
export const CHANGE_TABLE_LOADING = `${namespace}CHANGE_TABLE_LOADING`; //更改Table加载动画

export const CHANGE_RECEIVE_PROPS = `${namespace}CHANGE_RECEIVE_PROPS`;

export const OPEN_ANDROID_COLLAPSE_LOG_MODAL = `${namespace}OPEN_ANDROID_COLLAPSE_LOG_MODAL`; // 控制android 加固的 modal
export const CHANGE_EDITOR_ROLE_NO = `${namespace}CHANGE_EDITOR_ROLE_NO`; // 控制android 加固的 modal
