/* eslint-disable */
const api = {
  getTableData: '/privacy-new/privacy/text/list', // 获取表格数据
  SelectOneData: '/privacy-new/privacy/text/type', // 获取审查类型
  SelectTwoData: '/privacy-new/privacy/text/content', //获取审查内容
  delTableData: '/privacy-new/privacy/text/delete', //删除表格数据
  editTableData: '/privacy-new/privacy/text/update', //修改表格数据
  addTableData: '/privacy-new/privacy/text/create', //添加表格数据
};
export default api;
