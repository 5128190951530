/* eslint-disable */
import * as actionType from './actionType.js';
var init = {
  sendEmail: false,
  sendEmailState: false, //false为关闭
  sendPhoneCodeMsg: '获取短信验证码',
  sendPhoneLoading: false,
  phoneCodeIs: false, //短信发送状态
  changeTimeDate: new Date().getTime(), //改变时间
};

export default function findPwdReducer(state = init, action) {
  switch (action.type) {
    case actionType.SEND_EMAIL:
      return { ...state, sendEmail: true };
    case actionType.SEND_EMAIL_END:
      return { ...state, sendEmail: false };
    case actionType.SEND_EMAIL_ERR:
      return { ...state, sendEmailState: false };
    case actionType.SEND_EMAIL_SUCC:
      return { ...state, sendEmailState: true };
    case actionType.SEND_PHONE_CODE:
      return { ...state, sendPhoneLoading: true };
    case actionType.SEND_PHONE_CODE_END:
      return { ...state, sendPhoneLoading: false };
    case actionType.SEND_PHONE_MSG:
      return { ...state, sendPhoneCodeMsg: action.loadMsg };
    case actionType.SEND_PHONE_RESET:
      return { ...state, phoneCodeIs: true };

    case actionType.SEND_PHONE_RESET_END:
      return { ...state, phoneCodeIs: false };

    case actionType.CHANGE_NEW_DATA:
      return { ...state, changeTimeDate: action.data };
    default:
      return { ...state };
  }
}
