/* eslint-disable */
import axios from '@/utils/request';
import { message, Modal } from 'antd';
import { get } from 'lodash';
import {
  gettablelistAction,
  delAction,
  updataction,
  uploadAction,
  ShowUploadBarAction,
  CheckedDataAction,
  detectionapkAction,
} from './action';
const { confirm } = Modal;
// 上传函数
const upload = (obj, callback, areaArr, timeView, workValue) => async (dispatch) => {
  await axios({
    url: '/appprivacy/privacy/application/upload',
    method: 'post',
    data: obj,
  })
    .then((res) => {
      if (res.data.code === 1) {
        let obj = {
          area_list: areaArr,
          start_time: timeView[0].format('YYYY-MM-DD'),
          end_time: timeView[1].format('YYYY-MM-DD'),
          state: workValue,
        };
        callback(obj); // 文件上传成功后 执行获取table数据的函数 不然要刷新一次才会看到上传成功过后的数据
        dispatch(ShowUploadBarAction(false));
        message.success('上传成功');
      } else {
        dispatch(ShowUploadBarAction(false));
        const msg = get(res, 'data.msg', '上传异常');
        message.error(msg);
      }
    })
    .catch((error) => {
      dispatch(ShowUploadBarAction(false));
      console.error('隐私上传错误', error.response);
      console.error('隐私上传错误', error);
      if (error.response) {
        let msg = get(error.response, 'data.msg', '请上传正确的文件');
        message.error(msg);
      } else {
        message.error('请上传正确的文件!');
      }
    });
};
// 上传的进度条显示
const uploadProgress = (progress) => (dispatch) => {
  dispatch(ShowUploadBarAction(true));

  if (progress) {
    console.log(progress);
    let newProgress = progress.toFixed(2) * 100;
    dispatch(uploadAction(newProgress));
  }
};
// 获取TABLE 数据
const getTableList = (obj) => async (dispatch) => {
  try {
    const dataAxios = await axios.get('/appprivacy/privacy/application/list', { params: obj });
    if (dataAxios) {
      const { data } = dataAxios;
      let code = get(data, 'code', '');
      if (code === 1) {
        dispatch(gettablelistAction(data.data));
      }
    }
  } catch (error) {
    if (error.response) {
      let msg = get(error.response, 'data.msg', '数据获取失败');
      message.error(msg);
    } else {
      message.error('数据获取失败!');
    }
  }
};
// 删除
const delcompany = (obj) => async (dispatch) => {
  try {
    const { data } = await axios.post('/appprivacy/privacy/application/delete', obj);
    let code = get(data, 'code', '');
    if (code === 1) {
      console.log('删除', data);
      dispatch(delAction(data));
      message.success('删除成功');
    }
  } catch (error) {
    if (error.response) {
      let msg = get(error.response, 'data.msg', '数据获取失败');
      message.error(msg);
    } else {
      message.error('数据获取失败!');
    }
  }
};
// 修改
const updatacompany = (obj) => async (dispatch) => {
  try {
    const { data } = await axios.get('/appprivacy/backend/report/data', { params: obj });
    let code = get(data, 'code', '');
    if (code === 1) {
      console.log('修改', data);
      dispatch(updataction(data));
    }
  } catch (error) {
    if (error.response) {
      let msg = get(error.response, 'data.msg', '数据获取失败');
      // message.error(msg);
      confirm({
        title: '审查数据获取失败',
        content: msg,
        onOk() {
          window.location.hash = '';
        },
        onCancel() {
          window.location.hash = '';
        },
      });
    } else {
      message.error('数据获取失败!');
    }
  }
};
// 获取审查数据
const CheckedData = (obj, flag = false) => async (dispatch) => {
  try {
    const { data } = await axios.get('/appprivacy/privacy/application/show', { params: obj });
    let code = get(data, 'code', '');
    if (code === 1) {
      // console.log("获取审查数据",data);
      let app_Violationsmessage_list = data.data.app_Violationsmessage_list;
      app_Violationsmessage_list.splice(0, 0, {
        key: '审查类一',
        question_desc: '以下行为可被认定为"未公开收集使用规则"',
        out: true,
      });
      app_Violationsmessage_list.splice(5, 0, {
        key: '审查类二',
        question_desc: '以下行为可被认定为"未明示收集使用个人信息的目的、方式和范围"',
        out: true,
      });
      app_Violationsmessage_list.splice(10, 0, {
        key: '审查类三',
        question_desc: '以下行为可被认定为"未经用户同意收集使用个人信息"',
        out: true,
      });
      app_Violationsmessage_list.splice(20, 0, {
        key: '审查类四',
        question_desc: '以下行为可被认定为"违反必要原则，收集与其提供的服务无关的个人信息"',
        out: true,
      });
      app_Violationsmessage_list.splice(27, 0, {
        key: '审查类五',
        question_desc: '以下行为可被认定为"未经同意向他人提供个人信息"',
        out: true,
      });
      app_Violationsmessage_list.splice(31, 0, {
        key: '审查类六',
        question_desc: '以下行为可被认定为"未按法律规定提供删除或更正个人信息功能”或“未公布投诉、举报方式等信息"',
        out: true,
      });
      data.data.app_Violationsmessage_list = app_Violationsmessage_list;
      dispatch(CheckedDataAction(data.data));
      return data.data;
    }
  } catch (error) {
    if (error.response) {
      let msg = get(error.response, 'data.msg', '应用数据请求出错');
      confirm({
        title: msg,
        // content: 'Some descriptions',
        onOk() {
          if (flag) {
            window.location.href = '/admin/privacy';
          } else {
            window.location.href = '';
          }
        },
        onCancel() {
          if (flag) {
            window.location.href = '/admin/privacy';
          } else {
            window.location.href = '';
          }
        },
      });
      // message.error(msg);
    } else {
      message.error('应用数据请求出错!');
    }
  }
};

// 审查操作
const CheckedAction = (obj) => async (dispatch) => {
  try {
    const { data } = await axios.post('/appprivacy/privacy/application/option/update', obj);
    let code = get(data, 'code', '');
    if (code === 1) {
      // console.log("获取审查数据",data);
      // dispatch(CheckedDataAction(data.data));
      // if(obj.remark){
      //   message.success('添加备注成功')
      // }
      if (obj.option) {
        message.success('操作成功');
      }
    }
  } catch (error) {
    if (error.response) {
      let msg = get(error.response, 'data.msg', '数据获取失败');
      message.error(msg);
    } else {
      message.error('数据获取失败!');
    }
  }
};
// 检测apk数据
const detectionApk = (id) => async (dispatch) => {
  try {
    const { data } = await axios.post('/appprivacy/privacy/application/task/create', { app_id: id });
    let code = get(data, 'code', '');
    if (code === 1) {
      console.log('检测apk数据', data);
      dispatch(detectionapkAction(data.task_id));
    }
  } catch (error) {
    if (error.response) {
      let msg = get(error.response, 'data.msg', '数据获取失败');
      message.error(msg);
    } else {
      message.error('数据获取失败!');
    }
  }
};
export default {
  getTableList,
  delcompany,
  updatacompany,
  upload,
  CheckedData,
  CheckedAction,
  detectionApk,
  uploadProgress,
};
