/* eslint-disable */
import axios from '@/utils/request';
import { message } from 'antd';
import * as constants from './constants.js';
const api = {
  sendEmail: '/saas-auth/open/sendActiveUrl',
};
export function sendEmail(userEmailObj) {
  return (dispatch) => {
    dispatch({ type: constants.ACTIVE_SEND_EMAIL });
    //放入domain
    userEmailObj.domain = window.location.host;
    axios
      .post(api.sendEmail, {
        email: userEmailObj.email,
        checkCode: userEmailObj.checkcode,
        domain: userEmailObj.domain,
      })
      .then((res) => {
        if (res.data.code == 1) {
          message.success('已向 ' + userEmailObj.email + ' 发送验证邮件，请注意查收');
          dispatch({ type: constants.ACTIVE_SEND_SUCCESS });
        } else {
          dispatch({ type: constants.ACTIVE_SEND_ERR, msg: res.data.msg });
          dispatch({ type: constants.ACTIVE_CHANGE_CODE_IMG });
        }
      })
      .catch((err) => {
        //console.log(err);
      });
  };
}
