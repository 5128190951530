/* eslint-disable */
import * as actionType from './actionType.js';

const defaultState = {
  kiwi_saas_version: '',
  unReaderNum: ''
}

export default function (state = defaultState, action) {
  switch (action.type) {
    case actionType.VERSION_CHANGE:
      return {
        ...state,
        kiwi_saas_version: action.data.value,
      };
    case actionType.UNREADER_NUM_ONMESSAGE:
      return {
        ...state,
        unReaderNum: action.value,
      };
    default:
      return state;
  }
}
