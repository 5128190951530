/* eslint-disable */
import { GETSAFEIDEALIST, GETSINGLEIDEA } from './actionType';
const getSafeIdeaListAction = (data) => ({
  type: GETSAFEIDEALIST,
  data,
});
const getSingleIdeaAction = (data) => ({
  type: GETSINGLEIDEA,
  data,
});
export { getSafeIdeaListAction, getSingleIdeaAction };
