/* eslint-disable */
import * as actionType from './actionType.js';
const defaultState = {
  isOpen: false, //是否打开添加Model
  tableData: [],
  current: 1, //当前table页数
  tableCount: 0, //表格数据总数
  options: [], //下拉框数据
  loading: true, //table是否加载
  roles: [], //角色信息存储
  selectSearchArr: [], //下拉框数据
}; //默认数据

export default (state = defaultState, action) => {
  //就是一个方法函数
  switch (action.type) {
    case actionType.CANCLE_MODEL:
      return { ...state, isOpen: false };
    case actionType.OPEN_MODEL:
      return { ...state, isOpen: true };
    case actionType.CHANGE_CODE:
      return { ...state, current: action.data };
    case actionType.CHANGE_SELECT_DATA:
      return { ...state, options: action.data };
    case actionType.CHANGE_TABLE_DATA:
      return { ...state, tableData: action.data, loading: false };
    case actionType.CHANGE_TABLE_LOAD:
      return { ...state, loading: action.data };
    case actionType.CHANGE_TABLE_COUNT:
      return { ...state, tableCount: action.data };
    case actionType.GET_OPTION_DATA:
      return { ...state, roles: action.data };
    case actionType.GET_SELECT_DATA:
      return { ...state, selectSearchArr: action.data };
    default:
      return { ...state };
  }
};
