/* eslint-disable */
import * as actionType from './actionType.js';
var init = {
  dangerHtml: '',
  current: -10,
  Total: [],
  res_data: {},
  uploading: false,
  progress: 0,
  fileInfo: {},
  appList: [],
  showListLoading: false,
  isShowMd5Progress: false, //是否显示md5加密进度
  md5Progress: '', //当前md5加密进度

  // 上传组件初始化状态,0初始化loading，1：允许使用，-1，禁止使用
  uploadState: 0,
  deteRight: {},
};

export default function detectionApk(state = init, action) {
  switch (action.type) {
    case actionType.FILEMD5_PROGRESS:
      return { ...state, isShowMd5Progress: action.isShow, md5Progress: action.progress };
    case actionType.GETAPP_DETE:
      return { ...state, Total: action.total_data, current: action.current };
    case actionType.APPINFO_DETE:
      return {
        ...state,
        res_data: action.res_data,
      };

    case actionType.UPLOAD_DETESTART:
      return { ...state, uploading: true };

    case actionType.UPLOAD_DETEIN:
      return { ...state, uploading: true, progress: action.progress, fileInfo: action.fileInfo };

    case actionType.UPLOAD_DETEEND:
      return { ...state, uploading: false, progress: 0, fileInfo: {} };

    case actionType.UPLOAD_DETESUCC:
      return { ...state, upload: true };

    case actionType.UPLOAD_DETEERR:
      return { ...state, upload: false, current: 1 };

    case actionType.GET_APP_LIST:
      return { ...state, appList: action.appList };

    case actionType.DETE_LOADING:
      return { ...state, showListLoading: true };

    case actionType.DETE_LOADEND:
      return { ...state, showListLoading: false };

    case actionType.DETE_UPLOAD_STATE:
      return { ...state, uploadState: action.payload, deteRight: action.right };

    default:
      return state;
  }
}
