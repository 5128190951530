/* eslint-disable */
import { GETTABLELIST, DEL, UPDATA, GETSYSTEMANAGMENT } from './actionType';

const defaultState = {
  TableListData: [],
  singleCompanyData: [],
  Tablecount: '',
  systemData: [],
};

function BusinessManagement(state = defaultState, action) {
  switch (action.type) {
    case GETTABLELIST: {
      return { ...state, TableListData: action.data.all, Tablecount: action.data.count };
    }
    case DEL: {
      return { ...state };
    }
    case UPDATA: {
      return { ...state, singleCompanyData: action.data };
    }
    case GETSYSTEMANAGMENT: {
      return { ...state, systemData: action.data.data, count: action.data.count };
    }
    default:
      return state;
  }
}
export default BusinessManagement;
