/* eslint-disable */
import {
  GETGROUPLIST,
  GETSINGlEDATA,
  GETSINGLEENCRYPTDATA,
  GETTHREATSCENARIOS,
  GETINSERTSTATE,
  STARTINSERT,
} from './actionType';
const getGroupListAction = (data) => ({
  type: GETGROUPLIST,
  data,
});
const getSingleDataAction = (singleData) => ({
  type: GETSINGlEDATA,
  singleData,
});
const getEncryptSingleDataAction = (data) => ({
  type: GETSINGLEENCRYPTDATA,
  data,
});
const getThreatScenariosAction = (data) => ({
  type: GETTHREATSCENARIOS,
  data,
});
const getInsertStateAction = (data) => ({
  type: GETINSERTSTATE,
  data,
});

export {
  getGroupListAction,
  getSingleDataAction,
  getEncryptSingleDataAction,
  getThreatScenariosAction,
  getInsertStateAction,
};
