/* eslint-disable */
import React from 'react';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { rainbow } from 'react-syntax-highlighter/dist/styles/hljs';

const PocHtmlCode = (props) => {

  let code = [];
  if (props.poc.indexOf('\n') !== -1) {
    code = props.poc.split('\n');
  } else {
    code = [props.poc]
  }

  return (
    <div className="poc-html-code">
      {/*<pre>*/}
      {/*  <code>*/}
      {/*    { props.poc }*/}
      {/*  </code>*/}
      {/*</pre>*/}
      {/*{*/}
      {/*  code.length > 1 ? (*/}
      {/*    code.map((item, index) => {*/}
      {/*      return <p key={index}>{ item }</p>*/}
      {/*    })*/}
      {/*  ) : (*/}
      {/*    <pre>*/}
      {/*      { code }*/}
      {/*    </pre>*/}
      {/*  )*/}
      {/*}*/}
      <SyntaxHighlighter language="python" style={rainbow}>{ props.poc }</SyntaxHighlighter>
    </div>
  )
}

export default PocHtmlCode;
