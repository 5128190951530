/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { operation } from './store';
import axios from '@/utils/request';
import { message, Spin } from 'antd';
import Layout from './layout';
import { MenuContext } from '@/modules/context';
import { getKeyFormArray } from '@/utils/utils'

const Home = (props) => {
  const [menu, setMenu] = useState(null);
  const [menuList, setMenuList] = useState(null);

  const getMenu = async () => {
    try {
      const { data } = await axios.post('/saas-auth/web/multipleMenu', {});
      if (data.code === 1) {
        let noSubMenus =[...data.data?.noSubMenus];
        let menus = [...data.data?.menus];

        // 针对消息管理的菜单设置
        // const subMenuItem = { key: "message_manage", title: "消息管理", subMenu: null };
        // const cIndex = getKeyFormArray(menus, '客户管理');
        // if (cIndex === -1) {
        //   const menuItem = {
        //     icon: "menu-icon-user",
        //     key: "客户管理",
        //     subMenu: [{ ...subMenuItem }],
        //     title: "客户管理",
        //   }
        //   menus = [...menus, menuItem];
        //   noSubMenus = [...noSubMenus, subMenuItem]
        // } else {
        //   const cIndexSubmenu = getKeyFormArray(menus[cIndex]?.subMenu, 'message_manage');
        //   if (cIndexSubmenu === -1) {
        //     menus[cIndex].subMenu = [...menus[cIndex]?.subMenu, subMenuItem]
        //     noSubMenus = [...noSubMenus, subMenuItem];
        //   }
        // }
        
        window.noSubMenus = Object.freeze(noSubMenus);
        setMenu(menus);
        setMenuList(noSubMenus);
      } else {
        message.error(data.message);
      }
    } catch (error) {
      console.error(error);
      props.history.push('/login');
    }
  };
  useEffect(() => {
    getMenu();
  }, []);
  if (!menu) {
    return <Spin spinning />;
  }
  return (
    <MenuContext.Provider value={menu}>
      <Layout {...props} menu={menu} menuList={menuList} />
    </MenuContext.Provider>
  );
};

const mapDispatchToProps = {
  setVersion: operation.setVersion,
  setUnReaderNum: operation.setUnReaderNum,
}

// export default Home
export default connect((state) => {
  return {
    layoutInfo: state.layoutInfo,
  };
}, mapDispatchToProps)(Home);
