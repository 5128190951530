/* eslint-disable */
const api = {
  getApkInfo: '/appguard/encrypt/application/android/info', // 获取apk的详细信息
  startEncrypt: '/appguard/encrypt/android/task/create', // 开始加固
  getTableList: '/appguard/encrypt/application/android/list', // 获取table列表数据
  uoload: '/appguard/encrypt/application/android/upload', // 上传包的接口
  updateRemark: '/appguard/encrypt/application/android/updateRemark', // 修改渠道的接口
  getEncryptState: '/appguard/encrypt/android/task/state', // 获取加固的实时状态
  getEncryptStateSchema: '/appguard/encrypt/android/task/multichannel', // 获取加固的实时状态多渠道
  downloadEncryptPackage: '/appguard/encrypt/android/download/package', // 下载加固包
  cancelEncrypt: '/appguard/encrypt/android/task/cancel', // 取消加固
  getEncryprIdea: '/appguard/encrypt/policy/list', // 获取加固策略数组
  getThreatScenarios: '/appguard/encrypt/policy/findOne', // 得到当前加固策略的威胁场景
  getVersionList: '/appguard/encrypt/toolVersion/list', // 获取加固版本list
  getauthority: '/saas-order/order/permission/detail', // 获取加固权限
  signFileList: '/appguard/encrypt/sign/file/list', // 获取签名文件总数
  schemaChannelList: '/appguard/encrypt/multichannel/schema', // 根据packagename 获取多渠道选择
  getAndroidPermissionKey: '/saas-auth/permission/hasGroupKeyPermit', // 获取用户是否签名和多渠道加固的权限
  restartEncryptChannelsTask: '/appguard/encrypt/android/task/multichannel/redo', // 多渠道单个任务重启加固
};
export default api;
