/* eslint-disable */
import * as actionType from './actionType.js';
const defaultState = {
  tableData: [],
  pageCode: 1,
  sdk_manufacturer: '',
  sdk_name: '',
  sdk_package_name: '',
  editVisible: false,
  tableLoading: true,
  total: 0,
}; //默认数据

export default (state = defaultState, action) => {
  //就是一个方法函数
  switch (action.type) {
    case actionType.CHANGE_TABLE_DATA:
      return {
        ...state,
        tableData: action.data,
        pageCode: action.pageCode,
        sdk_manufacturer: action.sdk_manufacturer,
        sdk_name: action.sdk_name,
        sdk_package_name: action.sdk_package_name,
        total: action.total,
        tableLoading: false,
      };
    case actionType.EDIT_VISIBLE:
      return { ...state, editVisible: action.is };
    default:
      return { ...state };
  }
};
