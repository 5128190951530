/* eslint-disable */
import React from 'react';
import { connect } from 'react-redux';

class MyIcon extends React.Component {
  render() {
    return (
      <span className="login-icon">
        <img src={this.props.src} />
      </span>
    );
  }
}

export default MyIcon;
