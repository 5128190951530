/* eslint-disable */
/*
 * @Author: kiliaosi
 * @Date: 2020-06-15 14:47:56
 * @LastEditors: kiliaosi
 * @LastEditTime: 2020-06-15 15:13:30
 * @Description:
 */
import React from 'react';

class Verify extends React.Component {
  state = {
    ms: 4,
  };
  componentDidMount() {}
  render() {
    setTimeout(() => {
      var ms = this.state.ms - 1;
      if (ms < 0) {
        this.props.history.push('/login');
        return;
      } else {
        this.setState({
          ms: ms,
        });
      }
    }, 1000);
    return (
      <div className="activ-back" style={{ textAlign: 'center' }}>
        <div className="activ">
          {' '}
          账号激活成功，请直接<a href="/login">登录</a>
          <span className="redirect">
            {' '}
            <i>{this.state.ms}</i>秒后自动跳转登录页
          </span>
        </div>
      </div>
    );
  }
}
export default Verify;
