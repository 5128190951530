/* eslint-disable */
import React, { useState } from 'react';
import { Form, Input, Button, Row, Col, message } from 'antd';
import VerifyCode from '@/common/verifyCode';
import { activeEmail } from '../../request/request';
import './activeEmail.less';

const FormItem = Form.Item;
const ActiveEmail = (props) => {
  const [currentTime, setcurrentTime] = useState('');
  const handleSubmit = (e) => {
    e.preventDefault();
    props.form.validateFields(async (err, values) => {
      if (!err) {
        values.domain = window.location.host;
        const data = await activeEmail(values);
        if (data) {
          message.success('已向 ' + values.email + ' 发送验证邮件，请注意查收');
        }
      }
    });
  };
  // 获取当前时间戳 获取不同的图片
  const changeCodeTime = () => {
    setcurrentTime(Date.parse(new Date()));
  };
  let regEmail = sessionStorage.getItem('activeEmail');
  const { getFieldDecorator } = props.form;
  let { loginTitle } = window.webConfig;
  return (
    <div className="activeEmail-panel-box">
      <div className="common-panel-content">
        <span className="panel-title">{loginTitle}</span>
        <div className="newsendEmail">
          <div className="title">发送邮箱验证链接</div>
          <Form onSubmit={handleSubmit}>
            <FormItem>
              {getFieldDecorator('email', {
                initialValue: regEmail,
                rules: [
                  { required: true, message: '邮箱不能为空' },
                  { min: 6, message: '请输入邮箱' },
                  {
                    pattern: /^[a-z0-9]+([._\\-]*[a-z0-9])*@([a-z0-9]+[-a-z0-9]*[a-z0-9]+.){1,63}[a-z0-9]+$/,
                    message: '邮箱格式错误',
                  },
                ],
              })(<Input className="username-info" placeholder="邮箱账号" />)}
            </FormItem>

            {/* 按钮界面 */}
            <FormItem className="err-err-panel">
              <Row>
                <Col span={14}>
                  <input type="password" style={{ display: 'none' }} />
                  {getFieldDecorator('checkCode', {
                    rules: [
                      { required: true, message: '验证码不能为空' },
                      { min: 4, message: '验证码不能少于四位' },
                    ],
                  })(<Input type="text" autoComplete="false" placeholder="验证码" />)}
                </Col>
                <Col span={10}>
                  <div className="checkCode">
                    {/* <> */}
                    <VerifyCode onClick={changeCodeTime} timestap={currentTime} />
                  </div>
                </Col>
              </Row>
            </FormItem>

            <Button type="primary" htmlType="submit" className="login-form-button">
              提交
            </Button>
            <a
              className="login-form-button-go"
              onClick={() => {
                props.history.push('/login');
              }}
            >
              返回
            </a>
          </Form>
        </div>
      </div>
    </div>
  );
};
const ActiveEmails = Form.create()(ActiveEmail);
export default ActiveEmails;
