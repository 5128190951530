/* eslint-disable */
/*
 * @Author: kiliaosi
 * @Date: 2020-02-29 18:34:30
 * @LastEditors: kiliaosi
 * @LastEditTime: 2020-03-26 14:41:11
 * @Description:
 */
import actionType from './actionType';

function iosDetection(state = {}, action) {
  switch (action.type) {
    case actionType.againDetection: {
      return { ...state };
    }
    case actionType.loadAllRecord: {
      return { ...state, record: action.payload };
    }
    case actionType.loadDetectionLength: {
      return { ...state, totalLength: action.payload };
    }
    case actionType.changeState: {
      return { ...state, currentState: action.payload };
    }
    case actionType.progress: {
      return { ...state, progress: action.payload, fileInfo: action.fileInfo };
    }
    case actionType.uploading: {
      return { ...state, uploading: action.payload };
    }
    case actionType.loadReport: {
      return { ...state, report: action.payload };
    }
    case actionType.detectionState: {
      return { ...state, detectionState: action.payload };
    }
    default: {
      return state;
    }
  }
}

export default iosDetection;
