/* eslint-disable */
/*
 * @Author: rookie.CYJ
 * @Date: 2020-11-10 09:30:03
 * @Last Modified by: rookie.CYJ
 * @Last Modified time: 2021-04-29 13:47:28
 */
import api from './api';
import axios from '@/utils/request';
import { message } from 'antd';
import { changeTableData, editVisible } from './action';
import { get } from 'lodash';

// 获取表格数据
const getTableDataFun = async (dispatch, pageCode = 1, sdk_manufacturer = '', sdk_name = '', sdk_package_name = '') => {
  let obj = { page: pageCode };
  if (sdk_manufacturer.length) {
    obj.sdk_manufacturer = sdk_manufacturer;
  }
  if (sdk_name.length) {
    obj.sdk_name = sdk_name;
  }
  if (sdk_package_name.length) {
    obj.sdk_package_name = sdk_package_name;
  }
  try {
    const dataAxios = await axios.get(api.getTableData, {
      params: obj,
    });

    if (dataAxios) {
      const { data } = dataAxios;
      const code = get(data, 'code', 0);
      if (code === 1) {
        const { data: dataSource, total_num: total } = data;
        console.log(dataSource, total);
        dispatch(changeTableData(dataSource, pageCode, sdk_manufacturer, sdk_name, sdk_package_name, total));
      }
    }
  } catch (error) {
    const { message: msg = '数据获取失败' } = error;
    message.error(msg);
  }
};
// 获取数据
const getTableData = (pageCode = 1, sdk_manufacturer = '', sdk_name = '', sdk_package_name = '') => async (
  dispatch,
) => {
  //筛选参数:
  // 1.pageCode页数
  // 2.SDK厂商筛选
  // 3.SDK名称筛选
  // 4.SDK包名筛选
  getTableDataFun(dispatch, pageCode, sdk_manufacturer, sdk_name, sdk_package_name);
};
//删除表格数据
const delTableData = (id, pageCode, sdk_manufacturer, sdk_name, sdk_package_name) => async (dispatch) => {
  // console.log(arr)
  let obj = {};
  obj.id = id;
  console.log(obj);
  try {
    const { data } = await axios({
      url: api.delTableData,
      method: 'delete',
      data: obj,
    });
    console.log(data);
    message.success(data.msg);
    getTableDataFun(dispatch, pageCode, sdk_manufacturer, sdk_name, sdk_package_name);
  } catch (error) {
    const { message: msg = '删除数据失败' } = error;
    message.error(msg);
  }
};
//修改表格数据
const editTableData = (value, pageCode, sdk_manufacturer, sdk_name, sdk_package_name) => async (dispatch) => {
  console.log(value, pageCode, sdk_manufacturer, sdk_name, sdk_package_name);
  try {
    const { data } = await axios({
      url: api.editTableData,
      method: 'put',
      data: value,
    });
    console.log(data);
    message.success(data.msg);
    dispatch(editVisible(false));
    getTableDataFun(dispatch, pageCode, sdk_manufacturer, sdk_name, sdk_package_name);
  } catch (error) {
    const { message: msg = '修改数据失败' } = error;
    message.error(msg);
  }
};
//添加表格数据
const addTableData = (value) => async (dispatch) => {
  console.log(value);
  try {
    const { data } = await axios({
      url: api.addTableData,
      method: 'post',
      data: value,
    });
    console.log(data);
    message.success(data.msg);
    getTableDataFun(dispatch);
    return { result: true };
  } catch (error) {
    const { message: msg = '修改数据失败' } = error;
    message.error(msg);
  }
};

const setEditVisible = (is) => async (dispatch) => {
  //控制是否打开编辑Model
  dispatch(editVisible(is));
};

export default {
  getTableData,
  delTableData,
  editTableData,
  addTableData,
  setEditVisible,
};
