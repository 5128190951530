/* eslint-disable */
export const REG_LOADING = 'REG_LOADING'; //注册loading
export const REG_LOADINGEND = 'REG_LOADINGEND'; //注册loading结束

//手机
export const REG_CODE_LOADING = 'REG_CODE_LOADING'; //获取短信验证码loading
export const REG_CODE_LOADEND = 'REG_CODE_LOADEND'; //获取短信验证码loading结束
export const REG_CODE_MIN_LOADING = 'REG_CODE_MIN_LOADING'; //验证码发送间隔loading
export const REG_CODE_MIN_LOADEND = 'REG_CODE_MIN_LOADEND'; //验证码发送间隔loading结束

//邮箱
export const REG_EMAIL_LOADING = 'REG_EMAIL_LOADING';
export const REG_EMAIL_LOADEND = 'REG_EMAIL_LOADEND';
export const SHOW_ACTIVE_BTN = 'SHOW_ACTIVE_BTN';

export const REG_ERR = 'REG_ERR'; //注册失败
export const REG_SUCCESS = 'REG_SUCCESS'; //注册成功

export const REG_SHOW_CODE = 'REG_SHOW_CODE'; //显示验证码

// 更新验证码
export const REG_UPDATE_CODE = 'REG_UPDATE_CODE';
