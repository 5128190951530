/* eslint-disable */
import axios from '@/utils/request';
import { message } from 'antd';
import * as actionType from './constants.js';
const api = {
  sendPhone: '/saas-auth/open/activePhone',
  getCode: '/saas-auth/open/sendActivePhoneVerifyCode',
};
export function checkResetPhone(userPhoneObj) {
  console.log(userPhoneObj);
  return (dispatch) => {
    dispatch({ type: actionType.ACTIVE_SEND_PHONE });
    axios
      .post(api.sendPhone, { phone: userPhoneObj.phone, code: userPhoneObj.code })
      .then((res) => {
        if (res.data.code == 1) {
          dispatch({ type: actionType.ACTIVE_SEND_SUCCESS });
          message.success('手机号验证成功，即将跳转到登录页面');
          setTimeout(() => {
            window.location.href = res.data.data.loginUrl;
          }, 1000);
        } else {
          dispatch({ type: actionType.ACTIVE_SEND_ERR, msg: res.data.msg });
        }
      })
      .catch((err) => {
        //console.log(err);
      });
  };
}

//短信验证码
export function sendPhoneCode(obj) {
  console.log(obj);
  return (dispatch) => {
    dispatch({ type: actionType.SEND_PHONE_CODE });
    dispatch({ type: actionType.SEND_PHONE_MSG, loadMsg: '发送中' });
    axios
      .post(api.getCode, { phone: obj.phone })
      .then((res) => {
        if (res.data.code == 1) {
          message.success('验证码发送成功,请注意查收');
          let times = 60;
          let timer = setInterval(() => {
            times--;
            if (times <= 0) {
              dispatch({
                type: actionType.SEND_PHONE_MSG,
                loadMsg: '点击获取验证码',
              });
              dispatch({ type: actionType.SEND_PHONE_CODE_END });
              clearInterval(timer);
              return;
            }
            dispatch({
              type: actionType.SEND_PHONE_MSG,
              loadMsg: '已发送(' + times + ')',
            });
          }, 1000);
        } else {
          let messages = res.data.msg.replace(/<[^>]*>|<\/[^>]*>/gm, '');
          message.error(messages);
          dispatch({
            type: actionType.SEND_PHONE_MSG,
            loadMsg: '点击获取验证码',
          });
          dispatch({ type: actionType.SEND_PHONE_CODE_END });
        }
      })
      .catch((err) => {
        dispatch({
          type: actionType.SEND_PHONE_MSG,
          loadMsg: '点击获取验证码',
        });
        dispatch({ type: actionType.SEND_PHONE_CODE_END });
      });
  };
}
