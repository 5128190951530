/* eslint-disable */
import { UPLOAD, SHOWUPLOADBAR, GETTABLELIST, DEL, UPDATA, CHECKEDDATA, DETECTIONAPK } from './actionType';

const gettablelistAction = (data) => ({
  type: GETTABLELIST,
  data,
});
const delAction = (data) => ({
  type: DEL,
  data,
});
const updataction = (data) => ({
  type: UPDATA,
  data,
});
// 上传进度
const uploadAction = (progress) => ({
  type: UPLOAD,
  progress,
});
// 进度条显示
const ShowUploadBarAction = (showBar) => ({
  type: SHOWUPLOADBAR,
  showBar,
});
// 审查数据
const CheckedDataAction = (data) => ({
  type: CHECKEDDATA,
  data,
});
// 审查数据
const detectionapkAction = (data) => ({
  type: DETECTIONAPK,
  data,
});

export {
  gettablelistAction,
  delAction,
  updataction,
  uploadAction,
  ShowUploadBarAction,
  CheckedDataAction,
  detectionapkAction,
};
