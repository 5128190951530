/* eslint-disable */
import axios from '@/utils/request';
import { message } from 'antd';
import { get } from 'lodash';
import { gettablelistAction, delAction, updataction, getSystemAction } from './action';

// 获取TABLE 数据
const getTableList = (obj) => async (dispatch) => {
  try {
    const dataAxios = await axios.get('/appprivacy/privacy/company/list', { params: obj });
    if (dataAxios) {
      const { data } = dataAxios;
      let code = get(data, 'code', '');
      if (code === 1) {
        console.log('获取TABLE', data);
        dispatch(gettablelistAction(data.data));
      }
    }
  } catch (error) {
    if (error.response) {
      let msg = get(error.response, 'data.msg', '数据获取失败');
      message.error(msg);
    } else {
      message.error('数据获取失败!');
    }
  }
};
// 删除
const delcompany = (obj) => async (dispatch) => {
  try {
    const { data } = await axios.post('/appprivacy/privacy/company/delete', obj);
    let code = get(data, 'code', '');
    if (code === 1) {
      console.log('删除', data);
      dispatch(delAction(data));
      message.success('删除成功');
    }
  } catch (error) {
    if (error.response) {
      let msg = get(error.response, 'data.msg', '数据获取失败');
      message.error(msg);
    } else {
      message.error('数据获取失败!');
    }
  }
};
// 修改
const updatacompany = (obj) => async (dispatch) => {
  try {
    const { data } = await axios.post('/appprivacy/privacy/company/update', obj);
    let code = get(data, 'code', '');
    if (code === 1) {
      // console.log("修改",data);
      message.success('修改成功');
      dispatch(updataction(data));
    }
  } catch (error) {
    if (error.response) {
      let msg = get(error.response, 'data.msg', '数据获取失败');
      message.error(msg);
    } else {
      message.error('数据获取失败!');
    }
  }
};
// 创建
const createcompany = (obj) => async (dispatch) => {
  try {
    const { data } = await axios.post('/appprivacy/privacy/company/create', obj);
    let code = get(data, 'code', '');
    if (code === 1) {
      console.log('创建', data);
      message.success('创建成功');
      dispatch(updataction(data));
    }
  } catch (error) {
    if (error.response) {
      let msg = get(error.response, 'data.msg', '数据获取失败');
      message.error(msg);
    } else {
      message.error('数据获取失败!');
    }
  }
};
// 获取组织设备
const getSystem = (page = 1) => async (dispatch) => {
  try {
    const { data } = await axios.get(`/appprivacy/privacy/device/list?page=${page}`);
    let code = get(data, 'code', '');
    if (code === 1) {
      console.log('获取组织设备的data', data.data);
      dispatch(getSystemAction(data));
      return data.data;
    }
  } catch (error) {
    if (error.response) {
      let msg = get(error.response, 'data.msg', '数据获取失败');
      message.error(msg);
    } else {
      message.error('数据获取失败!');
    }
  }
};
export default {
  getTableList,
  delcompany,
  updatacompany,
  createcompany,
  getSystem,
};
