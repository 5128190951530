/* eslint-disable */
/* eslint-disable array-callback-return */
import React, { lazy, Suspense } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { Spin } from 'antd';
import component from '@/config/component';
import NotFound from './notFound.js';
const suspenseStype = { margin: '0 auto', display: 'block' };

class CoreRouter extends React.Component {
  render() {
    const { title } = window.webConfig;
    document.title = title ? title : '几维安全';
    let firstMenuList = '';
    let routeList;
    try {
      let menuList = this.props?.menuList ?? [];
      routeList = component.map((item, index) => {
        if (item.key === menuList[0]?.key) {
          firstMenuList = item.loc;
        }
        let condition = menuList.some((item2) => {
          return item2.key.includes(item.key) || (Boolean(item.authKey) && item2.key.includes(item.authKey));
        });
        const Asynccomponent = lazy(item.component);
        return (
          <Route
            key={index}
            path={`${item.loc}`}
            render={(props) => {
              if (condition) {
                return <Asynccomponent {...props} />;
              }
              return <NotFound {...props} />;
            }}
          />
        );
      });
    } catch (error) {
      console.error(error);
    }
    return (
      <Suspense fallback={<Spin spinning style={suspenseStype} />}>
        <Switch>
          {routeList}
          <Redirect exact from="/" to={firstMenuList} />
          <Route component={NotFound} key="notfonud" />
        </Switch>
      </Suspense>
    );
  }
}
export default CoreRouter;
