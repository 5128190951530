/* eslint-disable */
import Axios from 'axios';
import { message, Modal } from 'antd';
import host from '../config/host';
import { get } from 'lodash';
import cancelRequestArr from './cancelRequestArr';
let pending = []; // 声明一个数组用于存储每个ajax请求的取消函数和ajax标识
let CancelToken = Axios.CancelToken;
// 取消请求 方法
let cancelPending = (config) => {
  pending.forEach((item, index) => {
    let flag = false;
    // cancelRequestArr包含的请求，重复请求 让他取消请求
    if (cancelRequestArr.includes(item.UrlPath)) {
      flag = true;
    }
    if (config) {
      if (item.UrlPath === config.url && flag) {
        item.Cancel(); // 取消请求
        pending.splice(index, 1); // 移除当前请求记录
      }
    }
  });
};

// 环境标识
let env = 'dev';
if (process.env.REACT_APP_ENV) {
  env = process.env.REACT_APP_ENV;
}
// 引入外部的配置文件 根据环境，请求名字不同，配置不同的baseurl
host
  .map((item) => {
    return { name: item.name, origin: item[env] };
  })
  .forEach((item) => {
    Axios.defaults[item.name] = item.origin;
  });

// Axios.defaults.withCredentials = true; // 默认为false  表示跨域请求时是否需要使用凭证

const axios = Axios.create({});
// 请求拦截器
axios.interceptors.request.use(
  (request) => {
    try {
      let isCloud = true;
      host.some((item) => {
        if (request.url.startsWith(item.prefix)) {
          isCloud = false;
          if (item.rewritePrefix) {
            request.url = request.url.substring(item.prefix.length);
          }
          let origin = item[env];
          request.baseURL = origin;
          Axios.defaults.baseURL = origin;
          return true;
        }
      });
      // console.log('请求拦截器里面的request', request);
      // fixme 特殊逻辑
      if (isCloud) {
        let origin = host[0][env] + '/cloud';
        request.baseURL = origin;
        Axios.defaults.baseURL = origin;
      }
      const token = localStorage.getItem('kiwi.saas.token');
      token && (request.headers.Authorization = `Bearer ${token}`);
      cancelPending(request);
      request.cancelToken = new CancelToken((res) => {
        pending.push({ UrlPath: request.url, Cancel: res });
      });
    } catch (err) {
      console.error('axios请求拦截出错');
      request.headers.Authorization = 'Bearer ';
    }
    if (process.env.REACT_APP_ENV === 'dev') {
      request.params = { ...request.params, dev: true };
    }
    return request;
  },
  (error) => Promise.reject(error),
);
// 响应拦截器
axios.interceptors.response.use(
  (response = {}) => {
    // console.log('响应拦截器的response', response);
    let code = get(response, 'data.code', '');
    let msg = get(response, 'data.message', '') || get(response, 'data.msg', '');
    // 代表request header中没有带token
    let pathname = window.location.pathname;
    // console.log('axios pathname', pathname);
    // 2020002 2020003 表示token过期，需要重新登录
    if (code === 2000101 || code === 2020002 || code === 2020003) {
      if (pathname === '/login') {
        return;
      }
      Modal.warning({
        title: '登录状态已过期，请重新登录',
        // content: '请重新登录',
        onOk() {
          localStorage.removeItem('kiwi.saas.token');
          window.location.pathname = '/login';
        },
      });
      return;
    }
    // code 不等于1 说明接口有问题 统一做报错处理
    // if (code !== 1) {
    //   message.error(msg);
    //   return response;
    // }
    if (code === 2020005 || code === 2020006 || code === 2020007 || code === 2020008) {
      if (pathname !== '/privacy/equipment/edit') {
        message.destroy();
        message.warning(msg);
        return;
      }
    }
    if (code === 101) {
      message.error(msg);
    }
    // 取消请求
    cancelPending(response.config);
    return response;
  },
  (error) => {
    const { response = {} } = error;
    console.error('我要的response', response, error);
    let url = get(response, 'config.url', '');
    if (response.status === 401 && !/login/g.test(window.location.href)) {
      if (url === '/saas-auth/user/getUserInfo' || url === '/saas-auth/user/getAccountInfo') {
        return;
      }
      Modal.warning({
        title: '身份校验已过期',
        content: '请重新登录',
        onOk() {
          localStorage.removeItem('kiwi.saas.token');
          window.location.href = '/login';
        },
      });
    }
    return Promise.reject(error);
  },
);

export default axios;
