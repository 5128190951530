/* eslint-disable */
/*
 * @Author: kiliaosi
 * @Date: 2020-02-29 17:25:08
 * @LastEditors: kiliaosi
 * @LastEditTime: 2020-03-26 14:39:00
 * @Description:
 */
const namespace = 'IOS_DETECTION';

const loadAllRecord = `${namespace}/LOAD_ALL_RECORD`;
const changeState = `${namespace}/CHANGE_STATE`;
//上传进度
const progress = `${namespace}/LOAD_PROGRESS`;
//启动上传
const uploading = `${namespace}/UPLOADING`;

//加载当前报告
const loadReport = `${namespace}/LOAD_REPORT`;

//加载当前报告状态
const detectionState = `${namespace}/LOAD_DETECTION`;

//加载所有检测任务个数
const loadDetectionLength = `${namespace}/LOAD_DETECTION_LENGTH`;

// 重新检测
const againDetection = `${namespace}/AGAINDETECTION`;

export default {
  loadAllRecord,
  changeState,
  progress,
  uploading,
  loadReport,
  detectionState,
  loadDetectionLength,
  againDetection,
};
