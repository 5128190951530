/* eslint-disable */
import React from 'react';
import axios from '@/utils/request';

class wechatLogin extends React.Component {
  componentDidMount() {
    var url = window.location.search; //获取url中"?"符后的字串
    var theRequest = new Object();
    if (url.indexOf('?') != -1) {
      var str = url.substr(1);
      var strs = str.split('&');
      for (var i = 0; i < strs.length; i++) {
        theRequest[strs[i].split('=')[0]] = unescape(strs[i].split('=')[1]);
      }
    }
    console.log('我是url中的code', theRequest.code);
    // todo
    axios.post(`/saas-auth/open/wechat/login`, { code: theRequest.code, state: theRequest.state }).then((res) => {
      localStorage.setItem('kiwi.saas.token', res.data.data.token);
      if (res.data.code == 1) {
        this.props.history.push('/encrypt/android');
        window.location.reload();
      }
    });
  }
  render() {
    return (
      <div
        style={{
          width: '100%',
          margin: '0px auto',
          color: 'white',
          fontSize: '14px',
          position: 'relative',
          top: '50%',
          left: '40%',
        }}
      >
        微信正在登陆中，请稍等！
      </div>
    );
  }
}

export default wechatLogin;
