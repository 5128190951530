/* eslint-disable */
// 取消重复请求的数组
const cancelRequestArr = [
  '/appguard/backend/environment/logs',
  '/appguard/backend/pirate/logs',
  '/appguard/backend/virtual/logs',
  '/appguard/backend/plugin/logs',
  '/appguard/backend/compete/logs',
  '/appprivacy2/privacy/detection/result/behavior/info',
  '/appguard/backend/Exception/search',
  '/privacy-new/privacy/detection/result/behavior/info',
  '/privacy-new/privacy/review/module/rule/detail',
];
export default cancelRequestArr;
