/* eslint-disable */
import React from 'react';
import UserPanel from '../common/component/panel.js';
import SendPhone from './component/send_phone.js';
import { connect } from 'react-redux';
import { checkResetPhone, sendPhoneCode } from './reducer/action.js';
class UserActive extends React.Component {
  render() {
    return (
      <UserPanel>
        <SendPhone {...this.props} />
      </UserPanel>
    );
  }
}
const UserActives = connect(
  (state) => {
    return state.SendPhoneActive;
  },
  { checkResetPhone, sendPhoneCode },
)(UserActive);
export default UserActives;
