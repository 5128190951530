/* eslint-disable */
import * as actionType from './actionType.js';
var init = {
  loading: false,
  islogin: false,
  showCode: false,
  imgCheckCode: new Date().getTime(),
};
export default function Login(state = init, action) {
  switch (action.type) {
    case actionType.LOGIN_SUCC:
      return {
        ...state,
        islogin: true,
        loginValue: action.value,
        errValue: '',
        showCode: false,
      };
    case actionType.LOGIN_ERR:
      return { ...state, loading: false, errValue: action.value };
    case actionType.LOADING:
      return { ...state, loading: true, showCode: false };
    case actionType.LOADEND:
      return { ...state, loading: false };
    case actionType.SHOWCODE:
      return { ...state, showCode: true };
    case actionType.LOGIN_CHECK_CODE:
      return { ...state, imgCheckCode: new Date().getTime() };
    default:
      return state;
  }
}
