/* eslint-disable */
/*
 * @Author: kiliaosi
 * @Date: 2020-06-15 14:47:56
 * @LastEditors: kiliaosi
 * @LastEditTime: 2020-06-15 15:16:46
 * @Description:
 */
import React from 'react';
import EmailFind from './component/email_find';
import FindEmailPwd from './component/phone_find';
import { Tabs, Icon } from 'antd';
import { connect } from 'react-redux';
import { sendEmailFind, sendPhoneCode, checkResetPhone, changeNewTimeDate } from './reducer/action';
import UserPanel3 from '../common/component/panel3';
import './style/find.less';

const TabPane = Tabs.TabPane;
class FindPwd extends React.Component {
  constructor(props) {
    super(props);
    this.ChangeLock = this.ChangeLock.bind(this);
    this.state = {
      value: 'phone',
    };
  }
  ChangeLock(e) {
    this.setState({
      value: e.target.value,
    });
  }
  render() {
    let webConfig = window.webConfig;
    const allowPhone = webConfig.allowLoginPhone !== undefined ? webConfig.allowLoginPhone : true;
    const allowEmail = webConfig.allowLoginEmail !== undefined ? webConfig.allowLoginEmail : true;
    return (
      <UserPanel3>
        <div className="title33">重置密码</div>
        <Tabs animated={false}>
          {allowPhone ? (
            <TabPane
              tab={
                <span className="min-dark">
                  <Icon type="mobile" />
                  手机找回
                </span>
              }
              key="1"
            >
              <FindEmailPwd
                sendPhoneCode={this.props.sendPhoneCode}
                sendPhoneCodeMsg={this.props.sendPhoneCodeMsg}
                sendPhoneLoading={this.props.sendPhoneLoading}
                phoneCodeIs={this.props.phoneCodeIs}
                checkResetPhone={this.props.checkResetPhone}
                changeUserOperation={this.props.changeUserOperation}
                history={this.props.history}
              />
            </TabPane>
          ) : null}
          {allowEmail ? (
            <TabPane
              tab={
                <span className="min-dark">
                  <Icon type="mail" />
                  邮箱找回
                </span>
              }
              key="2"
            >
              <EmailFind
                sendEmail={this.props.sendEmail}
                sendEmailState={this.props.sendEmailState}
                sendEmailFind={this.props.sendEmailFind}
                history={this.props.history}
                changeUserOperation={this.props.changeUserOperation}
                changeTimeDate={this.props.changeTimeDate}
                changeNewTimeDate={this.props.changeNewTimeDate}
              />
            </TabPane>
          ) : null}
        </Tabs>
      </UserPanel3>
    );
  }
}
const FindPwdIndex = connect(
  (state) => {
    return state.FindPwd;
  },
  { sendEmailFind, sendPhoneCode, checkResetPhone, changeNewTimeDate },
)(FindPwd);
export default FindPwdIndex;
