/* eslint-disable */
const namespace = 'AGENT'; //申明命名空间
const GETTABLEDATA = `${namespace}getTableData`; // 获取table数据
const DEL = `${namespace}del`; // 删除数据
const ADD = `${namespace}add`; // 添加代理商
const UPDATA = `${namespace}updata`; // 获取单个代理商详细信息
const REALUPDA = `${namespace}realupdata`; // 更新代理商信息
const FINDAGENTTWO = `${namespace}findAgentTwo`; // 查询代理商配置（二级配置）
const REALUPDATWO = `${namespace}realupdatatwo`; // 修改代理商配置（二级配置）
const FREEZE = `${namespace}freeze`; //冻结代理商
const UPDATAWORD = `${namespace}updataWord`; //上传word模板
const GET_AGENT_LIST = `${namespace}getAgentList`;
const GET_AGENT_NAME_LIST = `${namespace}getAgentNameList`;
const GET_ENTERPRISE_TYPE_LIST = `${namespace}getEnterpriseTypeList`;
const GET_PROJECT_MANAGER_LIST = `${namespace}getProjectManagerList`; // 获取项目经理
const GET_SALES_MANAGER_LIST = `${namespace}getSalesManagerList`; // 获取销售经理
export {
  GETTABLEDATA,
  DEL,
  ADD,
  UPDATA,
  REALUPDA,
  FINDAGENTTWO,
  REALUPDATWO,
  FREEZE,
  UPDATAWORD,
  GET_AGENT_LIST,
  GET_AGENT_NAME_LIST,
  GET_ENTERPRISE_TYPE_LIST,
  GET_PROJECT_MANAGER_LIST,
  GET_SALES_MANAGER_LIST,
};
