/* eslint-disable */
import axios from '@/utils/request';
import { getSafeIdeaListAction, getSingleIdeaAction } from './action';
import { message } from 'antd';
import { get } from 'lodash';

// 获取安全策略list数组
const getSafeIdeaList = () => async (dispatch) => {
  try {
    const { data } = await axios.get(`/appguard/backend/dynamic/policy/list`);
    let code = get(data, 'code', '');
    let datas = get(data, 'data', []);
    if (code === 1) {
      return datas;
    }
  } catch (error) {
    console.error(error);
  }
};
// 获取单个安全策略的策略
const getSingleIdea = (obj) => async (dispatch) => {
  try {
    const { data } = await axios.get(`/appguard/backend/dynamic/policy/search`, { params: obj });
    let code = get(data, 'code', '');
    data.data[0].initId = obj.policy_id;
    if (code === 1) {
      dispatch(getSingleIdeaAction(data.data));
    }
  } catch (error) {
    console.error(error);
  }
};
// 创建安全策略
const createSafeIdea = (obj) => async (dispatch) => {
  try {
    const { data } = await axios.post(`/appguard/backend/dynamic/policy/create`, obj);
    let id = get(data, 'data.policy_id', '');
    let code = get(data, 'code', '');
    let msg = get(data, 'msg', '');
    if (code === 1) {
      message.success('创建成功');
      return id;
    } else {
      message.warning(msg);
      return false;
    }
  } catch (error) {
    let msg = get(error, 'response.data.msg', '创建失败');
    message.error(msg);
    console.error(error);
  }
};
// 删除安全策略
const delSafeIdea = (obj) => async (dispatch) => {
  const { data } = await axios.post(`/appguard/backend/dynamic/policy/delete`, obj);
  if (data.code === 1) {
    message.success(data.msg);
  }
};
// 更改安全策略名称
const editSafeIdea = (obj) => async (dispatch) => {
  const { data } = await axios.post(`/appguard/backend/dynamic/policy/name/update`, obj);
  if (data.code === 1) {
    message.success(data.msg);
  }
};
// 文件完整性 校验规则
const fileCompleteRule = (obj) => async (dispatch) => {
  try {
    const { data } = await axios.post(`/appguard/backend/dynamic/policy/whole_file_chk/regulation/update`, obj);
    if (data.code === 1) {
      message.success(data.msg);
      return true;
    }
    return false;
  } catch (error) {
    const { response } = error;
    let msg = get(response, 'data.msg', '修改失败');
    message.error(msg);
    console.error(error);
    return false;
  }
};
// 修改 单个威胁场景的的异常处理方式
const exceptionHandlingMethod = (api, obj) => async (dispatch) => {
  try {
    const { data } = await axios.post(api, obj);
    let code = get(data, 'code', '');
    if (code === 1) {
      message.success('修改成功');
    }
  } catch (error) {
    const { response } = error;
    let msg = get(response, 'data.msg', '修改失败');
    message.error(msg);
    // console.error("异常处理方式错误", error);
  }
};
// 启用安全策略
const enableSafeIdea = (obj) => async (dispatch) => {
  try {
    const { data } = await axios.post(`/appguard/backend/project/dynamic/policy/update`, obj);
    if (data.code === 1) {
      message.success(data.msg);
    }
  } catch (error) {
    console.error(error);
  }
};
export default {
  getSafeIdeaList,
  getSingleIdea,
  createSafeIdea,
  delSafeIdea,
  fileCompleteRule,
  exceptionHandlingMethod,
  enableSafeIdea,
  editSafeIdea,
};
