/* eslint-disable */
/* eslint-disable no-param-reassign */
import React, { useState, useEffect, useRef } from 'react';
import { withRouter } from 'react-router-dom';
import { Form, Input, Row, Col, Button, message } from 'antd';
import { getFindPwdPhoneCode, findPwdByPhone } from '../../../request/request';
import md5 from 'md5';
import { LoginUserImg, LoginYanzmaImg, PwdIconImg } from '../../common/config_img';
import { passwordRegExp } from '../../common/config_regexp';
import VerifyCode from '@/common/verifyCode';

const FormItem = Form.Item;
const phoneReg = /^\d{11}$/;

const FindPhonePwd = (props) => {
  // 设置和清楚定时器
  const timer = useRef(null);
  const [currentTime, setcurrentTime] = useState('');
  const [phoneNumber, setPhoneNumber] = useState(''); // 用户输入的手机号
  const [codeMessage, setcodeMessage] = useState('获取短信验证码');
  const [checkCode, setCheckCode] = useState("");
  const [CodeTime, setCodeTime] = useState(60); // 创新获取验证码的时间
  useEffect(() => {
    clearInterval(timer.current);
    return () => clearInterval(timer.current);
  }, []);
  useEffect(() => {
    if (CodeTime > 0 && CodeTime < 60) {
      setcodeMessage(`${CodeTime} s后重新获取`);
    } else {
      clearInterval(timer.current);
      setCodeTime(60);
      setcodeMessage('获取短信验证码');
    }
    return () => {
      return () => clearInterval(timer.current);
    };
  }, [CodeTime]);
  // 获取验证码方法
  const getVerificationCode = async () => {
    if (!phoneReg.test(phoneNumber)) {
      message.error('请输入正确的手机号');
      return;
    }
    if (!checkCode) {
      message.error('请输入图形验证码!');
      return;
    } else if (checkCode.length < 4) {
      message.error('图形验证码不能少于四位!');
      return;
    }

    const data = await getFindPwdPhoneCode(phoneNumber, checkCode);
    console.log('是否发送成功', data);
    if (data) {
      timer.current = setInterval(() => setCodeTime((t) => --t), 1000);
    }
  };

  // 获取当前时间戳 获取不同的图片
  const changeCodeTime = () => {
    setcurrentTime(Date.parse(new Date()));
  };

  // 提交
  const handleSubmit = (e) => {
    e.preventDefault();
    props.form.validateFields(async (err, values) => {
      if (!err) {
        values.password = md5(values.password);
        const data = await findPwdByPhone(values);
        if (data) {
          message.success('密码修改成功,请重新登录');
          props.history.push('/login');
        }
      }
    });
  };
  const { getFieldDecorator } = props.form;
  return (
    <Form onSubmit={handleSubmit} className="find-password">
      <FormItem>
        {getFieldDecorator('phone', {
          rules: [
            {
              required: true,
              message: '手机号不能为空',
            },

            {
              pattern: phoneReg,
              message: '请输入正确的手机号',
            },
          ],
        })(
          <Input
            autoComplete="off"
            className="username-info"
            placeholder="请输入手机号"
            onChange={(e) => {
              setPhoneNumber(e.target.value);
            }}
            prefix={
              <span className="login-icon">
                <img src={LoginUserImg} />
              </span>
            }
          />,
        )}
      </FormItem>
      {/* 按钮界面 */}
      <FormItem className="err-err-panel">
        <Row>
          <Col span={14}>
            {getFieldDecorator('checkCode', {
              rules: [
                { required: true, message: '验证码不能为空' },
                { min: 4, message: '验证码不能少于四位' },
              ],
            })(
              <Input
                type="text"
                placeholder="请输入验证码"
                prefix={
                  <span className="login-icon">
                    <img src={LoginYanzmaImg} />
                  </span>
                }
                onChange={(e) => {
                  setCheckCode(e.target.value);
                }}
              />,
            )}
          </Col>
          <Col span={10}>
            <div className="checkCode">
              <VerifyCode onClick={changeCodeTime} timestap={currentTime} />
            </div>
          </Col>
        </Row>
      </FormItem>
      <FormItem className="err-err-panel">
        <Row>
          <Col span={14}>
            {getFieldDecorator('code', {
              rules: [
                { required: true, message: '验证码不能为空' },
                { min: 4, message: '验证码不能少于四位' },
              ],
            })(
              <Input
                autoComplete="off"
                type="text"
                name="checkCode"
                placeholder="请输入验证码"
                prefix={
                  <span className="login-icon">
                    <img src={LoginYanzmaImg} />
                  </span>
                }
              />,
            )}
          </Col>
          <Col span={10}>
            <div className="reg-duanxin3">
              <a onClick={getVerificationCode} className="button-color-color">
                {codeMessage}
              </a>
            </div>
          </Col>
        </Row>
      </FormItem>

      <FormItem>
        {getFieldDecorator('password', {
          rules: [
            { required: true, message: '密码不能为空' },
            { min: 6, message: '密码不能少于6位' },
            { pattern: passwordRegExp, message: '密码必须由字母和数字组成' },
          ],
        })(
          <Input.Password
            placeholder="请输入密码"
            prefix={
              <span className="login-icon">
                <img src={PwdIconImg} />
              </span>
            }
          />,
        )}
      </FormItem>
      {/* 提交和取消按钮 */}
      <FormItem>
        <Button type="primary" htmlType="submit" className="login-form-button antdButtonSelf">
          提交
        </Button>
      </FormItem>
      <a
        className="login-form-button-go"
        onClick={() => {
          props.history.push('/login');
        }}
      >
        取消
      </a>
    </Form>
  );
};
const FindPhonePwds = Form.create()(FindPhonePwd);
export default withRouter(FindPhonePwds);
