/* eslint-disable */
import { GETTABLELIST, DEL, APLICATIONTYPELIST, APLICATIONTYPEdetail } from './actionType';

const defaultState = {
  TableListData: [],
  list: [],
};

function RuleConfig(state = defaultState, action) {
  switch (action.type) {
    case GETTABLELIST: {
      return { ...state, TableListData: action.data.data, TableListDataLength: action.data.total };
    }
    case DEL: {
      return { ...state };
    }
    case APLICATIONTYPELIST: {
      return { ...state, list: action.data };
    }
    case APLICATIONTYPEdetail: {
      return { ...state, detailData: action.data };
    }
    default:
      return state;
  }
}
export default RuleConfig;
