/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Row, Col, message } from 'antd';
import { sendPhoneCode, activePhone } from '../../request/request';
import './activePhone.less';
const phoneReg = /^\d{11}$/;
const FormItem = Form.Item;
const ActivePhone = (props) => {
  const [phoneNum, setPhoneNum] = useState('');
  // 是否允许点击发送
  const [isClickSend, setClickSend] = useState(true);
  // 文本显示
  const [showText, setShowText] = useState(''); // 发送中 已发送

  useEffect(() => {
    setPhoneNum(sessionStorage.getItem('activePhone'));
  }, []);

  const changePhone = (e) => {
    setPhoneNum(e.target.value);
  };

  const getPhoneCode = async () => {
    if (!phoneReg.test(phoneNum)) {
      message.error('请输入正确的电话号码');
      return;
    }
    const data = await sendPhoneCode({ phone: phoneNum });
    // 获取手机验证码页面等待60S
    if (data?.verifyCodeExpiration) {
      setClickSend(false);
      setShowText('发送中');
      let times = 60;
      // 定时器
      let timer = setInterval(() => {
        times--;
        if (times <= 0) {
          setClickSend(true);
          clearInterval(timer);
          return;
        }
        setShowText(`已发送(${times})`);
      }, 1000);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    props.form.validateFields(async (err, values) => {
      if (!err) {
        console.log(values);
        const data = await activePhone(values);
        if (data) {
          message.success('手机号验证成功，即将跳转到登录页面');
          setTimeout(() => {
            window.location.href = data.loginUrl;
          }, 1000);
        }
      }
    });
  };

  const regPhone = sessionStorage.getItem('activePhone');
  const { getFieldDecorator } = props.form;
  let { loginTitle } = window.webConfig;
  return (
    <div className="activePhone-panel-box">
      <div className="common-panel-content">
        <span className="panel-title">{loginTitle}</span>
        <div className="newsendEmail">
          {/*<div className="title">发送手机号验证链接</div>*/}
          <Form onSubmit={handleSubmit}>
            <FormItem>
              {getFieldDecorator('phone', {
                initialValue: regPhone,
                rules: [
                  {
                    required: true,
                    message: '手机号不能为空',
                  },
                  {
                    pattern: /^1[3456789]\d{9}$/,
                    message: '请输入正确格式的手机号',
                  },
                ],
              })(<Input className="username-info" placeholder="手机账号" onChange={changePhone} />)}
            </FormItem>

            {/*按钮界面*/}
            <FormItem className="err-err-panel">
              <Row>
                <Col span={14}>
                  {getFieldDecorator('code', {
                    rules: [
                      { required: true, message: '验证码不能为空' },
                      { min: 4, message: '验证码不能少于四位' },
                    ],
                  })(<Input autoComplete="off" type="text" name="checkCode" placeholder="请输入验证码" />)}
                </Col>
                <Col span={10}>
                  <div className="reg-duanxin">
                    {isClickSend ? (
                      <a onClick={getPhoneCode} className="button-color-color">
                        获取短信验证码
                      </a>
                    ) : (
                      <a onClick={() => message.warning('请勿重复获取')} className="button-color-color">
                        {showText}
                      </a>
                    )}
                  </div>
                </Col>
              </Row>
            </FormItem>

            <FormItem>
              <Button type="primary" htmlType="submit" className="login-form-button">
                提交
              </Button>
            </FormItem>
            <a
              className="login-form-button-go"
              onClick={() => {
                props.history.push('/login');
              }}
            >
              返回
            </a>
          </Form>
        </div>
      </div>
    </div>
  );
};
const ActivePhones = Form.create()(ActivePhone);
export default ActivePhones;
