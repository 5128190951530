/* eslint-disable */
/*
 * @Author: kiliaosi
 * @Date: 2020-06-15 14:47:56
 * @LastEditors: kiliaosi
 * @LastEditTime: 2020-06-18 16:58:44
 * @Description:
 */

import React from 'react';
import { Form, Input, Button, Row, Col, message } from 'antd';
import VerifyCode from '@/common/verifyCode';
const phoneReg = /^\d{11}$/;
const FormItem = Form.Item;
class SendEmail extends React.Component {
  state = {
    phoneNum: '',
  };
  componentDidMount() {
    this.setState({
      phoneNum: sessionStorage.getItem('activePhone'),
    });
  }
  sendPhoneCode() {
    if (this.props.sendPhoneLoading) {
      message.error('请不要重复获取');
      return;
    }
    if (!phoneReg.test(this.state.phoneNum)) {
      message.error('请输入正确的电话号码');
      return;
    }
    this.props.sendPhoneCode({ phone: this.state.phoneNum });
  }
  changePhone(e) {
    this.setState({ phoneNum: e.target.value });
  }
  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        console.log(values);
        this.props.checkResetPhone(values);
      }
    });
  };
  render() {
    let regPhone = sessionStorage.getItem('activePhone');
    console.log('imgSrc', this.props.ImgCodeTime);
    console.log('激活邮件页面props', this.props);
    const { getFieldDecorator } = this.props.form;
    const { sendPhoneCodeMsg } = this.props;
    console.log(sendPhoneCodeMsg);
    return (
      <div className="newsendEmail">
        {/*<div className="title">发送手机号验证链接</div>*/}
        <Form onSubmit={this.handleSubmit}>
          <FormItem>
            {getFieldDecorator('phone', {
              initialValue: regPhone,
              rules: [
                {
                  required: true,
                  message: '手机号不能为空',
                },
                {
                  pattern: /^1[3456789]\d{9}$/,
                  message: '请输入正确格式的手机号',
                },
              ],
            })(<Input className="username-info" placeholder="手机账号" onChange={(e) => this.changePhone(e)} />)}
          </FormItem>

          {/*按钮界面*/}
          <FormItem className="err-err-panel">
            <Row>
              <Col span={14}>
                {getFieldDecorator('code', {
                  rules: [
                    { required: true, message: '验证码不能为空' },
                    { min: 4, message: '验证码不能少于四位' },
                  ],
                })(
                  <Input
                    autoComplete="off"
                    type="text"
                    name="checkCode"
                    placeholder="请输入验证码"
                    // prefix={<MyIcon src="/images/login-yanzma.png" />}
                  />,
                )}
              </Col>
              <Col span={10}>
                <div className="reg-duanxin">
                  <a onClick={() => this.sendPhoneCode()} className="button-color-color">
                    {sendPhoneCodeMsg}
                  </a>
                </div>
              </Col>
            </Row>

            {this.props.errorMsg ? (
              <div className="ant-form-explain errlist" dangerouslySetInnerHTML={{ __html: this.props.errorMsg }}></div>
            ) : null}
          </FormItem>

          <FormItem>
            <Button loading={this.props.activeLoading} type="primary" htmlType="submit" className="login-form-button">
              提交
            </Button>
          </FormItem>
          <a
            className="login-form-button-go"
            onClick={() => {
              this.props.history.push('/login');
            }}
          >
            返回
          </a>
        </Form>
      </div>
    );
  }
}
const SendEmails = Form.create()(SendEmail);
export default SendEmails;
