/* eslint-disable */
import React from 'react';
import UserPanel from '../common/component/panel.js';
import SendEmail from './component/send_email.js';
import { connect } from 'react-redux';
import { sendEmail } from './reducer/action.js';
class UserActive extends React.Component {
  render() {
    return (
      <UserPanel>
        <SendEmail {...this.props} />
      </UserPanel>
    );
  }
}
const UserActives = connect(
  (state) => {
    return state.SendEmailActive;
  },
  { sendEmail },
)(UserActive);
export default UserActives;
