/* eslint-disable */
import React from 'react';
import { Button, Spin } from 'antd';
import { get } from 'lodash';

import './popup.less';

const PopUpNoticeInfo = (props) => {
  const { noticeInfo, noticeLoading, handelClose } = props;
  const content = get(noticeInfo, 'content', '');
  let noticeContent = content && content.split(/[;；]\s*/);

  return (
    <div className="notice-info">
      <Spin spinning={noticeLoading}>
        <div className="notice-content">
          <div className="title">
            <strong>【{noticeInfo?.messType === 1 ? '版本更新' : '系统消息'}】</strong>
            <span>{noticeInfo?.title}</span>
          </div>
          <div className="content">
            <div className="notice-info-content">
              { noticeContent.map((item, index) => {
                return (<p key={index}>{ item }</p>)
              }) }
            </div>
          </div>
        </div>
      </Spin>
      <div className="layoutconfirm">
        <Button type="primary" onClick={ () => handelClose() }>确定</Button>
      </div>
    </div>
  )
}

export default PopUpNoticeInfo
