/* eslint-disable */
import * as actionType from './constants.js';
const init = {
  sendPhoneCodeMsg: '获取短信验证码',
  sendPhoneLoading: false,
  activeLoading: false,
};
export default function activeReducer(state = init, action) {
  switch (action.type) {
    //短信验证码激活
    case actionType.SEND_PHONE_CODE:
      return { ...state, sendPhoneLoading: true };
    case actionType.SEND_PHONE_CODE_END:
      return { ...state, sendPhoneLoading: false };
    case actionType.SEND_PHONE_MSG:
      return { ...state, sendPhoneCodeMsg: action.loadMsg };
    //提交
    case actionType.ACTIVE_SEND_PHONE:
      return { ...state, activeLoading: true };
    case actionType.ACTIVE_SEND_SUCCESS:
      return { ...state, activeLoading: false, errorMsg: false };
    case actionType.ACTIVE_SEND_ERR:
      return { ...state, activeLoading: false, errorMsg: action.msg };
    default:
      return state;
  }
}
