/* eslint-disable */
import React from 'react';
import md5 from 'md5';
import { Form, Input, Button, message, Modal } from 'antd';
import { resetPassword } from '../../request/request';
import './resetPassword.less';
import { passwordRegExp } from '../common/config_regexp';
const FormItem = Form.Item;
const ResetPassword = (props) => {
  let { loginTitle } = window.webConfig;
  const { getFieldDecorator } = props.form;
  // 获取url中的token
  const getToken = () => {
    // 用户通过找回密码邮件，跳转到/user/resetpwd?token=xxx这个链接
    // 当前方法目的是获取token
    let url = location.search; // 获取url中"?"符后的字串
    if (url.indexOf('?') !== -1) {
      let str = url.substr(1);
      let tokenKey = str.split('=')[0];
      if (tokenKey === 'token') {
        return str.split('=')[1];
      }
    }
    return null;
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    props.form.validateFields((err, values) => {
      if (!err) {
        const token = getToken();
        values.password = md5(values.password);
        values.onePassword = md5(values.onePassword);
        if (values.password !== values.onePassword) {
          message.error('两次输入的密码不一致');
          return;
        } else {
          const data = resetPassword({ password: values.password, token });
          if (data) {
            Modal.info({
              title: '密码修改成功，请重新登录',
              onOk() {
                props.history.push('./login');
              },
            });
          }
        }
      }
    });
  };
  return (
    <div className="resetPwd-panel-box">
      <div className="common-panel-content">
        <span className="panel-title">{loginTitle}</span>
        <Form onSubmit={handleSubmit}>
          <FormItem>
            {getFieldDecorator('onePassword', {
              rules: [
                { required: true, message: '密码不能为空' },
                { min: 6, message: '密码不能少于六位' },
                { pattern: passwordRegExp, message: '密码必须由字母和数字组成' },
              ],
            })(<Input className="username-info" type="password" placeholder="请输入新密码" />)}
          </FormItem>
          {/* 按钮界面 */}
          <FormItem>
            {getFieldDecorator('password', {
              rules: [
                { required: true, message: '密码不能为空' },
                { min: 6, message: '密码格式不正确！' },
              ],
            })(<Input type="password" placeholder="请再次输入新密码" />)}
          </FormItem>

          {/* 按钮 */}

          <FormItem>
            <Button type="primary" htmlType="submit" className="login-form-button">
              提交
            </Button>
          </FormItem>
        </Form>
      </div>
    </div>
  );
};
const ResetPasswords = Form.create()(ResetPassword);
export default ResetPasswords;
