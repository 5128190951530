/* eslint-disable */
/*
 * @Author: kiliaosi
 * @Date: 2020-06-15 14:47:56
 * @LastEditors: kiliaosi
 * @LastEditTime: 2020-06-15 15:49:39
 * @Description:
 */
import React from 'react';
import UserPanel from '../common/component/panel';
import { Form, Input, Button, message } from 'antd';
import { connect } from 'react-redux';
import { resetPwdwender } from './reducer/action';
import md5 from 'md5';
import { passwordRegExp } from '../../../config/regexp';

const FormItem = Form.Item;

class ResetPwd extends React.Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  handleSubmit(e) {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const token = this.getToken();
        values.password = md5(values.password);
        values.onePassword = md5(values.onePassword);
        if (values.password != values.onePassword) {
          message.error('两次输入的密码不一致');
          return;
        } else {
          this.props.resetPwdwender(values.password, token);
        }
      }
    });
  }
  getToken() {
    // 用户通过找回密码邮件，跳转到/user/resetpwd?token=xxx这个链接
    // 当前方法目的是获取token
    let url = location.search; //获取url中"?"符后的字串
    if (url.indexOf('?') != -1) {
      let str = url.substr(1);
      let tokenKey = str.split('=')[0];
      if (tokenKey === 'token') {
        return str.split('=')[1];
      }
    }
    return null;
  }
  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <UserPanel>
        {/* <div>重置密码</div> */}
        <Form onSubmit={this.handleSubmit}>
          <FormItem>
            {getFieldDecorator('onePassword', {
              rules: [
                { required: true, message: '密码不能为空' },
                { min: 6, message: '密码不能少于六位' },
                { pattern: passwordRegExp, message: '密码必须由字母和数字组成' },
              ],
            })(<Input className="username-info" type="password" placeholder="请输入新密码" />)}
          </FormItem>
          {/*按钮界面*/}
          <FormItem>
            {getFieldDecorator('password', {
              rules: [
                { required: true, message: '密码不能为空' },
                { min: 6, message: '密码格式不正确！' },
              ],
            })(<Input type="password" placeholder="请再次输入新密码" />)}
          </FormItem>

          {/*按钮*/}

          <FormItem>
            <Button loading={this.props.resetLoading} type="primary" htmlType="submit" className="login-form-button">
              提交
            </Button>
          </FormItem>
        </Form>
      </UserPanel>
    );
  }
}
const WrappedNormalResetPwd = Form.create()(ResetPwd);
const WrappedNormalResetPwds = connect(
  (state) => {
    return state.ResetUserPwd;
  },
  { resetPwdwender },
)(WrappedNormalResetPwd);
export default WrappedNormalResetPwds;
