/* eslint-disable */
import React from 'react';
import { Form, Icon, Input, Button, Checkbox, Row, Col, message } from 'antd';
import md5 from 'md5';
import { passwordRegExp } from '../../../../config/regexp.js';
import MyIcon from '../../login/component/loginIcon';
import VerifyCode from '@/common/verifyCode';
import '../style/regitem.less';

const phoneReg = /^\d{11}$/;
const FormItem = Form.Item;
class RegFroms extends React.Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.changePhone = this.changePhone.bind(this);
    this.changeCodeTime = this.changeCodeTime.bind(this);
    this.AgreeMent = this.AgreeMent.bind(this);
    this.state = {
      PhoneCodeDesc: '获取短信验证码',
      phoneNum: null,
      phoneCodeTime: null,
      disabledCo: true,
      AgreeMent: false,
      codeTime: 1,
    };
  }
  changeCodeTime() {
    const phoneNum = this.state.phoneNum;
    if (!phoneReg.test(phoneNum)) {
      message.error('手机号码格式错误');
      return;
    }
    //console.log(phoneNum);
    this.props.getPhoneCode(phoneNum);
  }
  handleSubmit(e) {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!this.state.AgreeMent) {
        message.info('请仔细阅读并同意用户协议！');
        return;
      }
      if (!err) {
        values.password = md5(values.password);
        values.checkcode = values.checkcodePhone;
        this.props.phoneRegister(values);
      }
    });
  }
  changePhone(e) {
    /* this.setState({
			phoneNum:
		}) */
    //console.log(e.target.value);
    this.setState({ phoneNum: e.target.value });
  }
  AgreeMent(e) {
    this.setState({ AgreeMent: !this.state.AgreeMent });
  }
  // alertDege() {
  //   message.info("请仔细阅读并同意用户协议");
  // }
  render() {
    //console.log(this.props.timesLoading);
    const { getFieldDecorator } = this.props.form;
    //console.log("wuwuiwui");
    return (
      <Form onSubmit={this.handleSubmit} className="regitem">
        <FormItem className="register-phone">
          {getFieldDecorator('phoneNumber', {
            initialValue: this.state.phoneNum,
            rules: [
              {
                required: true,
                message: '手机号不能为空',
              },

              {
                pattern: phoneReg,
                message: '请输入正确的手机号',
              },
            ],
          })(
            <Input
              prefix={<MyIcon src="/images/login-user.png" />}
              autoComplete="new-phone"
              className="username-info register-input-user"
              placeholder="请输入手机号"
              onChange={this.changePhone}
            />,
          )}
        </FormItem>

        <FormItem className="err-err-panel">
          <Row>
            <Col span={14}>
              {getFieldDecorator('phonecode', {
                rules: [
                  { required: true, message: '验证码不能为空' },
                  { min: 4, message: '验证码不能少于四位' },
                ],
              })(
                <Input
                  autoComplete="new-phone"
                  type="text"
                  name="checkCode"
                  placeholder="请输入验证码"
                  prefix={<MyIcon src="/images/login-yanzma.png" />}
                />,
              )}
            </Col>
            <Col span={10}>
              <div className="reg-duanxin">
                <a
                  className="button-color-color"
                  onClick={this.props.code_min_lang_loading ? null : this.changeCodeTime}
                >
                  {this.props.phoneMessage}
                </a>
              </div>
            </Col>
          </Row>
        </FormItem>

        {/*按钮界面*/}
        <FormItem>
          {getFieldDecorator('password', {
            rules: [
              { required: true, message: '密码不能为空' },
              { min: 6, message: '密码不能少于6位' },
              { pattern: passwordRegExp, message: '密码必须由字母和数字组成' },
            ],
          })(<Input.Password prefix={<MyIcon src="/images/pwd-icon.png" />} placeholder="请输入密码" />)}
        </FormItem>

        {this.props.checkCode ? (
          <FormItem className="err-err-panel">
            <Row>
              <Col span={14}>
                {getFieldDecorator('checkcodePhone', {
                  rules: [
                    { required: true, message: '验证码不能为空' },
                    { min: 4, message: '验证码不能少于四位' },
                  ],
                })(<Input autoComplete="new-reg-code" type="text" placeholder="验证码" />)}
              </Col>
              <Col span={10}>
                <div className="checkCode">
                  <VerifyCode onClick={this.props.updateImgCode} timestap={this.props.imgCodeTime} />
                </div>
              </Col>
            </Row>
          </FormItem>
        ) : null}

        <div className="res-argee-ment mobil-res-argee-ment">
          <Checkbox onChange={this.AgreeMent} checked={this.state.AgreeMent} className="antd-checkboxinput">
            我已阅读并同意
            <a href="https://www.kiwisec.com/service/agreements" target="_blank" className="button-color-color">
              《用户协议》
            </a>
          </Checkbox>
        </div>

        <FormItem>
          <Button
            type="primary"
            htmlType={this.state.AgreeMent ? 'submit' : null}
            loading={this.props.reg_loading}
            onClick={this.state.AgreeMent ? null : this.alertDege}
            className="login-form-button antdButtonSelf"
          >
            注册账号
          </Button>
        </FormItem>
      </Form>
    );
  }
}
const WrappedNormalRegForm = Form.create()(RegFroms);

export default WrappedNormalRegForm;
