/* eslint-disable */
import React from 'react';
import RegFrom from './component/phone_from';
import { message, Tabs, Icon, Row, Col } from 'antd';
import './style/reg.less';
import './style/regitem.less';
import Mail_reg from './component/mail_reg';
import { connect } from 'react-redux';
import UserPanel2 from '../common/component/panel2';
import { getPhoneCode, phoneRegister, mailRegister, updateImgCode } from './reducer/action';

const TabPane = Tabs.TabPane;

message.config({
  top: 100,
  duration: 2,
  maxCount: 2,
});
class Register extends React.Component {
  render() {
    const { changeUserOperation } = this.props;
    let webConfig = window.webConfig;
    const allowPhone = webConfig.allowLoginPhone !== undefined ? webConfig.allowLoginPhone : true;
    const allowEmail = webConfig.allowLoginEmail !== undefined ? webConfig.allowLoginEmail : true;
    return (
      <UserPanel2>
        <Tabs animated={false}>
          {allowPhone ? (
            <TabPane
              tab={
                <span className="min-dark">
                  <Icon type="mobile" />
                  手机号注册
                </span>
              }
              key="1"
            >
              <RegFrom
                getPhoneCode={this.props.getPhoneCode}
                timesLoading={this.props.timesLoading}
                phoneMessage={this.props.phoneMessage}
                code_min_lang_loading={this.props.code_min_lang_loading}
                phoneRegister={this.props.phoneRegister}
                reg_loading={this.props.reg_loading}
                checkCode={this.props.checkCode}
                updateImgCode={this.props.updateImgCode}
                // 验证码时间戳
                imgCodeTime={this.props.imgCodeTime}
              />
            </TabPane>
          ) : null}

          {allowEmail ? (
            <TabPane
              tab={
                <span className="min-dark">
                  <Icon type="mail" />
                  邮箱账号注册
                </span>
              }
              key="2"
            >
              <Mail_reg
                // 邮箱注册
                mailRegister={this.props.mailRegister}
                // 注册loading
                mail_loading={this.props.mail_loading}
                // 更改用户
                changeUserOperation={changeUserOperation}
                // 检查验证码
                checkCode={this.props.checkCode}
                // 显示邮箱按钮
                show_email_btn={this.props.show_email_btn}
                // 验证码时间戳
                imgCodeTime={this.props.imgCodeTime}
                updateImgCode={this.props.updateImgCode}
              />
            </TabPane>
          ) : null}
        </Tabs>
        <Row>
          <Col span={24} className="loginIcon">
            <div>
              <span className="min-dark ">已有账号: </span>
              <a
                className="button-color-color"
                onClick={() => {
                  changeUserOperation({ code: 1 });
                }}
              >
                立即登录
              </a>
            </div>
            <div>
              {/* <span className="min-dark">第三方登录:</span>
              <span
                className="icon-pravite icon-qq"
                onClick={this.props.qqLogin}
              />
              <span
                className="icon-pravite icon-wechat"
                onClick={this.props.wxLogin}
              /> */}
            </div>
          </Col>
        </Row>
      </UserPanel2>
    );
  }
}
const Registers = connect(
  (state) => {
    return state.Register;
  },
  { getPhoneCode, phoneRegister, mailRegister, updateImgCode },
)(Register);
export default Registers;
