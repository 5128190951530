/* eslint-disable */
import { GETSAFEIDEALIST, GETSINGLEIDEA } from './actionType';
const defaultState = {
  singleSafeIdea: [], // 单个的安全策略
  relevance_project: [], // 单个的安全策略 关联的项目
  tactics: [], // 威胁场景 数组
};
function reducer(state = defaultState, action) {
  switch (action.type) {
    case GETSAFEIDEALIST: {
      return {
        ...state,
        safeIdeaArr: action.data.data,
      };
    }
    case GETSINGLEIDEA: {
      return {
        ...state,
        initPolicyName: action.data[0].policy_name,
        tactics: action.data[0].tactics,
        tactic_count: action.data[0].tactic_count,
        relevance_project: action.data[0].relevance_project,
      };
    }
    default:
      return state;
  }
}
export default reducer;
