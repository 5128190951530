/* eslint-disable */
/*
 * @Author: rookie.CYJ
 * @Date: 2020-11-10 09:30:03
 * @Last Modified by: rookie.CYJ
 * @Last Modified time: 2021-04-27 11:01:54
 */
import api from './api';
import axios from '@/utils/request';
import { message } from 'antd';
import { changeTableData } from './action';
import { get } from 'lodash';

// 获取数据
const getTableData = () => async (dispatch) => {
  console.log('operation数据请求');
  let obj = { a: 'cyj' };
  try {
    const { data } = await axios.get(api.getTableData, {
      params: obj,
    });
    // console.log(data);
    let code = get(data, 'code', '');
    // let dataSource = get(data, "data", []);
    // if (code === 1) {
    //   console.log(dataSource);
    //   dispatch(changeTableData(dataSource));
    // } else {
    //   message.error(data.msg);
    // }
    return { code };
  } catch (error) {
    const { response = {} } = error;
    const msg = get(response, 'data.msg', '数据请求失败');
    message.error(msg);
    console.error(error);
  }
};

export default {
  getTableData,
};
