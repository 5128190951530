/* eslint-disable */
/*
 * @Author: kiliaosi
 * @Date: 2020-06-15 14:47:56
 * @LastEditors: kiliaosi
 * @LastEditTime: 2020-06-15 15:22:57
 * @Description:
 */
import * as actionType from './actionType.js';
import axios from '@/utils/request';
import promise from 'es6-promise';
import { message } from 'antd';

promise.polyfill();
const api = {
  sendReset: '/saas-auth/open/resetPassword',
};
//重置密码发送

//获取token
//发送验证
function getJsonData(data) {
  const jsonData = window.jsonData;
  return jsonData;
}
export function resetPwdwender(password, token) {
  return (dispatch) => {
    dispatch({ type: actionType.SEND_RESET });
    let passwordObj = {};
    passwordObj.password = password;
    passwordObj.token = token;
    //const privateJson = getJsonData(window.jsonData);
    //console.log(password);
    // if (!privateJson.resetpwd_token) {
    //   message.error('验证失败，请刷新重试');
    //   dispatch({ type: actionType.SEND_RESET_END });
    //   return;
    // }
    // passwordObj.t = privateJson.resetpwd_token;
    // if (/t=/.test(window.location.search)) {
    //   passwordObj.type = 9;
    // } else {
    //   passwordObj.type = 1;
    // }
    axios
      .post(api.sendReset, passwordObj)
      .then((res) => {
        if (res.data.code == 1) {
          message.success('密码重置成功,即将跳转登录页');
          setTimeout(() => {
            window.location.href = res.data.data.loginUrl;
          }, 1000);
        } else {
          message.error(res.data.msg);
        }
        dispatch({ type: actionType.SEND_RESET_END }); //action执行结束
      })
      .catch((err) => {
        message.error('密码重置失败,请稍后重试');
        dispatch({ type: actionType.SEND_RESET_END }); //action执行结束
      });
  };
}
