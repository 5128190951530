/* eslint-disable */
import React from 'react';
import { Menu } from 'antd';
import PrivateIcon from './icon';
import component from '@/config/component';
import './leftMenu.less';
import { getKeyFormArray } from '@/utils/utils'

const SubMenu = Menu.SubMenu;
const submenu = async (menu, open, key, callback) => {
  for (const item of menu) {
    if (item.subMenu) {
      submenu(item.subMenu, open.concat([item.key]), key, callback);
    } else if (item.key === key) {
      callback(open.concat([item.key]));
    }
  }
};
class LeftMenu extends React.Component {
  constructor(props) {
    super(props);
    const { history, menu } = props;
    history.listen(async (...rest) => {
      const selectKey = component.find(({ loc }) => rest[0].pathname.startsWith(loc))?.key;
      if (selectKey) {
        let openId = [];
        submenu(menu, [], selectKey, (value) => {
          openId = value;
        });
        this.setState({
          menu,
          openId,
          selectKey,
          selectActive: selectKey,
        });
      }
    });
    this.state = {
      menu: [],
      // 展开的 SubMenu 菜单项标题 key 数组
      openId: [],
      // 默认选中的菜单项key
      selectKey: '',
      // 选中的菜单项key
      selectActive: 'android_detection',
    };
  }
  async componentDidMount() {
    const { menu } = this.props;
    const selectKey = component.find(({ loc }) => window.location.pathname.startsWith(loc))?.key; // 选中的菜单
    let openId = [];
    submenu(menu, [], selectKey, (value) => {
      openId = value;
    });
    this.setState({
      menu,
      openId,
      selectKey,
      selectActive: selectKey,
    });
  }

  getPath(key) {
    let path;
    component.some((item) => {
      if (item.key === key) {
        path = item.loc;
        return true;
      }
    });
    return path;
  }
  itemClickRed = (key) => {
    let path = this.getPath(key);
    return (obj) => {
      if (key == 'menu-sdkEncrypt') {
        window.location.hash = 'sdk';
      } else if (key == 'menu-defenseSdk') {
        window.location.hash = 'dsdk';
      }
      this.setState({ selectActive: key });
      if (window.location.pathname !== path) {
        this.props.history.push(path);
      }
    };
  };
  componentDidUpdate() {
    //浏览器返回按键左部导航返回
    if (this.getPath(this.state.selectActive) !== window.location.pathname) {
      component.some((item) => {
        if (item.loc === window.location.pathname) {
          this.setState({
            selectActive: item.key,
          });
        }
      });
    }
  }

  render() {
    const userNo = window.localStorage.getItem('kiwi.saas.userNo');

    if (this.props.menu) {
      let menuList = this.props.menu;
      const subMenu = (menuList, n) => {
        return menuList.map((item) => {
          if ('subMenu' in item && item.subMenu) {
            if (item.key === '客户管理') {
              const cIndexSubmenu = getKeyFormArray(item.subMenu, 'message_manage');
              if (item.subMenu.length === 1 && cIndexSubmenu > -1) {
                return null
              } else {
                return (
                  <SubMenu
                    id="secedn-submeun"
                    key={item?.key}
                    style={n === 0 ? { backgroundColor: '#333744' } : {}}
                    className={n === 0 ? '' : 'set-multilayer'}
                    title={
                      <span>
                        {n === 0 && <PrivateIcon iconName={item.icon} />}
                        <span>{item.title}</span>
                      </span>
                    }
                  >
                    {subMenu(item.subMenu, n + 1)}
                  </SubMenu>
                );
              }
            } else {
              return (
                <SubMenu
                  id="secedn-submeun"
                  key={item?.key}
                  style={n === 0 ? { backgroundColor: '#333744' } : {}}
                  className={n === 0 ? '' : 'set-multilayer'}
                  title={
                    <span>
                      {n === 0 && <PrivateIcon iconName={item.icon} />}
                      <span>{item.title}</span>
                    </span>
                  }
                >
                  {subMenu(item.subMenu, n + 1)}
                </SubMenu>
              );
            }
            
          } else {
            if (item.key === 'message_manage') {
              if (!(userNo === 'kiwi_admin' || userNo === '20171887') ) {
                return null;
              } else {
                return (
                  <Menu.Item key={item.key}>
                    <a onClick={this.itemClickRed(item.key)}>
                      {n === 0 && <PrivateIcon iconName={item.icon} />}
                      <span>{item.title}</span>
                    </a>
                  </Menu.Item>
                );
              }
              
            } else {
              return (
                <Menu.Item key={item.key}>
                  <a onClick={this.itemClickRed(item.key)}>
                    {n === 0 && <PrivateIcon iconName={item.icon} />}
                    <span>{item.title}</span>
                  </a>
                </Menu.Item>
              );
            }
          }
        });
      };
      return (
        <div className="left-menu-list">
          <div className="left-menu">
            <Menu
              theme="dark"
              multiple={true} // 是否允许多选
              mode="inline" // 菜单类型 内嵌
              onOpenChange={(openKeys) => {
                this.setState({
                  openId: openKeys,
                });
              }} // SubMenu 展开/关闭的回调
              onClick={({ item, key, keyPath, domEvent }) => {
                let { openId } = this.state;
                let newOpenId = openId;
                this.setState({ openId: newOpenId });
              }} // 点击 MenuItem 调用此函数
              // defaultOpenKeys={this.state.openId}
              openKeys={this.state.openId}
              defaultSelectedKeys={[this.state.selectKey]}
              selectedKeys={[this.state.selectActive]}
            >
              {subMenu(menuList, 0)}
            </Menu>
          </div>
          {
            this.props.layoutInfo.kiwi_saas_version && (<div className="version_base">{this.props.layoutInfo.kiwi_saas_version}</div>)
          }

        </div>
      );
    } else {
      return <div />;
    }
  }
}
export default LeftMenu;
