/* eslint-disable */
const spacename = 'TEXTDETECTION';
export const GET_TABLE_DATAS = `${spacename}GET_TABLE_DATAS`;
export const SET_SELECTED_ROW = `${spacename}SET_SELECTED_ROW`;
export const SET_SELECT_DATA = `${spacename}SET_SELECT_DATA`;
export const EDIT_VISIBLE = `${spacename}EDIT_VISIBLE`;
export const EDIT_WHITE_VISIBLE = `${spacename}EDIT_WHITE_VISIBLE`;
export const EDIT_WHITE_DATA = `${spacename}EDIT_WHITE_DATA`;
export const ADD_VISIBLE = `${spacename}ADD_VISIBLE`;
export const SET_TABLE_LOADING = `${spacename}SET_TABLE_LOADING`;
