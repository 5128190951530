/* eslint-disable */
/*
 * @Author: kiliaosi
 * @Date: 2020-06-15 14:47:56
 * @LastEditors: kiliaosi
 * @LastEditTime: 2020-06-15 14:53:14
 * @Description:
 */
export const LOGIN_SUCC = 'LOGIN_SUCC';
export const LOGIN_ERR = 'LOGIN_ERR';
export const LOGOUT_SUCC = 'LOGOUT_SUCC';
export const LOGOUT_ERR = 'LOGOUT_ERR';
export const LOADING = 'LOADING';
export const LOADEND = 'LOADEND';
export const SHOWCODE = 'SHOWCODE';

// 更新验证码
export const LOGIN_CHECK_CODE = 'LOGIN_CHECK_CODE';
