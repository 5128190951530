/* eslint-disable */
import { UPLOAD, SHOWUPLOADBAR, GETTABLELIST, DEL, UPDATA, CHECKEDDATA, DETECTIONAPK } from './actionType';

const defaultState = {
  showBar: false, // 控制上传进度条 是否显示
  progress: '', // 上传进度
  TableListData: [],
  singleCompanyData: [],
  Tablecount: 10,
  detectionApkTaskId: 0,
  reflectPhoneUrl: '',
};

function BusinessManagement(state = defaultState, action) {
  switch (action.type) {
    case GETTABLELIST: {
      return {
        ...state,
        TableListData: action.data.all,
        Tablecount: action.data.count,
        reflectPhoneUrl: action.data.cast_panel_url,
      };
    }
    case DEL: {
      return { ...state };
    }
    case UPDATA: {
      return { ...state, singleCompanyData: action.data };
    }
    // 进度条显示控制
    case SHOWUPLOADBAR: {
      return { ...state, showBar: action.showBar, progress: 0 };
    }
    // 上穿进度
    case UPLOAD: {
      return { ...state, progress: action.progress };
    }
    // 审查数据
    case CHECKEDDATA: {
      return { ...state, checkedData: action.data };
    }
    // 检测apk
    case DETECTIONAPK: {
      return { ...state, detectionApkTaskId: action.data };
    }
    default:
      return state;
  }
}
export default BusinessManagement;
