/* eslint-disable */
/* eslint-disable react/jsx-pascal-case */
import React from 'react';
import { Tabs, Icon, Row, Col } from 'antd';
import RegFrom from './component/phone_from';
import Mail_reg from './component/mail_reg';
import './style/register.less';
const TabPane = Tabs.TabPane;

const Register = (props) => {
  let webConfig = window.webConfig;
  // 是否允许邮箱或者手机号注册
  const allowPhone = webConfig?.allowLoginPhone;
  const allowEmail = webConfig?.allowLoginEmail;
  let { loginTitle } = window.webConfig;
  return (
    <div className="register-panel-box">
      <div className="common-panel-content">
        <span className="panel-title">{loginTitle}</span>
        <Tabs animated={false}>
          {allowPhone ? (
            <TabPane
              tab={
                <span className="min-dark">
                  <Icon type="mobile" />
                  手机号注册
                </span>
              }
              key="1"
            >
              <RegFrom />
            </TabPane>
          ) : null}

          {allowEmail ? (
            <TabPane
              tab={
                <span className="min-dark">
                  <Icon type="mail" />
                  邮箱账号注册
                </span>
              }
              key="2"
            >
              <Mail_reg />
            </TabPane>
          ) : null}
        </Tabs>
        <Row>
          <Col span={24} className="loginIcon">
            <div>
              <span className="min-dark ">已有账号: </span>
              <a
                className="button-color-color"
                onClick={() => {
                  props.history.push('/login');
                }}
              >
                立即登录
              </a>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};
export default Register;
