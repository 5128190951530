/* eslint-disable */
/*
 * @Author: kiliaosi
 * @Date: 2020-06-15 14:47:56
 * @LastEditors: kiliaosi
 * @LastEditTime: 2020-06-15 15:08:43
 * @Description:
 */

import React from 'react';
import '../panel2.less';
class UserPanel extends React.PureComponent {
  constructor(props) {
    super(props);
  }

  render() {
    let { loginTitle } = window.webConfig;
    return (
      <div className="newlogin-from2 reg-from">
        <div className="login-back">
          <span
            style={{
              display: 'block',
              textAlign: 'center',
              fontSize: '24px',
              // height:"24px",
              color: '#454545',
              marginBottom: '30px',
            }}
          >
            {loginTitle}
          </span>
          {/* <input type="password" style={{ display: "none" }} /> */}
          {/* <span
            className="user-title"
            style={isPrive ? { paddingTop: "16px" } : null}
          /> */}
          {this.props.children}
        </div>
      </div>
    );
  }
}
export default UserPanel;
