/* eslint-disable */
import React from 'react';
import { Icon } from 'antd';
import './header.less';
// import logo from './img/logo.png';
import qq from '../img/QQ.png';

function Header() {
  const webConfig = window.webConfig;
  let qqLink = webConfig?.qqLink; // 在线咨询链接
  let site = webConfig?.site; // 官网链接
  let logoimg = decodeURIComponent(webConfig.logo);
  return (
    <div className="login-header">
      <div className="header-left">
        <img src={logoimg} alt="" />
        <span className="header-top-title">{webConfig?.webLoginTitle}</span>
      </div>
      <div className="header-right">
        {qqLink ? (
          <span>
            <img src={qq} alt="" className="qq-img" />
            <a href={qqLink} target="_black" id="officeWebEnterprise" className="wordss">
              <i className="pri-qq private-icon" />
              在线咨询
            </a>
          </span>
        ) : null}

        {site ? (
          <span>
            <Icon type="home" style={{ fontSize: 24, marginRight: 5 }} />
            <a className="wordss" href={site} target="_blank">
              访问官网
            </a>
          </span>
        ) : null}
      </div>
    </div>
  );
}

export default Header;
