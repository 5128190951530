/* eslint-disable */
import axios from '@/utils/request';
import { message } from 'antd';
import * as actionType from './actionType';
import { versionChangeAction } from './action';

const setVersion = (value) => async (dispatch) => {
  dispatch(versionChangeAction({ value }));
}

const setUnReaderNum = (value) => async (dispatch) => {
  // try {
  //   axios.post('/saas-auth/message/getUnreadNum')
  //     .then(res => {
  //       let data = res.data;
  //       if (data.code === 1) {
  //         dispatch({
  //           type: actionType.UNREADER_NUM_ONMESSAGE,
  //           value: data?.data?.unreadNum + parseInt(Math.random() * 100 - 10)
  //         });
  //       }
  //     })
  //     .catch((err) => {
  //       message.error(err.message);
  //     });
  //
  // } catch (error) {
  //   const data = get(error, 'response.data', {});
  //   const { msg = '获取数据失败' } = data;
  //   message.error(msg);
  // }
  dispatch({
    type: actionType.UNREADER_NUM_ONMESSAGE,
    value,
  })
}

export default {
  setVersion,
  setUnReaderNum,
};
