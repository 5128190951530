/* eslint-disable */
import * as actionType from './actionType.js';

const getTableDatas = (data, total, pageCode, review_type, review_content_id, state, platform) => ({
  type: actionType.GET_TABLE_DATAS,
  data, //数据
  total, //Table总数
  pageCode, //页数
  review_type, //审查类型
  review_content_id, //审查内容
  state, //审查状态
  platform, //系统类型
});

const setSelectedRow = (arr) => ({
  type: actionType.SET_SELECTED_ROW,
  arr,
});

const setSelectData = (arrOne, arrTwo) => ({
  type: actionType.SET_SELECT_DATA,
  arrOne,
  arrTwo,
});

const editVisible = (is) => ({
  type: actionType.EDIT_VISIBLE,
  is,
});

const editWhiteData = (arr) => ({
  type: actionType.EDIT_WHITE_DATA,
  arr,
});

const editWhiteVisible = (is) => ({
  type: actionType.EDIT_WHITE_VISIBLE,
  is,
});

const addVisible = (is) => ({
  type: actionType.ADD_VISIBLE,
  is,
});

const setTableLoading = (is) => ({
  type: actionType.SET_TABLE_LOADING,
  is,
});

export { getTableDatas, setSelectedRow, setSelectData, editVisible, editWhiteVisible, editWhiteData, addVisible, setTableLoading };
