/* eslint-disable */
import { GETAPKINFO, GETTHREATSCENARIOS } from './actionType';
const defaultState = {
  tableData: [], // 表格数据
  tableDataTotal: 0, // 表格数据的总数
  ThreatScenariosArr: [], // 威胁场景数组
  apkInformation: {}, // 加固状态和apk解析信息对象
};
function reducer(state = defaultState, action) {
  switch (action.type) {
    case GETAPKINFO: {
      return {
        ...state,
        apkInformation: action.data,
      };
    }
    case GETTHREATSCENARIOS: {
      return { ...state, ThreatScenariosArr: action.data };
    }
    default:
      return state;
  }
}
export default reducer;
