/* eslint-disable */
/*
 * @Author: kiliaosi
 * @Date: 2020-06-15 14:47:56
 * @LastEditors: kiliaosi
 * @LastEditTime: 2020-06-18 16:59:52
 * @Description:
 */
import React from 'react';
import { Form, Input, Button, Row, Col } from 'antd';
import MyIcon from '../../login/component/loginIcon';
import VerifyCode from '@/common/verifyCode';
const FormItem = Form.Item;

class EmailFind extends React.Component {
  constructor(props) {
    super(props);
    this.state = { codeTime: new Date().getTime() };
    this.changeCodeTime = this.changeCodeTime.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  changeCodeTime() {
    this.props.changeNewTimeDate();
  }
  handleSubmit(e) {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.sendEmailFind(values);
      }
    });
  }
  render() {
    //console.log(this.props);
    const { getFieldDecorator } = this.props.form;
    return (
      <Form onSubmit={this.handleSubmit} className="find-password">
        <input type="password" style={{ display: 'none' }} />
        <FormItem>
          {getFieldDecorator('email', {
            rules: [
              { required: true, message: '邮箱不能为空' },
              { min: 6, message: '请输入邮箱' },
              {
                // pattern: /^([0-9A-Za-z\-_\.]+)@([0-9a-z]+\.[a-z]{2,3}(\.[a-z]{2})?)$/g,  old
                pattern: /^[a-zA-Z0-9]+([-_.][a-zA-Z0-9]+)*@[a-zA-Z0-9]+([-_.][a-zA-Z0-9]+)*\.[a-z]{2,}$/,
                message: '邮箱格式错误',
              },
            ],
          })(
            <Input className="username-info" placeholder="邮箱账号" prefix={<MyIcon src="/images/login-user.png" />} />,
          )}
        </FormItem>
        {/*按钮界面*/}
        <FormItem className="err-err-panel">
          <Row>
            <Col span={14}>
              {getFieldDecorator('checkCode', {
                rules: [
                  { required: true, message: '验证码不能为空' },
                  { min: 4, message: '验证码不能少于四位' },
                ],
              })(<Input type="text" placeholder="请输入验证码" prefix={<MyIcon src="/images/login-yanzma.png" />} />)}
            </Col>
            <Col span={10}>
              <div className="checkCode">
                <VerifyCode onClick={this.changeCodeTime} timestap={this.props.changeTimeDate} />
              </div>
            </Col>
          </Row>
        </FormItem>

        <FormItem>
          <Button
            loading={this.props.sendEmail}
            type="primary"
            htmlType="submit"
            className="login-form-button antdButtonSelf"
          >
            提交
          </Button>
        </FormItem>
        <a
          className="login-form-button-go"
          onClick={() => {
            this.props.history.push('/login');
          }}
        >
          取消
        </a>
      </Form>
    );
  }
}
const PhoneFindForm = Form.create()(EmailFind);
export default PhoneFindForm;
