/* eslint-disable */
const host = [
  {
    dev: 'http://192.168.3.99:8100',
    test: 'http://192.168.3.99:8100',
    pre: 'https://new-coapi.kiwisec.com',
    prd: 'https://wechat.kiwisec.com/gateway',
    vm: 'http://192.168.3.100:8100',
    private: '/gateway',
    demo: '',
    name: 'baseURL',
    prefix: '/cloud',
    rewritePrefix: false,
  },
  {
    dev: 'http://192.168.3.99:8100',
    test: 'http://192.168.3.99:8100',
    pre: 'https://new-coapi.kiwisec.com/appguard',
    prd: 'https://wechat.kiwisec.com/gateway',
    vm: 'http://192.168.3.100:8100',
    private: '/gateway',
    demo: '',
    name: 'iot',
    prefix: '/iot',
    rewritePrefix: false,
  },
  {
    dev: 'http://192.168.3.99:8100',
    test: 'http://192.168.3.99:8100',
    pre: 'https://new-gateway.kiwisec.com',
    prd: 'https://wechat.kiwisec.com/gateway',
    vm: 'http://192.168.3.100:8100',
    private: '/gateway',
    demo: '',
    name: 'appGuard',
    prefix: '/appguard',
    rewritePrefix: false,
  },
  {
    dev: 'http://192.168.3.99:8100',
    test: 'http://192.168.3.99:8100',
    pre: 'https://new-gateway.kiwisec.com',
    prd: 'https://wechat.kiwisec.com/gateway',
    vm: 'http://192.168.3.100:8100',
    private: '/gateway',
    demo: '',
    name: 'SAAS_ORDER',
    prefix: '/saas-order',
    rewritePrefix: false,
  },
  {
    dev: 'http://192.168.3.99:8100',
    test: 'http://192.168.3.99:8100',
    pre: 'https://new-gateway.kiwisec.com',
    prd: 'https://wechat.kiwisec.com/gateway',
    vm: 'http://192.168.3.100:8100',
    private: '/gateway',
    demo: '',
    name: 'SAAS_AUTH',
    prefix: '/saas-auth',
    rewritePrefix: false,
  },
  {
    dev: 'http://192.168.3.99:8100',
    test: 'http://192.168.3.99:8100',
    pre: 'https://new-gateway.kiwisec.com',
    prd: 'https://wechat.kiwisec.com/gateway',
    vm: 'http://192.168.3.100:8100',
    private: '/gateway',
    demo: '',
    name: 'SAAS_FILE',
    prefix: '/saas-file',
    rewritePrefix: false,
  },
  {
    dev: 'http://192.168.3.99:8100',
    test: 'http://192.168.3.99:8100',
    pre: 'https://new-gateway.kiwisec.com',
    prd: 'https://wechat.kiwisec.com/gateway',
    vm: 'http://192.168.3.100:8100',
    private: '/gateway',
    demo: '',
    name: 'app_privacy',
    prefix: '/appprivacy',
    rewritePrefix: false,
  },
  {
    dev: 'http://192.168.3.99:8100',
    test: 'http://192.168.3.99:8100',
    pre: 'https://new-gateway.kiwisec.com',
    prd: 'https://gateway.kiwisec.com',
    private: '/gateway',
    demo: 'https://demo-gateway.kiwisec.com',
    name: 'app_privacy',
    prefix: '/privacy-new',
    rewritePrefix: false,
  },
  {
    dev: 'http://192.168.3.99:8100',
    test: 'http://192.168.3.99:8100',
    pre: 'https://new-gateway.kiwisec.com',
    prd: 'https://gateway.kiwisec.com',
    private: '/gateway',
    demo: 'https://demo-gateway.kiwisec.com',
    name: 'android-detect',
    prefix: '/android-detect',
    rewritePrefix: false,
  },
  {
    dev: 'http://192.168.3.99:8100',
    test: 'http://192.168.3.99:8100',
    pre: 'https://new-gateway.kiwisec.com',
    prd: 'https://wechat.kiwisec.com/gateway',
    vm: 'http://192.168.3.100:8100',
    private: '/gateway',
    demo: 'https://demo-gateway.kiwisec.com',
    name: 'ios-detect',
    prefix: '/ios-detect',
    rewritePrefix: false,
  },
  {
    dev: 'http://192.168.3.99:8100',
    test: 'http://192.168.3.99:8100',
    pre: 'https://new-gateway.kiwisec.com',
    prd: 'http://vm.kiwitap.com:8100',
    vm: 'http://192.168.3.100:8100',
    private: 'http://192.168.3.137/gateway',
    demo: '',
    name: 'encryptcrash',
    prefix: '/encrypt',
  },
  {
    dev: 'http://192.168.11.61:180/gateway/',
    test: 'http://192.168.11.61:180/gateway',
    pre: 'https://new-gateway.kiwisec.com',
    prd: 'https://wechat.kiwisec.com/gateway',
    vm: 'http://192.168.3.100:8100',
    private: '/gateway',
    demo: '',
    name: 'wechat-applet-testing',
    prefix: '/wechat-applet-testing',
    rewritePrefix: false,
  },
  {
    dev: 'http://192.168.11.61:180/gateway/',
    test: 'http://192.168.11.61:180/gateway',
    pre: 'https://new-gateway.kiwisec.com',
    prd: 'https://wechat.kiwisec.com/gateway',
    vm: 'http://192.168.3.100:8100',
    private: '/gateway',
    demo: '',
    name: 'wechat-applet-confusion',
    prefix: '/wechat-applet-confusion',
    rewritePrefix: false,
  },
];
export default host;
