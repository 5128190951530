/* eslint-disable */
/*
 * @Author: kiliaosi
 * @Date: 2020-06-15 14:47:56
 * @LastEditors: kiliaosi
 * @LastEditTime: 2020-06-15 14:54:32
 * @Description:
 */
import * as actionType from './actionType.js';

var init = {
  reg_success: false,
  reg_loading: false,
  code_loading: false,
  code_min_lang_loading: false,
  timesLoading: 60,
  phoneMessage: '获取短信验证码',
  mail_loading: false,
  checkCode: false,
  show_email_btn: false,
  regEmail: '',
  imgCodeTime: new Date().getTime(),
};

export default function regReducer(state = init, action) {
  switch (action.type) {
    case actionType.REG_LOADING: //注册等待
      return { ...state, reg_loading: true };

    case actionType.REG_LOADINGEND: //注册等待结束
      return { ...state, reg_loading: false };

    case actionType.REG_ERR: //注册失败
      return { ...state, reg_success: false, err_msg: action.err_msg };

    case actionType.REG_SUCCESS: //注册成功
      return { ...state, reg_success: true };

    case actionType.REG_CODE_LOADING: //验证码获取等待
      return { ...state, code_loading: true };

    case actionType.REG_CODE_LOADEND: //验证码获取等待结束(服务器端已经响应)
      return { ...state, code_loading: false };

    case actionType.REG_CODE_MIN_LOADING: //验证码获取等待
      return {
        ...state,
        code_min_lang_loading: true,
        phoneMessage: action.phoneMessage,
        timesLoading: action.time,
      };

    case actionType.REG_CODE_MIN_LOADEND: //验证码获取等待结束
      return {
        ...state,
        code_min_lang_loading: false,
        phoneMessage: action.phoneMessage,
      };
    //邮箱注册等待开启
    case actionType.REG_EMAIL_LOADING:
      return { ...state, mail_loading: true };
    //邮箱注册等待结束
    case actionType.REG_EMAIL_LOADEND:
      return { ...state, mail_loading: false };

    case actionType.SHOW_ACTIVE_BTN:
      return { ...state, show_email_btn: true, regEmail: action.regEmail };

    case actionType.REG_SHOW_CODE:
      return { ...state, checkCode: true };

    case actionType.REG_UPDATE_CODE:
      return { ...state, imgCodeTime: new Date().getTime() };

    default:
      return state;
  }
}
