/* eslint-disable */
/*
 * @Author: kiliaosi
 * @Date: 2020-06-15 14:47:56
 * @LastEditors: kiliaosi
 * @LastEditTime: 2020-06-16 17:30:04
 * @Description:
 */
import * as actionType from './actionType.js';
import axios from '@/utils/request';
import promise from 'es6-promise';
import { message } from 'antd';
import md5 from 'md5';

promise.polyfill();

export default function userLogin(data) {
  return function (dispatch) {
    dispatch({ type: actionType.LOADING });
    const account = data.userName.replace(/^\s+|\s+$/g, '');
    let password = data.password.replace(/^\s+|\s+$/g, '');
    if (data.passwordDoMd5) {
      password = md5(password);
    }
    let param = {
      account: account,
      password: password,
      checkcode: data.checkcode,
    };
    axios
      .post('/saas-auth/open/login', param)
      .then((res) => {
        let data = res.data;
        if (res.data.code == 2020012) {
          sessionStorage.setItem('activeEmail', account);
          dispatch({
            type: actionType.LOGIN_ERR,
            value: '该邮箱未激活,请<a class="link"  href="/user/active">前往激活</a>',
          });
          //dispatch({ type: actionType.LOGIN_CHECK_CODE });
          //if (res.data.isShowCode) {
          //  dispatch({ type: actionType.SHOWCODE });
          //}
        } else if (res.data.code == 2020013) {
          sessionStorage.setItem('activePhone', account);
          dispatch({
            type: actionType.LOGIN_ERR,
            value: '该手机号未激活,请<a class="link"  href="/user/activephone">前往激活</a>',
          });
        } else if (res.data.code == 1) {
          localStorage.setItem('kiwi.saas.token', data.data.token);
          localStorage.setItem('kiwi.saas.userNo', data.data.userNo);
          localStorage.setItem('kiwi.saas.account', data.data.account);
          sessionStorage.setItem('activeEmail', data.data.account);
          message.success('登陆成功，即将跳转');
          dispatch({ type: actionType.LOGIN_SUCC });
        } else {
          dispatch({ type: actionType.LOGIN_ERR, value: data.message });
        }
        dispatch({ type: actionType.LOADEND });
      })
      .catch(function (err) {
        dispatch({ type: actionType.LOGIN_ERR, value: '登录超时，请稍后再试' });
        dispatch({ type: actionType.LOADEND });
      });
  };
}

// 点击切换验证码
export function updateImgCode() {
  return (dispatch) => {
    dispatch({
      type: actionType.LOGIN_CHECK_CODE,
    });
  };
}
