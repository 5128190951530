/* eslint-disable */
/*
 * @Author: kiliaosi
 * @Date: 2020-02-29 17:25:08
 * @LastEditors: kiliaosi
 * @LastEditTime: 2020-02-29 18:47:37
 * @Description:
 */

import iosDetection from './detection_ios';

import operation from './operations';

export default iosDetection;

export { operation };
