/* eslint-disable */
import axios from '@/utils/request';
import { get } from 'lodash';
// 获取模块的权限信息 参数为模块的key 返回的对象直接传入权限展示的公共组件就行
const getPowerInformation = async (moduleKey) => {
  const { data } = await axios.post('/saas-order/order/permission/detail', { productKey: moduleKey });
  let powerObj = get(data, 'data', {});
  return powerObj;
};
// 禁用img拖动 和屏蔽日志
const toolFunction = () => {
  // 禁用img拖动
  document.getElementsByTagName('body')[0].onmousedown = function (e) {
    if (e.target.tagName === 'IMG') {
      e.preventDefault();
    }
    if (e.target.classList.contains('qq-link')) {
      document.getElementById('officeWebEnterprise').click();
    }
  };
  // 生产环境 屏蔽打印的日志
  if (process.env.NODE_ENV === 'prd' || process.env.NODE_ENV === 'pre') {
    window.console = {
      log: () => {
        return null;
      },
      info: () => {
        return null;
      },
      warn: () => {
        return null;
      },
      error: () => {
        return null;
      },
    };
  }
};
// JS获取url参数 例如?id=xxx
const getUrlData = (name) => {
  let reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i');
  let r = window.location.search.substr(1).match(reg);
  if (r !== null) return unescape(r[2]);
  return false;
};

// 找到数组中是否存在某个key值
const getKeyFormArray = (arr, key) => {
  if (!key) return -1;
  if (arr instanceof Array && arr.length == 0) return -1;

  let cIndex = -1;
  arr.forEach((item, index) => {
    if (item.key === key) {
      cIndex = index;
      return;
    }
  })
  return cIndex;
}
export { toolFunction, getPowerInformation, getUrlData, getKeyFormArray };
