/* eslint-disable */
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Headers from './component/header/header'; // 头部组件
import Login from './component/login/login'; // 登录组件
import Register from './component/register/register'; // 注册组件
import ForgetPassword from './component/forgetPassword/forgetPassword'; // 忘记密码组件
import ResetPassword from './component/resetPassword/resetPassword'; // 重置密码组件
import verifyEmailSuccess from './component/verifyEmailSuccess/verifyEmailSuccess'; // 邮件注册成功 激活成功组件
import ActiveEmail from './component/activeEmail/activeEmail'; // 发起激活邮箱组件
import ActivePhone from './component/activePhone/activePhone'; // 发起激活邮箱组件
import './index.less';
// 登录注册模块
const LoginModule = () => {
  let copyright = window.webConfig.copyright;
  return (
    <div id="login-register-module">
      {/* 登录的头部 */}
      <Headers />
      {/* 登录注册面板组件  */}
      <div className="login-content-box">
        <Switch>
          {/* 发起激活邮箱组件 */}
          <Route path="/user/active" component={ActiveEmail} />
          {/* 邮件注册成功 激活成功组件 */}
          <Route path="/user/verify" component={verifyEmailSuccess} />
          {/* 发起手机激活验证码 */}
          <Route path="/user/activePhone" component={ActivePhone} />
          {/* 重置密码组件 */}
          <Route path="/user/resetpwd" component={ResetPassword} />
          {/* 注册组件 */}
          <Route path="/login/register" component={Register} />
          {/* 忘记密码组件 */}
          <Route path="/login/forgetPassword" component={ForgetPassword} />
          {/* 登录组件 */}
          <Route path="/login" component={Login} />
          <Route path="/" component={Login} />
        </Switch>
      </div>
      {/* 版权信息 */}
      <div className="newcopyright">
        {copyright ? copyright : `版权 © ${ new Date().getFullYear() } 成都盈海益讯科技有限公司 版权所有 蜀ICP备15015960号-1`}
      </div>
    </div>
  );
};
export default LoginModule;
