/* eslint-disable */
import { combineReducers } from 'redux';

// android检测
import * as detectionApk from '../modules/detection-module/android-detection/reducer.js';
// ios检测
import * as detectionIos from '../modules/detection-module/ios-detection/reducer';
// 应用合规审查
import * as ApplicationList from '../modules/privacy-detection-module/application-compliance-review/store';
// 归属企业管理
import * as BusinessManagement from '../modules/privacy-detection-module/business-management/store';
// 权限行为规则
import * as RuleConfig from '../modules/privacy-detection-module/ruleConfig/store';
// 隐私文本规则
import * as TextDetection from '../modules/privacy-detection-module/TextDetection/store';
// 隐私文本规则-new
import * as NewTextDetection from '../modules/privacy-detection-module-new/text-detection/store';
// SDK基础库
import * as SdkBank from '../modules/privacy-detection-module/SdkBank/store';
// android加固
import * as newencryptAPK from '../modules/android-encrypt-module/android-encrypt/store';
// h5加固
import * as h5Encrypt from '../modules/android-encrypt-module/android-encrypt/store';
// kiwiguard 应用管理
import * as applicationManagement from '../modules/kiwiguard-module/application-management/store';
// kiwiguard iOS 应用管理
import * as iosApplicationManagement from '../modules/kiwiguard-module/ios/application-management/store';
// 安全策略
import * as IdeaManage from '../modules/kiwiguard-module/security-policy/store';
// 企业信息
import * as EnterpriseInfo from '../modules/enterprise-management-module/enterprise-information/reducer/reducer.js';
// 企业成员
import * as MemberAdmin from '../modules/enterprise-management-module/enterprise-memberse/reducer/reducer.js';
// 角色成员
import * as RoleAdmin from '../modules/enterprise-management-module/role-management/reducer/reducer.js';
// 企业客户管理
import * as agent from '../modules/customer-management-module/enterprise-customer-management/store';
// 登录注册模块
import * as user from '../modules/login-register-module/reducer.js';
// 管理员
import * as adminList from '@/modules/customer-management-module/reducer.js';
// 检测配置模块
import * as DetectionCnf from '../modules/detection-module/detectionConfig/reducer.js';
// 平台基础数据-企业收录管理
import * as recordEnterprise from '../modules/platform_public/record-enterprise/store';
// 平台基础数据-应用收录管理
import * as recordApplication from '../modules/platform_public/record-application/store';

// layout version unReaderNum
import * as layoutInfo from '../layout/store';

const Reducer = Object.assign(
  {},
  user.default,
  detectionApk.default,
  detectionIos.default,
  EnterpriseInfo.default,
  MemberAdmin.default,
  RoleAdmin.default,
  { TextDetection: TextDetection.default },
  { NewTextDetection: NewTextDetection.default },
  { SdkBank: SdkBank.default },
  { recordEnterprise: recordEnterprise.default },
  { recordApplication: recordApplication.default },
  { agent: agent.default },
  { androidEncrypr: newencryptAPK.default },
  { h5Encrypt: h5Encrypt.default },
  { ideaManage: IdeaManage.default },
  { applicationManagement: applicationManagement.default },
  { RuleConfig: RuleConfig.default },
  { BusinessManagement: BusinessManagement.default },
  { ApplicationList: ApplicationList.default },
  adminList.default,
  DetectionCnf.default,
  { ideaManage: IdeaManage.default },
  { applicationManagement: applicationManagement.default },
  { iosApplicationManagement: iosApplicationManagement.default },
  { layoutInfo: layoutInfo.default },
);
const store = combineReducers({ ...Reducer });
export default store;
