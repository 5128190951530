/* eslint-disable */
export const GETAPP_DETE = 'GETAPP_DETE'; //获取app列表
export const APPINFO_DETE = 'APPINFO_DETE'; //获取App信息
export const UPLOAD_DETESTART = 'UPLOAD_DETESTART'; //上传开始
export const UPLOAD_DETEIN = 'UPLOAD_DETEIN'; //上传中；
export const UPLOAD_DETESUCC = 'UPLOAD_DETESUCC'; //上传成功
export const UPLOAD_DETEERR = 'UPLOAD_DETEERR'; //上传失败
export const UPLOAD_DETEEND = 'UPLOAD_DETEEND'; //上传结束；

export const DETE_LOADING = 'DETE_LOADING'; //启用loading
export const DETE_LOADEND = 'DETE_LOADEND'; //loading结束
export const GET_APP_LIST = 'GET_APP_LIST'; //更改检测管理列表

export const DETE_UPLOAD_STATE = 'DETE_UPLOAD_STATE'; //是否禁用上传组件
export const FILEMD5_PROGRESS = 'FILEMD5_PROGRESS'; //加密进度
