/* eslint-disable */
import React, { useState } from 'react';
import { Layout } from 'antd';
import LeftMenu from './LeftMenu/index.js';
import Headers from '@/layout/header';
import Content from './content';
import './style/topcontainer.less';
export default (props) => {
  const [collapsed, setCollapsed] = useState(false);
  return (
    <Layout id="top-container">
      {/* 头部 */}
      <Headers {...props} />
      <div className="content-private">
        {/* 侧边导航 */}
        <Layout.Sider collapsed={collapsed} onCollapse={setCollapsed}>
          <div className="menu-scroll">
            <LeftMenu {...props} />
          </div>
        </Layout.Sider>
        {/* 主体内容部分 */}
        <div id="topCON">
          <div className="body-box">
            <div className="scroll-box">
              <Content {...props} />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};
// export default TopContainer;
