/* eslint-disable */
import axios from '@/utils/request';
import { message } from 'antd';
import { get } from 'lodash';
import api from './api';
// 获取表格数据函数
const login = async (obj) => {
  try {
    const { data } = await axios.post(api.login, obj);
    let code = get(data, 'code', '');
    let msg = get(data, 'msg', '');
    if (code === 1) {
      localStorage.setItem('kiwi.saas.token', data?.data?.token);
      localStorage.setItem('kiwi.saas.userNo', data?.data?.userNo);
      localStorage.setItem('kiwi.saas.account', data?.data?.account);
      sessionStorage.setItem('activeEmail', data?.data?.account);
      sessionStorage.removeItem('kiwi.saas.notice');
      localStorage.removeItem("kiwi.saas.notice");
      message.success('登录成功');
      return true;
    }
    // 邮箱激活
    if (code === 2020012) {
      sessionStorage.setItem('activeEmail', obj?.account);
      return 2020012;
    }
    // 手机号码激活
    if (code === 2020013) {
      sessionStorage.setItem('activePhone', obj?.account);
      return 2020013;
    }
    message.error(msg);
    return false;
  } catch (error) {
    console.error(error);
    const msg = get(error, 'response.data.msg', '');
    if (msg) {
      message.error(msg);
    }
    return false;
  }
};
// 获取注册账号的手机验证码
const getPhoneCode = async (phone, checkCode) => {
  try {
    const { data } = await axios.post(api.getPhoneCode, { phone, checkCode });
    let code = get(data, 'code', '');
    let msg = get(data, 'msg', '');
    if (code === 1) {
      message.success('验证码发送成功');
      return true;
    }
    message.error(msg);
    return false;
  } catch (error) {
    console.error(error);
    const msg = get(error, 'response.data.msg', '');
    if (msg) {
      message.error(msg);
    }
    return false;
  }
};
// 获取找回密码的手机验证码
const getFindPwdPhoneCode = async (phone, checkCode) => {
  try {
    const { data } = await axios.post(api.getFindPwdPhoneCode, { phone, checkCode });
    let code = get(data, 'code', '');
    let msg = get(data, 'msg', '');
    if (code === 1) {
      message.success('验证码发送成功');
      return true;
    }
    message.error(msg);
    return false;
  } catch (error) {
    console.error(error);
    const msg = get(error, 'response.data.msg', '');
    if (msg) {
      message.error(msg);
    }
    return false;
  }
};
// 手机号注册
const phoneRigister = async (obj) => {
  try {
    const { data } = await axios.post(api.phoneRigister, obj);
    let code = get(data, 'code', '');
    let msg = get(data, 'msg', '');
    if (code === 1) {
      message.success('注册成功');
      return true;
    }
    message.error(msg);
    return false;
  } catch (error) {
    console.error(error);
    const msg = get(error, 'response.data.msg', '');
    if (msg) {
      message.error(msg);
    }
    return false;
  }
};
// 邮箱注册
const emailRigister = async (obj) => {
  try {
    const { data } = await axios.post(api.emailRigister, obj);
    let code = get(data, 'code', '');
    let msg = get(data, 'msg', '');
    // 说明 邮箱已注册没有激活
    if (code === 2020012) {
      return 'noActicve';
    }
    if (code === 1) {
      return true;
    }
    message.error(msg);
    return false;
  } catch (error) {
    console.error(error);
    const msg = get(error, 'response.data.msg', '');
    if (msg) {
      message.error(msg);
    }
    return false;
  }
};
// 手机号 找回密码
const findPwdByPhone = async (obj) => {
  try {
    const { data } = await axios.post(api.findPwdByPhone, obj);
    let code = get(data, 'code', '');
    let msg = get(data, 'msg', '');
    if (code === 1) {
      return true;
    }
    message.error(msg);
    return false;
  } catch (error) {
    console.error(error);
    const msg = get(error, 'response.data.msg', '');
    if (msg) {
      message.error(msg);
    }
    return false;
  }
};
// 邮箱 找回密码 提交函数
const findPwdByEmail = async (obj) => {
  try {
    const { data } = await axios.post(api.findPwdByEmail, obj);
    let code = get(data, 'code', '');
    let msg = get(data, 'msg', '');
    if (code === 1) {
      return true;
    }
    message.error(msg);
    return false;
  } catch (error) {
    console.error(error);
    const msg = get(error, 'response.data.msg', '');
    if (msg) {
      message.error(msg);
    }
    return false;
  }
};
// 重置密码
const resetPassword = async (obj) => {
  try {
    const { data } = await axios.post(api.resetPassword, obj);
    let code = get(data, 'code', '');
    let msg = get(data, 'msg', '');
    if (code === 1) {
      return true;
    }
    message.error(msg);
    return false;
  } catch (error) {
    console.error(error);
    const msg = get(error, 'response.data.msg', '');
    if (msg) {
      message.error(msg);
    }
    return false;
  }
};
// 发起邮箱激活链接
const activeEmail = async (obj) => {
  try {
    const { data } = await axios.post(api.activeEmail, obj);
    let code = get(data, 'code', '');
    let msg = get(data, 'msg', '');
    if (code === 1) {
      return true;
    }
    message.error(msg);
    return false;
  } catch (error) {
    console.error(error);
    const msg = get(error, 'response.data.msg', '');
    if (msg) {
      message.error(msg);
    }
    return false;
  }
};

// 手机激活短信验证码
const sendPhoneCode = async (obj) => {
  try {
    const { data } = await axios.post(api.getActivePhoneCode, obj);
    let code = get(data, 'code', '');
    let msg = get(data, 'msg', '');
    if (code === 1) {
      message.success('验证码发送成功,请注意查收');
      return data?.data;
    }
    message.error(msg);
    return false;
  } catch (error) {
    console.error(error);
    const msg = get(error, 'response.data.msg', '');
    if (msg) {
      message.error(msg);
    }
    return false;
  }
};

// 手机验证码激活链接
const activePhone = async (obj) => {
  try {
    const { data } = await axios.post(api.activePhone, obj);
    let code = get(data, 'code', '');
    let msg = get(data, 'msg', '');
    if (code === 1) {
      return data?.data;
    }
    message.error(msg);
    return false;
  } catch (error) {
    console.error(error);
    const msg = get(error, 'response.data.msg', '');
    if (msg) {
      message.error(msg);
    }
    return false;
  }
};

export {
  login,
  getPhoneCode,
  phoneRigister,
  emailRigister,
  getFindPwdPhoneCode,
  findPwdByPhone,
  findPwdByEmail,
  resetPassword,
  activeEmail,
  sendPhoneCode,
  activePhone,
};
