/* eslint-disable */
/*
 * @Author: kiliaosi
 * @Date: 2020-06-15 14:47:56
 * @LastEditors: kiliaosi
 * @LastEditTime: 2020-07-02 17:54:05
 * @Description:
 */
import React from 'react';
import './index.less';

class CenterBox extends React.Component {
  render() {
    const { isPrivateServer } = window.webConfig;
    return (
      <div className="login-center newlogin-center">
        {/* 左边配图 */}
        <div className="newimg-box">
          <img src="/images/login-peitu.png" alt="" className="shadowimg jiwei" />
          <img src="/images/usev-peitu.png" alt="" className="shadowimg uvsec-peitu" />
        </div>
        {/* 右边登录注册框 */}
        <div className="newlogin-content">{this.props.children}</div>
      </div>
    );
  }
}
export default CenterBox;
