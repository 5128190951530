/* eslint-disable */
/*
 * @Author: kiliaosi
 * @Date: 2020-06-15 14:47:56
 * @LastEditors: kiliaosi
 * @LastEditTime: 2020-06-16 17:09:52
 * @Description:
 */

import React from 'react';
import { connect } from 'react-redux';
import userLogin, { updateImgCode } from '../reducer/action';
import PhoneLogin from './phoneFrom';
import PropTypes from 'prop-types';
import '../style/login.less';
import UserPanel from '../../common/component/panel';

class LoginForm extends React.Component {
  getChildContext() {
    const obj = { fn: this.props.userLogin, state: this.props.state };
    return { loginCommonObj: obj };
  }
  render() {
    if (this.props.state.islogin) {
      setTimeout(() => {
        let redirectUrl = window.location.search;
        if (/redirectUrl/gim.test(redirectUrl)) {
          window.location.href = redirectUrl.replace('?redirectUrl=', '');
          return;
        }
        window.location.href = '/';
      });
    }
    return (
      <UserPanel>
        <PhoneLogin {...this.props} />
      </UserPanel>
    );
  }
}
LoginForm.childContextTypes = {
  loginCommonObj: PropTypes.object,
  history: PropTypes.object,
};
var Login = connect(
  function (state) {
    return { state: state.Login };
  },
  { userLogin, updateImgCode },
)(LoginForm);
export default Login;
