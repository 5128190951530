/* eslint-disable */
import React from 'react';
import { Result, Button } from 'antd';
const NotFound = (props) => {
  return (
    <Result
      status="404"
      title="404"
      subTitle="Sorry, 页面不存在."
      extra={
        <Button
          onClick={() => {
            props.history.push('/detection/android');
          }}
          type="primary"
        >
          回到首页
        </Button>
      }
    />
  );
};
export default NotFound;
