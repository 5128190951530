/* eslint-disable */
import * as actionType from './actionType.js';
const defaultState = {
  tableData: [], //表格数据
  tableTotal: 0, //表格总数据长度
  pageCode: 0, //当前页数
  selectedRowKeys: [], //当前选中的删除数组
  selectDataOne: [], //下拉框(1)的默认数据
  selectDataTwo: [], //下拉框(2)的默认数据
  review_type: undefined, //默认的审查类型
  review_content_id: undefined, //默认审查内容
  state: 'all', //审查状态
  platform: 'all', //系统类型
  editVisible: false, //编辑面板
  editWhiteVisible: false, // 白名单控制面板
  editeWhiteData: [], // 白名单列表数据
  addVisible: false, //新添面板
  isTableLoading: true, //Table表是否处于加载
}; //默认数据

export default (state = defaultState, action) => {
  //就是一个方法函数
  switch (action.type) {
    case actionType.GET_TABLE_DATAS:
      return {
        ...state,
        tableData: action.data,
        tableTotal: action.total,
        pageCode: action.pageCode,
        review_type: action.review_type,
        review_content_id: action.review_content_id,
        state: action.state,
        platform: action.platform,
        isTableLoading: false,
      };
    case actionType.SET_SELECTED_ROW:
      return { ...state, selectedRowKeys: action.arr };
    case actionType.SET_SELECT_DATA:
      return { ...state, selectDataOne: action.arrOne, selectDataTwo: action.arrTwo };
    case actionType.EDIT_VISIBLE:
      return { ...state, editVisible: action.is };
    case actionType.EDIT_WHITE_VISIBLE:
      return { ...state, editWhiteVisible: action.is };
    case actionType.EDIT_WHITE_DATA:
      return { ...state, editeWhiteData: action.arr };
    case actionType.ADD_VISIBLE:
      return { ...state, addVisible: action.is };
    case actionType.SET_TABLE_LOADING:
      return { ...state, isTableLoading: action.is };
    default:
      return { ...state };
  }
};
