/* eslint-disable */
import React from 'react';
import './menu_icon.less';

class PrivateIcon extends React.Component {
  render() {
    return (
      <em className={'menu-icon anticon ' + ' ' + this.props.iconName}>
        <div className="icon-box-menu">
          <span className="mask"></span>
        </div>
      </em>
    );
  }
}
export default PrivateIcon;
