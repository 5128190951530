/* eslint-disable */
import * as actionType from './actionType.js';

var init = {
  resetLoading: false,
};

export default function resetPwdReducer(state = init, action) {
  switch (action.type) {
    case actionType.SEND_RESET:
      return { ...state, resetLoading: true };
    case actionType.SEND_RESET_END:
      return { ...state, resetLoading: false };
    default:
      return state;
  }
}
