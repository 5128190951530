/* eslint-disable */
import React from 'react';
import { Form, Input, Row, Col, Button, message } from 'antd';
import MyIcon from '../../login/component/loginIcon';
import { passwordRegExp } from '../../../../config/regexp';
const FormItem = Form.Item;
const phoneReg = /^\d{11}$/;
class FindEmailPwd extends React.Component {
  constructor(props) {
    super(props);
    this.sendPhoneCode = this.sendPhoneCode.bind(this);
    this.changePhone = this.changePhone.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.state = {
      phoneNum: '',
    };
  }
  changePhone(e) {
    this.setState({ phoneNum: e.target.value });
  }
  sendPhoneCode() {
    if (this.props.sendPhoneLoading) {
      message.error('请不要重复获取');
      return;
    }
    if (!phoneReg.test(this.state.phoneNum)) {
      message.error('请输入正确的电话号码');
      return;
    }
    this.props.sendPhoneCode({ phone: this.state.phoneNum });
  }
  handleSubmit(e) {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.checkResetPhone(values);
      }
    });
  }
  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <Form onSubmit={this.handleSubmit} className="find-password">
        <FormItem>
          {getFieldDecorator('phone', {
            initialValue: this.state.phoneNum,
            rules: [
              {
                required: true,
                message: '手机号不能为空',
              },

              {
                pattern: phoneReg,
                message: '请输入正确的手机号',
              },
            ],
          })(
            <Input
              autoComplete="off"
              className="username-info"
              placeholder="请输入手机号"
              onChange={this.changePhone}
              prefix={<MyIcon src="/images/login-user.png" />}
            />,
          )}
        </FormItem>

        <FormItem className="err-err-panel">
          <Row>
            <Col span={14}>
              {getFieldDecorator('phonecode', {
                rules: [
                  { required: true, message: '验证码不能为空' },
                  { min: 4, message: '验证码不能少于四位' },
                ],
              })(
                <Input
                  autoComplete="off"
                  type="text"
                  name="checkCode"
                  placeholder="请输入验证码"
                  prefix={<MyIcon src="/images/login-yanzma.png" />}
                />,
              )}
            </Col>
            <Col span={10}>
              <div className="reg-duanxin">
                <a onClick={this.sendPhoneCode} className="button-color-color">
                  {this.props.sendPhoneCodeMsg}
                </a>
              </div>
            </Col>
          </Row>
        </FormItem>

        <FormItem>
          {getFieldDecorator('password', {
            rules: [
              { required: true, message: '密码不能为空' },
              { min: 6, message: '密码不能少于6位' },
              { pattern: passwordRegExp, message: '密码必须由字母和数字组成' },
            ],
          })(<Input.Password placeholder="请输入密码" prefix={<MyIcon src="/images/pwd-icon.png" />} />)}
        </FormItem>

        {/* 按钮 */}

        <FormItem>
          <Button type="primary" htmlType="submit" className="login-form-button antdButtonSelf">
            提交
          </Button>
        </FormItem>
        <a
          className="login-form-button-go"
          onClick={() => {
            this.props.history.push('/login');
          }}
        >
          取消
        </a>
      </Form>
    );
  }
}
const FindEmailPwds = Form.create()(FindEmailPwd);
export default FindEmailPwds;
