/* eslint-disable */
/*
 * @Author: Wang
 * @Date: 2019-03-12 17:21:21
 * @Last Modified by: Wang
 * @Last Modified time: 2019-03-13 18:06:17
 */
import * as actionType from './actionType';

const init = {
  templateList: [],
  loading: false,
  currentId: 'init',
  currentData: {},
};

/**
 *检测reducer函数
 *
 * @param {*} [state=init]
 * @param {*} action
 */
function detetionCnf(state = init, action) {
  switch (action.type) {
    case actionType.CNF_LOADING:
      return { ...state, loading: action.loading };

    case actionType.CNF_LOAD_LIST:
      return { ...state, templateList: action.templateList };

    case actionType.CNF_CHANGE_CURRENT:
      return { ...state, currentId: action.currentId };

    // case actionType.CNF_CHANGE_ITEM:

    // let flag=false, modifyState = state.templateList.map((item)=>{
    //     if(item.id === action.id){
    //         flag = true;
    //         return {...action.pushData};

    //     }
    //     return item
    // })
    // if(!flag){
    //     modifyState.push(action.pushData);
    // }
    // //console.log(modifyState,"???",flag)
    // return {...state,templateList:[...modifyState]};

    default:
      return state;
  }
}
export default detetionCnf;
