/* eslint-disable */
import { GETTABLELIST, DEL, APLICATIONTYPELIST, APLICATIONTYPEdetail } from './actionType';

const gettablelistAction = (data) => ({
  type: GETTABLELIST,
  data,
});
const delAction = (data) => ({
  type: GETTABLELIST,
  data,
});
const aplicationTypeListAction = (data) => ({
  type: APLICATIONTYPELIST,
  data,
});
const aplicationTypeDetailAction = (data) => ({
  type: APLICATIONTYPEdetail,
  data,
});

export { gettablelistAction, delAction, aplicationTypeListAction, aplicationTypeDetailAction };
