/* eslint-disable */
import { GETGROUPLIST, GETSINGlEDATA, GETSINGLEENCRYPTDATA, GETTHREATSCENARIOS, GETINSERTSTATE } from './actionType';
const defaultState = {
  tableData: [], // 表格数据
  tableDataTotal: 0, // 表格数据的总数
  singleAppData: [], // 上传的单个app的信息
  ThreatScenariosArr: [], // 威胁场景数组
  apkInformation: {}, // 接入状态和apk信息对象
  permission_info: {}, // 控制自动接入和手动接入显示
};
function reducer(state = defaultState, action) {
  switch (action.type) {
    case GETGROUPLIST: {
      return {
        ...state,
        tableData: action.data.application_info,
        tableDataTotal: action.data.count,
        permission_info: action?.data?.permission_info,
      };
    }
    case GETSINGlEDATA: {
      return { ...state, singleData: action.singleData };
    }
    case GETSINGLEENCRYPTDATA: {
      return { ...state, singleAppData: action.data };
    }
    case GETTHREATSCENARIOS: {
      return { ...state, ThreatScenariosArr: action.data };
    }
    case GETINSERTSTATE: {
      return { ...state, apkInformation: action.data };
    }
    default:
      return state;
  }
}
export default reducer;
