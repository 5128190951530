/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { Switch, Route } from 'react-router-dom';
import { message, Spin } from 'antd';
import { get } from 'lodash';
import axios from '@/utils/request';
import User from '@/modules/login-register-module-new';
import UserDev from '@/modules/login-register-module/index-dev';
import UserCore from '@/modules/login-register-module/user_index';
import Home from '@/layout/home';
import PocHtml from "@/modules/privacy-detection-poc-module/privacy-poc";

const App = () => {
  const [webConfig, setWebConfig] = useState(null);
  useEffect(() => {
    getWebConfig();
  }, []);
  // 获取全局的webConfig
  const getWebConfig = async () => {
    try {
      // 获取全局的webConfig
      let domain = window.location.host;
      // 给云端开发做配置
      if (domain.startsWith('28fdf871-b679-415b-b623-0f6f36b87c5b-3000')) {
        domain = '28fdf871-b679-415b-b623-0f6f36b87c5b-3000-2fivdtfw.xide.aliyun.com';
      }
      domain = domain === 'localhost:3000' ? '192.168.11.61:180' : domain;
      const { data } = await axios.post('/saas-auth/web/config', { domain });
      if (data.code === 1) {
        window.webConfig = Object.freeze(data.data);
        setWebConfig(data.data);
        let title = get(data, 'data.title', '几维安全');
        let favicon = get(data, 'data.favicon', '');
        window.document.title = title;
        appendIcon(favicon);
      } else if (data.code !== 2 && data.code !== 3) {
        message.error('请刷新页面重试');
      }
    } catch (error) {
      console.error('网络请求出错', error);
    }
  };
  // 设置徽标函数
  const appendIcon = (ico) => {
    let link = document.createElement('link');
    link.type = 'image/x-icon';
    link.rel = 'shortcut icon';
    link.href = decodeURIComponent(ico);
    document.getElementsByTagName('head')[0].appendChild(link);
  };
  // 没有初始化下来 就一直转圈圈
  if (!webConfig) {
    return (
      <div style={{ paddingTop: '40px', textAlign: 'center' }}>
        <Spin size="large" spinning tip="平台努力加载中..." />
      </div>
    );
  }
  let localStorage = window.localStorage.getItem('kiwi.saas.token');
  return (
    <Switch>
      <Route exact path="/login" component={User} />
      <Route exact path="/poc_rule/:id/:rule_name" component={PocHtml} />
      {/*<Route exact path="/login-dev" component={UserDev} />*/}
      {/*<Route exact path="/wx_web/checkLogin" component={UserCore} />*/}
      {/* <Route exact path="/user/resetpwd" component={UserCore} /> */}
      {/* 重置密码  */}
      {/*<Route exact path="/user/findpwd" component={UserCore} />*/}
      {/* 找回密码 */}
      {/* <Route exact path="/user/active" component={UserCore} /> */}
      {/* 发送邮件 */}
      {/* <Route exact path="/user/verify" component={UserCore} /> */}
      {/* 邮箱激活验证界面 */}
      {/* <Route exact path="/user/activephone" component={UserCore} /> */}
      {/* 手机激活验证界面 */}
      {localStorage ? <Route path="/" component={Home} /> : <Route path="/" component={User} />}
      <Route component={User} />
    </Switch>
  );
};
export default App;
