/* eslint-disable */
import React from 'react';
import ReactDOM from 'react-dom';
import { createStore, applyMiddleware, compose } from 'redux';
import { ConfigProvider } from 'antd';
import zh_CN from 'antd/lib/locale-provider/zh_CN';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import thunk from 'redux-thunk';
import reducer from './store/index';
import App from './app.js';
// 解决antd 中文显示问题
import moment from 'moment';
import 'moment/locale/zh-cn';
moment.locale('zh-cn');
import { toolFunction } from './utils/utils';
// 生成store
// 支持调试工具
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
  ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
  : compose;
const enhancer = composeEnhancers(applyMiddleware(thunk));
let store;
if (!process.env.NODE_ENV === 'production') {
  store = createStore(reducer, enhancer);
} else {
  store = createStore(reducer, applyMiddleware(thunk));
}
// 执行日志屏蔽，img禁用拖动函数
toolFunction();
// 挂载渲染
ReactDOM.render(
  <Provider store={store}>
    <ConfigProvider locale={zh_CN}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </ConfigProvider>
  </Provider>,
  document.getElementById('root'),
);
