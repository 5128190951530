/* eslint-disable */
import axios from '@/utils/request';
import {
  getGroupListAction,
  getEncryptSingleDataAction,
  getThreatScenariosAction,
  getInsertStateAction,
} from './action';
import { message } from 'antd';
import api from './api';
import { get } from 'lodash';

// 获取项目组
const getGroupList = (obj) => async (dispatch) => {
  try {
    const { data } = await axios.get(api.getGroupList);
    let code = get(data, 'code', '');
    if (code === 1) {
      dispatch(getGroupListAction(data.data));
    }
  } catch (error) {
    console.error(error);
    // message.error('获取数据失败');
  }
};
// 创建项目组
const createGroup = (obj) => async (dispatch) => {
  try {
    const { data } = await axios.post(api.createGroup, obj);
    let code = get(data, 'code', '');
    let msg = get(data, 'msg', '');
    if (code === 1) {
      message.success(msg);
      return true;
    } else {
      message.error(msg);
    }
  } catch (error) {
    const { response = {} } = error;
    const msg = get(response, 'data.msg', '');
    if (msg) {
      message.error(msg);
    }
    console.error(error);
    return false;
  }
};
// 编辑项目组
const updateGroup = (obj) => async (dispatch) => {
  try {
    const { data } = await axios.post(api.updateGroup, obj);
    let code = get(data, 'code', '');
    let msg = get(data, 'msg', '编辑成功');
    if (code === 1) {
      message.success(msg);
      return true;
    }
    return false;
  } catch (error) {
    const { response = {} } = error;
    const msg = get(response, 'data.msg', '编辑失败');
    message.error(msg);
    console.error(error);
    return false;
  }
};
// 删除项目组
const delGroup = (obj) => async (dispatch) => {
  try {
    const { data } = await axios.post(api.delGroup, obj);
    let code = get(data, 'code', '');
    let msg = get(data, 'msg', '删除成功');
    if (code === 1) {
      message.success(msg);
    }
  } catch (error) {
    const { response = {} } = error;
    const msg = get(response, 'data.msg', '删除失败');
    message.error(msg);
    console.error(error);
  }
};
// 删除子表格接入的包
const delChildTableApp = (obj) => async (dispatch) => {
  try {
    const { data } = await axios.post(api.delChildTableApp, obj);
    let code = get(data, 'code', '');
    let msg = get(data, 'msg', '删除成功');
    if (code === 1) {
      message.success(msg);
    }
  } catch (error) {
    const { response = {} } = error;
    const msg = get(response, 'data.msg', '删除失败');
    message.error(msg);
    console.error(error);
  }
};
// 编辑子表格的渠道和归属应用
const updateChildTable = (obj) => async (dispatch) => {
  try {
    const { data } = await axios.post(api.updateChildTable, obj);
    let code = get(data, 'code', '');
    let msg = get(data, 'msg', '编辑成功');
    if (code === 1) {
      message.success(msg);
      return true;
    }
    return false;
  } catch (error) {
    const { response = {} } = error;
    const msg = get(response, 'data.msg', '编辑失败');
    message.error(msg);
    console.error(error);
    return false;
  }
};
// 编辑子表格的手动接入的渠道和归属应用appname 和version
const updatehandChildTable = (obj) => async (dispatch) => {
  try {
    const { data } = await axios.post(api.updatehandChildTable, obj);
    let code = get(data, 'code', '');
    let msg = get(data, 'msg', '编辑成功');
    if (code === 1) {
      message.success(msg);
      return true;
    }
    return false;
  } catch (error) {
    const { response = {} } = error;
    const msg = get(response, 'data.msg', '编辑失败');
    message.error(msg);
    console.error(error);
    return false;
  }
};

// ---------------------------------自动接入的方法----------------------------------------------
const getSingleAppData = (app_md5) => async (dispatch) => {
  try {
    let currentTime = Date.parse(new Date());
    const { data } = await axios.get(`/encrypt/android/${app_md5}?${currentTime}&n=1`);
    let code = get(data, 'code', '');
    let datas = get(data, 'data', []);
    if (code === 1) {
      dispatch(getEncryptSingleDataAction(datas));
    }
  } catch (error) {
    console.error(error);
  }
};
// 取消截图接口
const cancelInsert = (obj) => async (dispatch) => {
  try {
    const { data } = await axios.post(`/appguard/ios_minitor/project/group/delete`, obj);
    let code = get(data, 'code', '');
    let msg = get(data, 'msg', '取消成功');
    if (code === 1) {
      message.success(msg);
    }
  } catch (error) {
    const { response = {} } = error;
    const msg = get(response, 'data.msg', '删除失败');
    message.error(msg);
    console.error(error);
  }
};
// 得到威胁场景
const getThreatScenarios = (obj) => async (dispatch) => {
  try {
    const { data } = await axios.get(api.getThreatScenarios, { params: obj });
    let code = get(data, 'code', '');
    if (code === 1) {
      dispatch(getThreatScenariosAction(data.data));
    }
  } catch (error) {
    const { response = {} } = error;
    const msg = get(response, 'data.msg', '');
    if (msg) {
      message.error(msg);
    }
    console.error(error);
  }
};
// 获取接入的实时状态
const getInsertState = (projectID) => async (dispatch) => {
  try {
    const { data } = await axios.get(api.getInsertState, { params: { projectID } });
    return data;
  } catch (error) {
    console.error(error);
    return false;
  }
};
// 获取apk 的详细信息
const getApkInfo = (projectID) => async (dispatch) => {
  try {
    const {
      data: { data },
    } = await axios.get(api.getApkInfo, { params: { projectID } });
    dispatch(getInsertStateAction(data));
  } catch (error) {
    console.error(error);
  }
};
// 开始接入
const startInsert = (obj) => async (dispatch) => {
  try {
    const { data } = await axios.post(api.startInsert, obj);
    if (data.code === 1) {
      return true;
    } else {
      message.error('开始接入失败');
      return false;
    }
  } catch (error) {
    const { response = {} } = error;
    const msg = get(response, 'data.msg', '开始接入失败');
    message.error(msg);
    console.error(error);
    return false;
  }
};
export default {
  getGroupList,
  createGroup,
  updateGroup,
  delGroup,
  updateChildTable,
  updatehandChildTable,
  getSingleAppData,
  cancelInsert,
  getThreatScenarios,
  getInsertState,
  startInsert,
  getApkInfo,
  delChildTableApp,
};
