/* eslint-disable */
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import PocHtml from './poc-html';

const WebScanIndex = () => {
  return (
    <Switch>
      {/* 详情页 */}
      <Route exact path="/poc_rule/:id/:rule_name" component={PocHtml} />
    </Switch>
  );
}

export default WebScanIndex;
