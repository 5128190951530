/* eslint-disable */
import axios from '@/utils/request';
import { message } from 'antd';
import { get } from 'lodash';
import api from './api';

// 获取是否有权限
const getHasPermissionKey = async (obj) => {
  try {
    const data = await axios.post(api.getHasPermissionKey, obj);
    if (data) {
      const { data: dataSource } = data;
      let code = get(dataSource, 'code', '');
      let msg = get(dataSource, 'message', '');
      if (code === 1) {
        return dataSource.data;
      } else {
        message.error(msg);
        return false;
      }
    }
  } catch (error) {
    console.error(error);
    let msg = get(error.response, 'data.message', '');
    if (msg) {
      message.error(msg);
    }
    return false;
  }
};

export {
  getHasPermissionKey
}